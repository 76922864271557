// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenterCode, OrganizationCenterCodeSchema } from 'api/gen/OrganizationCenterCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationNameCobol, OrganizationNameCobolSchema } from 'api/gen/OrganizationNameCobol';

const requiredPart = t.interface({
  id: t.Integer,
  organizationCenterCode: OrganizationCenterCodeSchema,
  organizationCode: t.union([OrganizationCodeSchema, t.null]),
  organizationName: t.union([OrganizationNameCobolSchema, t.null]),
});

export const OrganizationCenterAutocompleteSchema = excess(requiredPart);

export interface OrganizationCenterAutocomplete extends t.TypeOf<typeof OrganizationCenterAutocompleteSchema> {}
