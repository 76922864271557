// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Genotype represented by a single character.
// Example: "G"

export const genotypeMinLength = 1;
export const genotypeMaxLength = 1;

export const GenotypeSchema = t.refinement(t.string, n => n.length >= genotypeMinLength && n.length <= genotypeMaxLength, 'Genotype');

export type Genotype = string;
