// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: HOLSTEIN

export const AssociationTypeSchema = t.keyof({
  HOLSTEIN: null,
  FLECKVIEH: null,
});

export type AssociationType = t.TypeOf<typeof AssociationTypeSchema>;
