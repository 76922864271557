// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A business name of a subject
// Example: "Společnost pro zemědělskou výrobu Ostředek,a.s."

export const subjectBusinessNameMinLength = 1;
export const subjectBusinessNameMaxLength = 256;

export const SubjectBusinessNameSchema = t.refinement(t.string, n => n.length >= subjectBusinessNameMinLength && n.length <= subjectBusinessNameMaxLength, 'SubjectBusinessName');

export type SubjectBusinessName = string;
