// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CorrectionType, CorrectionTypeSchema } from 'api/gen/CorrectionType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal80, Decimal80Schema } from 'api/gen/Decimal80';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingCount, MilkingCountSchema } from 'api/gen/MilkingCount';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingDayTime, MilkingDayTimeSchema } from 'api/gen/MilkingDayTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingInterval, MilkingIntervalSchema } from 'api/gen/MilkingInterval';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationName, OrganizationNameSchema } from 'api/gen/OrganizationName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal52, PositiveOrZeroDecimal52Schema } from 'api/gen/PositiveOrZeroDecimal52';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TechnicianNumber, TechnicianNumberSchema } from 'api/gen/TechnicianNumber';

const requiredPart = t.interface({
  breederName: BreederNameSchema,
  calveDate: t.union([DateSchema, t.null]),
  correctionType: CorrectionTypeSchema,
  currentMethodOfProficiencyTest: t.union([PositiveIntegerSchema, t.null]),
  earTag: EarTagSchema,
  exportedToCobolDatabase: t.boolean,
  fatPercentage: PositiveOrZeroDecimal52Schema,
  id: PositiveIntegerSchema,
  lactationOrder: t.union([t.Integer, t.null]),
  lactosePercentage: PositiveOrZeroDecimal52Schema,
  lastChangedBy: t.string,
  lastChangedById: PositiveIntegerSchema,
  methodOfProficiencyTest: t.union([PositiveIntegerSchema, t.null]),
  methodOfProficiencyTestChanged: t.boolean,
  milkKg: PositiveOrZeroDecimal52Schema,
  milkingCount: t.union([MilkingCountSchema, t.null]),
  milkingDayTime: t.union([MilkingDayTimeSchema, t.null]),
  milkingInterval: t.union([MilkingIntervalSchema, t.null]),
  organizationId: PositiveIntegerSchema,
  organizationName: OrganizationNameSchema,
  proficiencyTestDate: DateSchema,
  proteinPercentage: PositiveOrZeroDecimal52Schema,
  somaticCells: Decimal80Schema,
  stableCode: StableCodeSchema,
  stableName: StableNameSchema,
  technicianNumber: TechnicianNumberSchema,
  updated: t.union([DateTimeSchema, t.null]),
  updatedEarTag: t.union([EarTagSchema, t.null]),
  urea: PositiveOrZeroDecimal52Schema,
});

export const CorrectionReportSchema = excess(requiredPart);

export interface CorrectionReport extends t.TypeOf<typeof CorrectionReportSchema> {}
