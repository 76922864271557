// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of the accordion.
// Example: "Základní parametry, RPH produkčních a funkčních znaků"

export const accordionNameMinLength = 1;
export const accordionNameMaxLength = 75;

export const AccordionNameSchema = t.refinement(t.string, n => n.length >= accordionNameMinLength && n.length <= accordionNameMaxLength, 'AccordionName');

export type AccordionName = string;
