// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaFilter, CriteriaFilterSchema } from 'api/gen/CriteriaFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

// Ids represent either farms (to filter heifers) or organizations (to filter bulls).

const requiredPart = t.interface({
  filters: t.array(CriteriaFilterSchema),
  ids: t.array(PositiveIntegerSchema),
});

export const CriteriaFiltersSchema = excess(requiredPart);

export interface CriteriaFilters extends t.TypeOf<typeof CriteriaFiltersSchema> {}
