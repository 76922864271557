// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';

const requiredPart = t.interface({
  code: StableCodeSchema,
  id: t.Integer,
});

export const StableSchema = excess(requiredPart);

export interface Stable extends t.TypeOf<typeof StableSchema> {}
