import {setupTables} from '@fl/cmsch-fe-library';

import {TableFilter} from 'api/gen/TableFilter';
import {AppAction} from 'app/setup/app-action';
import {State} from 'app/setup/state';
import {TableRowMap} from 'types/table-row-map';

export const {
    ConnectedTable,
    getRequestedTableColumnSettings,
    getRequestedTablePageParams,
    initialTablesState,
    tablesAction,
    tablesReducer,
    tablesSagas,
    simpleTableSelector,
    convertTableDataParamsToDto,
    getRequestedTableCsvExportParams,
    createTableUserSettings,
} = setupTables<TableRowMap, State, AppAction, TableFilter>();

