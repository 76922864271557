// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalCodeName, AnimalCodeNameSchema } from 'api/gen/AnimalCodeName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitInteger, AnimalTraitIntegerSchema } from 'api/gen/AnimalTraitInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinAnimalInListBase, HolsteinAnimalInListBaseSchema } from 'api/gen/HolsteinAnimalInListBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';

const requiredPart = t.interface({
  exteriorReliability: t.union([t.Integer, t.null]),
  exteriorTraitsCode: t.union([t.string, t.null]),
  extremitiesIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  lineRegistry: LineRegistrySchema,
  longevityIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  milkProductionIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  name: t.union([AnimalCodeNameSchema, t.null]),
  organizationCode: OrganizationCodeSchema,
  productionTraitsCode: t.union([t.string, t.null]),
  rbvAllChildBirthDifficulties: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvDaughtersFertility: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFirstChildbirthDifficulty: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvHeifersFertility: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvLongevity: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvMilkability: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvMothersChildbirthDifficulty: t.union([AnimalTraitIntegerSchema, t.null]),
  roboticMilkingIndex: t.union([t.Integer, t.null]),
  udderIndex: t.union([AnimalTraitIntegerSchema, t.null]),
});

export const MaleHolsteinAnimalInListSchema = excess(t.intersection([HolsteinAnimalInListBaseSchema.type, requiredPart]));

export interface MaleHolsteinAnimalInList extends t.TypeOf<typeof MaleHolsteinAnimalInListSchema> {}
