// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';

const requiredPart = t.interface({
  code: FarmCodeSchema,
  id: PositiveIntegerSchema,
  name: t.union([t.string, t.null]),
  numOfCows: PositiveOrZeroLongSchema,
});

export const FarmToChooseSchema = excess(requiredPart);

export interface FarmToChoose extends t.TypeOf<typeof FarmToChooseSchema> {}
