// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederRegistrationNumber, BreederRegistrationNumberSchema } from 'api/gen/BreederRegistrationNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';

const requiredPart = t.interface({
  breederRegistrationNumber: BreederRegistrationNumberSchema,
  code: FarmCodeSchema,
});

export const AnimalsBrowserFarmSchema = excess(requiredPart);

export interface AnimalsBrowserFarm extends t.TypeOf<typeof AnimalsBrowserFarmSchema> {}
