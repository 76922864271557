// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaValuesBase, CriteriaValuesBaseSchema } from 'api/gen/CriteriaValuesBase';

const requiredPart = t.interface({
  tag: t.literal('SINGLE_INT'),
  value: t.union([t.Integer, t.null]),
});

export const SingleIntegerCriteriaValuesSchema = excess(t.intersection([CriteriaValuesBaseSchema.type, requiredPart]));

export interface SingleIntegerCriteriaValues extends t.TypeOf<typeof SingleIntegerCriteriaValuesSchema> {}
