import React, { FC, memo } from 'react';
import { useOurTranslation } from 'app/translations';
import { Button } from 'common/buttons';
interface Props {
  disabled?: boolean;
  onClick(): void;
}
const ResetButtonBase: FC<Props> = props => {
  const {
    onClick
  } = props;
  const {
    t
  } = useOurTranslation('common');
  return <Button onClick={onClick} stopClickEventPropagation danger data-sentry-element="Button" data-sentry-component="ResetButtonBase" data-sentry-source-file="index.tsx">
            {t('resetSettings')}
        </Button>;
};
export const ResetButton = memo(ResetButtonBase);