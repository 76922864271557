// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederRegistrationNumber, BreederRegistrationNumberSchema } from 'api/gen/BreederRegistrationNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';

const requiredPart = t.interface({
  breederRegistrationNumber: BreederRegistrationNumberSchema,
  code: StableCodeSchema,
});

export const AnimalsBrowserStableSchema = excess(requiredPart);

export interface AnimalsBrowserStable extends t.TypeOf<typeof AnimalsBrowserStableSchema> {}
