// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaValues, CriteriaValuesSchema } from 'api/gen/CriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Operator, OperatorSchema } from 'api/gen/Operator';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  criteriaDefinitionId: PositiveIntegerSchema,
  operator: OperatorSchema,
  values: CriteriaValuesSchema,
});

export const WriteCriteriaSchema = excess(requiredPart);

export interface WriteCriteria extends t.TypeOf<typeof WriteCriteriaSchema> {}
