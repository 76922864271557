import { classNames, toggleItem } from '@fl/cmsch-fe-library';
import { isEmpty } from 'lodash/fp';
import React, { createRef, FC, memo, ReactNode, RefObject, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { ICONS } from 'common/icons';
import { MenuItem } from 'common/layout/types/menu-item';
import { LanguageOption, LanguageSelect } from './language-select';
import { buildSubMenuItems, isActive, MainMenuItem } from './main-menu-item';
import { SideMenu } from './side-menu';
import { SmallMenu } from './small-menu';
import styles from './styles.sass';
import logoDevelopment from './img/logo-development.png';
import logoProduction from './img/logo-production.png';
import logoStaging from './img/logo-staging.png';
type Environment = 'development' | 'staging' | 'production';
export type MenuType = 'full' | 'side' | 'small';
interface Props {
  menuItems: Array<MenuItem>;
  locationPath: string;
  homePath: string;
  isUserLoading: boolean;
  menuType: MenuType;
  languageOptions: Array<LanguageOption>;
  selectedLanguage: LanguageOption;
  environment: Environment;
  isSideMenuCollapsed: boolean;
  onMenuItemClick?(): void;
  onLanguageChange(language: string): void;
}
const environmentLogoMap: Record<Environment, string> = {
  production: logoProduction,
  development: logoDevelopment,
  staging: logoStaging
};
const renderMenuItems = (menuItems: Array<MenuItem>, locationPath: string, overflowedMenuItemsKeys: Array<string>, actionIconsRef: RefObject<HTMLDivElement>, toggleOverflowedMenuItem: (key: string) => void): ReactNode => menuItems.map((menuItem: MenuItem) => <MainMenuItem key={menuItem.key} locationPath={locationPath} menuItem={menuItem} visible={!overflowedMenuItemsKeys.includes(menuItem.key)} actionIconsRef={actionIconsRef} toggleOverflowedMenuItem={toggleOverflowedMenuItem} />);
const MainMenuBase: FC<Props> = ({
  menuItems,
  locationPath,
  homePath,
  isUserLoading,
  menuType,
  languageOptions,
  selectedLanguage,
  environment,
  isSideMenuCollapsed,
  onMenuItemClick,
  onLanguageChange
}) => {
  const {
    t
  } = useOurTranslation('user/login');
  const [overflowedMenuItemsKeys, setOverflowedMenuItems] = useState<Array<string>>([]);
  const toggleOverflowedMenuItem = useCallback((key: string) => {
    setOverflowedMenuItems(toggleItem(key)(overflowedMenuItemsKeys));
  }, [overflowedMenuItemsKeys]);
  useEffect(() => {
    setOverflowedMenuItems([]);
  }, [menuItems]);
  const appLogo = <img className={styles.logo} src={environmentLogoMap[environment]} alt="eskot" />;
  const languageSelect = <LanguageSelect isSideMenu={false} languageOptions={languageOptions} selectedLanguage={selectedLanguage} onLanguageChange={onLanguageChange} />;
  const actionIconsRef = createRef<HTMLDivElement>();
  const overflowedMenuItems = menuItems.filter(x => overflowedMenuItemsKeys.includes(x.key));
  const overflowedMenuClass = classNames(styles.menuItem, styles.moreButton, 'd-inline-block', isActive(locationPath, homePath, overflowedMenuItems) && styles.active);
  const overflowedMenu = <Ant.Dropdown overlay={<Ant.Menu items={buildSubMenuItems(overflowedMenuItems, locationPath)} />} placement="bottomRight" arrow>
            <div className={overflowedMenuClass}>
                {ICONS.moreOutlined}
            </div>
        </Ant.Dropdown>;
  const actionIcons = <div className={classNames('position-absolute', styles.actionIcons)} ref={actionIconsRef}>
            {languageSelect}
            {!isEmpty(overflowedMenuItemsKeys) && menuType === 'full' && overflowedMenu}
        </div>;
  switch (menuType) {
    case 'side':
      return <SideMenu isUserLoading={isUserLoading} menuItems={menuItems} locationPath={locationPath} onMenuItemClick={onMenuItemClick} isSideMenuCollapsed={isSideMenuCollapsed} />;
    case 'small':
      return <SmallMenu appLogo={appLogo} actionIcons={actionIcons} />;
    case 'full':
      return <div className={styles.menuContainer}>
                    {isUserLoading ? <div className={styles.loadingHeader}>{t('loadingUser')}</div> : <div className={classNames('text-nowrap position-relative overflow-hidden', styles.menuHeight)}>
                                <div className={classNames(styles.menuItem, styles.logoItem)}>
                                    <Link to={homePath} onClick={onMenuItemClick}>
                                        {appLogo}
                                    </Link>
                                </div>
                                {renderMenuItems(menuItems, locationPath, overflowedMenuItemsKeys, actionIconsRef, toggleOverflowedMenuItem)}
                                {actionIcons}
                            </div>}
                </div>;
  }
};
export const MainMenu = memo(MainMenuBase);