// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{overflow:auto!important}body{overflow:visible;overflow-y:scroll}html:not([data-scroll="0"]) .src-common-layout-components-card-___styles__redux-toastr___A91Om .src-common-layout-components-card-___styles__top-right___KIhP4{top:0!important}.src-common-layout-components-card-___styles__fade-enter___PGflK{opacity:0}.src-common-layout-components-card-___styles__fade-exit___NKlHX,.src-common-layout-components-card-___styles__fade-enter-active___ss9_H{opacity:1}.src-common-layout-components-card-___styles__fade-exit-active___ZPb3c{opacity:0}.src-common-layout-components-card-___styles__fade-enter-active___ss9_H,.src-common-layout-components-card-___styles__fade-exit-active___ZPb3c{transition:opacity .25s}.src-common-layout-components-card-___styles__ant-dropdown___x2ivW:has(.src-common-layout-components-card-___styles__ant-dropdown-menu___fZT5R){z-index:1060!important}.src-common-layout-components-card-___styles__titleSizeBig___yx8Mg .ant-card-head-title{font-size:20px}@media (max-width: 575px){.src-common-layout-components-card-___styles__card___fTfYZ .ant-card-body{padding-left:0;padding-right:0}.src-common-layout-components-card-___styles__titleSizeBig___yx8Mg .ant-card-head-title{font-size:14px;line-height:1.2;white-space:normal}.src-common-layout-components-card-___styles__leftMargin___FL0K0 .ant-card-body{padding-left:10px!important}}
`, "",{"version":3,"sources":["webpack://./src/common/layout/components/card/styles.sass"],"names":[],"mappings":"AAAA,KAAK,uBAAA,CAAyB,KAAK,gBAAA,CAAiB,iBAAA,CAAkB,+JAAqD,eAAA,CAAmB,iEAAY,SAAA,CAAU,wIAAW,SAAA,CAAuC,uEAAkB,SAAA,CAAU,+IAAqC,uBAAA,CAAyB,gJAAsC,sBAAA,CAAwB,wFAA4C,cAAA,CAAe,0BAAyB,0EAA8B,cAAA,CAAe,eAAA,CAAgB,wFAA4C,cAAA,CAAe,eAAA,CAAgB,kBAAA,CAAmB,gFAAoC,2BAAA,CAAA","sourcesContent":["html{overflow:auto !important}body{overflow:visible;overflow-y:scroll}html:not([data-scroll=\"0\"]) .redux-toastr .top-right{top:0px !important}.fade-enter{opacity:0}.fade-exit{opacity:1}.fade-enter-active{opacity:1}.fade-exit-active{opacity:0}.fade-enter-active,.fade-exit-active{transition:opacity 250ms}.ant-dropdown:has(.ant-dropdown-menu){z-index:1060 !important}.titleSizeBig :global(.ant-card-head-title){font-size:20px}@media(max-width: 575px){.card :global(.ant-card-body){padding-left:0;padding-right:0}.titleSizeBig :global(.ant-card-head-title){font-size:14px;line-height:1.2;white-space:normal}.leftMargin :global(.ant-card-body){padding-left:10px !important}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redux-toastr": `src-common-layout-components-card-___styles__redux-toastr___A91Om`,
	"top-right": `src-common-layout-components-card-___styles__top-right___KIhP4`,
	"fade-enter": `src-common-layout-components-card-___styles__fade-enter___PGflK`,
	"fade-exit": `src-common-layout-components-card-___styles__fade-exit___NKlHX`,
	"fade-enter-active": `src-common-layout-components-card-___styles__fade-enter-active___ss9_H`,
	"fade-exit-active": `src-common-layout-components-card-___styles__fade-exit-active___ZPb3c`,
	"ant-dropdown": `src-common-layout-components-card-___styles__ant-dropdown___x2ivW`,
	"ant-dropdown-menu": `src-common-layout-components-card-___styles__ant-dropdown-menu___fZT5R`,
	"titleSizeBig": `src-common-layout-components-card-___styles__titleSizeBig___yx8Mg`,
	"card": `src-common-layout-components-card-___styles__card___fTfYZ`,
	"leftMargin": `src-common-layout-components-card-___styles__leftMargin___FL0K0`
};
export default ___CSS_LOADER_EXPORT___;
