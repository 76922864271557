// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OtherSubjectRole, OtherSubjectRoleSchema } from 'api/gen/OtherSubjectRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';

const requiredPart = t.interface({
  content: t.union([t.array(OtherSubjectRoleSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelOtherSubjectRoleSchema = excess(requiredPart);

export interface PagedModelOtherSubjectRole extends t.TypeOf<typeof PagedModelOtherSubjectRoleSchema> {}
