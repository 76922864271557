// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalCodeName, AnimalCodeNameSchema } from 'api/gen/AnimalCodeName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal41, Decimal41Schema } from 'api/gen/Decimal41';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal42, Decimal42Schema } from 'api/gen/Decimal42';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Exterior, ExteriorSchema } from 'api/gen/Exterior';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GenotypeExt, GenotypeExtSchema } from 'api/gen/GenotypeExt';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Huid, HuidSchema } from 'api/gen/Huid';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Sex, SexSchema } from 'api/gen/Sex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StudbookSection, StudbookSectionSchema } from 'api/gen/StudbookSection';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  birthDate: t.union([DateSchema, t.null]),
  breed: t.union([BreedAlpha6Schema, t.null]),
  earTag: EarTagSchema,
  fatherEarTag: t.union([EarTagSchema, t.null]),
  fatherLineRegistry: t.union([LineRegistrySchema, t.null]),
  fatherName: t.union([AnimalCodeNameSchema, t.null]),
  fatherSelectionIndex: t.union([Decimal41Schema, t.null]),
  firstInbreedingAt: t.union([DateSchema, t.null]),
  firstPregnancyAt: t.union([DateSchema, t.null]),
  genotype: t.union([GenotypeExtSchema, t.null]),
  genotypeIndex: t.union([t.boolean, t.null]),
  huid: t.union([HuidSchema, t.null]),
  id: PositiveIntegerSchema,
  inbreedingBullEarTag: t.union([EarTagSchema, t.null]),
  inbreedingBullLineRegistry: t.union([LineRegistrySchema, t.null]),
  inbreedingBullName: t.union([AnimalCodeNameSchema, t.null]),
  motherAvgLactationDays: t.union([t.Integer, t.null]),
  motherAvgLactationMilkKg: t.union([t.Integer, t.null]),
  motherBodyStructureToMusculature: t.union([t.Integer, t.null]),
  motherEarTag: t.union([EarTagSchema, t.null]),
  motherExterior: t.union([ExteriorSchema, t.null]),
  motherExtremities: t.union([t.Integer, t.null]),
  motherFirstLactationDays: t.union([t.Integer, t.null]),
  motherFirstLactationFatKg: t.union([t.Integer, t.null]),
  motherFirstLactationFatPercentage: t.union([Decimal42Schema, t.null]),
  motherFirstLactationMilkKg: t.union([t.Integer, t.null]),
  motherFirstLactationProteinKg: t.union([t.Integer, t.null]),
  motherFirstLactationProteinPercentage: t.union([Decimal42Schema, t.null]),
  motherLifelongLactationMilkKg: t.union([t.Integer, t.null]),
  motherMaxLactationFatKg: t.union([t.Integer, t.null]),
  motherMaxLactationFatPercentage: t.union([Decimal42Schema, t.null]),
  motherMaxLactationMilkKg: t.union([t.Integer, t.null]),
  motherMaxLactationOrder: t.union([t.Integer, t.null]),
  motherMaxLactationProteinKg: t.union([t.Integer, t.null]),
  motherMaxLactationProteinPercentage: t.union([Decimal42Schema, t.null]),
  motherMilkStrengthToFrame: t.union([t.Integer, t.null]),
  motherName: t.union([AnimalCodeNameSchema, t.null]),
  motherSelectionIndex: t.union([Decimal41Schema, t.null]),
  motherStandardizedLactationCount: t.union([t.Integer, t.null]),
  motherUdder: t.union([t.Integer, t.null]),
  pregnant: t.union([t.boolean, t.null]),
  selectionIndex: t.union([Decimal41Schema, t.null]),
  sex: SexSchema,
  stableCode: StableCodeSchema,
  studbookSection: t.union([StudbookSectionSchema, t.null]),
  subjectBusinessName: t.union([SubjectBusinessNameSchema, t.null]),
});

export const CalfSchema = excess(requiredPart);

export interface Calf extends t.TypeOf<typeof CalfSchema> {}
