// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDefinitionBase, CriteriaDefinitionBaseSchema } from 'api/gen/CriteriaDefinitionBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal31CriteriaValues, Decimal31CriteriaValuesSchema } from 'api/gen/Decimal31CriteriaValues';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_3_1'),
  values: Decimal31CriteriaValuesSchema,
});

export const Decimal31CriteriaDefinitionSchema = excess(t.intersection([CriteriaDefinitionBaseSchema.type, requiredPart]));

export interface Decimal31CriteriaDefinition extends t.TypeOf<typeof Decimal31CriteriaDefinitionSchema> {}
