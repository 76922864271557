// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AssemblyVersions, AssemblyVersionsSchema } from 'api/gen/AssemblyVersions';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DatabaseInfo, DatabaseInfoSchema } from 'api/gen/DatabaseInfo';

const requiredPart = t.interface({
  assemblyVersions: AssemblyVersionsSchema,
  databaseInfo: DatabaseInfoSchema,
  missingScripts: t.array(t.string),
});

export const LegacyBeAppInfoSchema = excess(requiredPart);

export interface LegacyBeAppInfo extends t.TypeOf<typeof LegacyBeAppInfoSchema> {}
