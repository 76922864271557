// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Milking count - valid values 2 - 3
// Example: 3

export const milkingCountMinimum = 2.0; // inclusive
export const milkingCountMaximum = 3.0; // inclusive

export const MilkingCountSchema = t.refinement(t.Integer, x => x >= milkingCountMinimum && x <= milkingCountMaximum, 'MilkingCount');

export type MilkingCount = t.TypeOf<typeof MilkingCountSchema>;
