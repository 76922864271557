// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EditApcBase, EditApcBaseSchema } from 'api/gen/EditApcBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkKg, MilkKgSchema } from 'api/gen/MilkKg';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal52, PositiveOrZeroDecimal52Schema } from 'api/gen/PositiveOrZeroDecimal52';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal80, PositiveOrZeroDecimal80Schema } from 'api/gen/PositiveOrZeroDecimal80';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';

const requiredPart = t.interface({
  correctionType: t.literal('UPDATE'),
  fatPercentage: PositiveOrZeroDecimal52Schema,
  lactosePercentage: PositiveOrZeroDecimal52Schema,
  milkKg: MilkKgSchema,
  proteinPercentage: PositiveOrZeroDecimal52Schema,
  somaticCells: PositiveOrZeroDecimal80Schema,
  stableCode: StableCodeSchema,
  urea: PositiveOrZeroDecimal52Schema,
});

export const EditApcUpdateSchema = excess(t.intersection([EditApcBaseSchema.type, requiredPart]));

export interface EditApcUpdate extends t.TypeOf<typeof EditApcUpdateSchema> {}
