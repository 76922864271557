import React, { FC, memo, PropsWithChildren, useCallback } from 'react';
import { AntLayout } from 'common/ant';
import { ICONS } from 'common/icons';
import { MainMenuMemoizedWrapper } from './main-menu-memoized-wrapper';
import styles from './styles.sass';
interface Props {
  isSiderActive: boolean;
  isSideMenuCollapsed: boolean;
  isE2E: boolean;
  setIsSideMenuCollapsed(isCollapsed: boolean): void;
  setIsSiderActive(isActive: boolean): void;
}
const MenuLayoutBase: FC<PropsWithChildren<Props>> = props => {
  const {
    isSiderActive,
    isSideMenuCollapsed,
    children,
    isE2E,
    setIsSideMenuCollapsed,
    setIsSiderActive
  } = props;
  const handleSideMenuCollapse = useCallback(() => {
    setIsSideMenuCollapsed(true);
  }, [isSideMenuCollapsed]); // eslint-disable-line react-hooks/exhaustive-deps

  return <AntLayout className={styles.menuAppWrapperLayout} hasSider={false} data-sentry-element="AntLayout" data-sentry-component="MenuLayoutBase" data-sentry-source-file="menu-layout.tsx">
            <AntLayout.Sider width="300px" breakpoint="sm" collapsedWidth="0" collapsed={isSideMenuCollapsed} onBreakpoint={setIsSiderActive} onCollapse={setIsSideMenuCollapsed} className={isSiderActive ? styles.menuAppWrapperSiderActive : 'd-none'} trigger={ICONS[isSideMenuCollapsed ? 'barsOutlined' : 'closeOutlined']} data-test-id="menu-layout-sider" data-sentry-element="unknown" data-sentry-source-file="menu-layout.tsx">
                <MainMenuMemoizedWrapper menuType="side" setIsSideMenuCollapsed={setIsSideMenuCollapsed} isSideMenuCollapsed={isSideMenuCollapsed} data-sentry-element="MainMenuMemoizedWrapper" data-sentry-source-file="menu-layout.tsx" />
            </AntLayout.Sider>
            {isSiderActive && !isSideMenuCollapsed && <div className={styles.menuAppWrapperSiderModalBackdrop} onClick={handleSideMenuCollapse} />}
            <AntLayout.Header className={isE2E ? 'd-none' : 'p-0'} data-test-id="menu-layout-header" data-sentry-element="unknown" data-sentry-source-file="menu-layout.tsx">
                <MainMenuMemoizedWrapper menuType={isSiderActive ? 'small' : 'full'} setIsSideMenuCollapsed={setIsSideMenuCollapsed} isSideMenuCollapsed={isSideMenuCollapsed} data-sentry-element="MainMenuMemoizedWrapper" data-sentry-source-file="menu-layout.tsx" />
            </AntLayout.Header>
            {children}
        </AntLayout>;
};
export const MenuLayout = memo(MenuLayoutBase);