// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Last name of the user.
// Example: "Vobecka"

export const lastNameMinLength = 1;
export const lastNameMaxLength = 256;

export const LastNameSchema = t.refinement(t.string, n => n.length >= lastNameMinLength && n.length <= lastNameMaxLength, 'LastName');

export type LastName = string;
