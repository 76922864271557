// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingCount, MilkingCountSchema } from 'api/gen/MilkingCount';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingDayTime, MilkingDayTimeSchema } from 'api/gen/MilkingDayTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingInterval, MilkingIntervalSchema } from 'api/gen/MilkingInterval';

const requiredPart = t.interface({
  milkingCount: t.union([MilkingCountSchema, t.null]),
  milkingDayTime: t.union([MilkingDayTimeSchema, t.null]),
  milkingInterval: t.union([MilkingIntervalSchema, t.null]),
});

export const StableDateRelationMilkingSchema = excess(requiredPart);

export interface StableDateRelationMilking extends t.TypeOf<typeof StableDateRelationMilkingSchema> {}
