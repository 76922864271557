import {AppModule} from 'api/gen/AppModule';
import {ModuleGuideUrls} from 'api/gen/ModuleGuideUrls';

import {LoadingItemId} from '../types';

export const layoutAction = {
    setItemLoading: (id: LoadingItemId, isLoading: boolean) => ({
        type: 'layout/SET_ITEM_LOADING',
        payload: {id, isLoading},
    }) as const,
    getTutorials: (appModule: AppModule) => ({
        type: 'layout/GET_APP_TUTORIALS',
        payload: {appModule},
    }) as const,
    getTutorialsSuccess: (urls: ModuleGuideUrls | undefined) => ({
        type: 'layout/GET_APP_TUTORIALS_SUCCESS',
        payload: urls,
    }) as const,
    setStatisticalCookie: (enabled: boolean) => ({
        type: 'layout/SET_STATISTICAL_COOKIE',
        payload: enabled,
    }) as const,
};

export type SetItemLoadingAction = ReturnType<typeof layoutAction.setItemLoading>;
export type GetTutorialsAction = ReturnType<typeof layoutAction.getTutorials>;
export type GetTutorialsSuccessAction = ReturnType<typeof layoutAction.getTutorialsSuccess>;
export type SetStatisticalCookieAction = ReturnType<typeof layoutAction.setStatisticalCookie>;

export type LayoutAction =
    | SetItemLoadingAction
    | GetTutorialsAction
    | GetTutorialsSuccessAction
    | SetStatisticalCookieAction
;
