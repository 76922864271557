// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Breeder's registration number.
// Example: 204918

export const breederRegistrationNumberMinimum = 1.0; // inclusive
export const breederRegistrationNumberMaximum = 999999.0; // inclusive

export const BreederRegistrationNumberSchema = t.refinement(t.Integer, x => x >= breederRegistrationNumberMinimum && x <= breederRegistrationNumberMaximum, 'BreederRegistrationNumber');

export type BreederRegistrationNumber = t.TypeOf<typeof BreederRegistrationNumberSchema>;
