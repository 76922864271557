// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AssociationName, AssociationNameSchema } from 'api/gen/AssociationName';

const requiredPart = t.interface({
  id: t.Integer,
  name: AssociationNameSchema,
});

export const AssociationSchema = excess(requiredPart);

export interface Association extends t.TypeOf<typeof AssociationSchema> {}
