// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateSubjectMemberBase, ValidateSubjectMemberBaseSchema } from 'api/gen/ValidateSubjectMemberBase';

const requiredPart = t.interface({
  tag: t.literal('ValidateCreateSubjectMember'),
  approvedFrom: DateSchema,
  subjectId: PositiveIntegerSchema,
  userProfileId: PositiveIntegerSchema,
});

export const ValidateCreateSubjectMemberSchema = excess(t.intersection([ValidateSubjectMemberBaseSchema.type, requiredPart]));

export interface ValidateCreateSubjectMember extends t.TypeOf<typeof ValidateCreateSubjectMemberSchema> {}
