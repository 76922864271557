// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Stable in studbook registry

export const stableInStudbookRegistryMinLength = 1;
export const stableInStudbookRegistryMaxLength = 1;

export const StableInStudbookRegistrySchema = t.refinement(t.string, n => n.length >= stableInStudbookRegistryMinLength && n.length <= stableInStudbookRegistryMaxLength, 'StableInStudbookRegistry');

export type StableInStudbookRegistry = string;
