// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateCreateSubjectMember, ValidateCreateSubjectMemberSchema } from 'api/gen/ValidateCreateSubjectMember';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateUpdateSubjectMember, ValidateUpdateSubjectMemberSchema } from 'api/gen/ValidateUpdateSubjectMember';

export const ValidateSubjectMemberSchema = t.union([ValidateCreateSubjectMemberSchema, ValidateUpdateSubjectMemberSchema]);

export type ValidateSubjectMember = t.TypeOf<typeof ValidateSubjectMemberSchema>;
