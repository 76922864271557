// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(5, 2).
// Example: 5.0e-2

export const positiveOrZeroDecimal52Minimum = 0.0; // inclusive
export const positiveOrZeroDecimal52Maximum = 1000.0; // exclusive
export const positiveOrZeroDecimal52MinimumInclusive = 0.0;
export const positiveOrZeroDecimal52MaximumInclusive = 999.99;
export const positiveOrZeroDecimal52MinMaxInclusive: [number | null, number | null] = [0.0, 999.99];
export const positiveOrZeroDecimal52DecimalPlaces = 2;
export const positiveOrZeroDecimal52RegexGen = new RegExp("^\\d{1,3}[,.]\\d{2}$");

export const PositiveOrZeroDecimal52Schema = t.refinement(t.number, x => x >= positiveOrZeroDecimal52Minimum && x < positiveOrZeroDecimal52Maximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'PositiveOrZeroDecimal52');

export type PositiveOrZeroDecimal52 = t.TypeOf<typeof PositiveOrZeroDecimal52Schema>;
