// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  approvedFrom: DateSchema,
  approvedTo: t.union([DateSchema, t.null]),
  farmIds: t.union([t.array(PositiveIntegerSchema), t.null]),
  organizationCenterIds: t.union([t.array(PositiveIntegerSchema), t.null]),
  organizationId: PositiveIntegerSchema,
  roleIds: t.array(PositiveIntegerSchema),
  userProfileId: PositiveIntegerSchema,
});

export const CreateUserProfileOrganizationRoleSchema = excess(requiredPart);

export interface CreateUserProfileOrganizationRole extends t.TypeOf<typeof CreateUserProfileOrganizationRoleSchema> {}
