import { BasicRadioGroup } from '@fl/cmsch-fe-library';
import React, { useCallback, memo } from 'react';
import { opt, Opt } from 'ts-opt';
import { Ant } from 'common/ant';
import styles from './styles.sass';
export interface LanguageOption {
  language: string;
  label: string;
  icon: React.ReactElement;
}
interface Props {
  isSideMenu: boolean;
  languageOptions: Array<LanguageOption>;
  selectedLanguage: LanguageOption;
  onLanguageChange(language: string): void;
}
const LanguageSelectBase: React.FC<Props> = props => {
  const {
    isSideMenu,
    languageOptions,
    selectedLanguage,
    onLanguageChange
  } = props;
  const handleLanguageChange = useCallback((language: Opt<string>) => onLanguageChange(language.orCrash('must have value')), [onLanguageChange]);
  return <span data-test-id="language-select" data-sentry-component="LanguageSelectBase" data-sentry-source-file="language-select.tsx">
            <Ant.Popover trigger={isSideMenu ? 'click' : 'hover'} placement={isSideMenu ? 'rightBottom' : 'bottomRight'} align={{
      offset: isSideMenu ? [-16, -11] : [-16, -16]
    }} /* eslint-disable-line no-magic-numbers */ content={<div className={styles.languageSelectRadioGroup}>
                        <BasicRadioGroup value={opt(selectedLanguage.language)} onFieldChange={handleLanguageChange} options={languageOptions.map(({
        language,
        label,
        icon
      }) => ({
        value: language,
        label,
        icon
      }))} />
                    </div>} data-sentry-element="unknown" data-sentry-source-file="language-select.tsx">
                <div className={styles.languageSelect}>
                    {selectedLanguage.icon}
                </div>
            </Ant.Popover>
        </span>;
};
export const LanguageSelect = memo(LanguageSelectBase);