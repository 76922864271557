// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: FEMALE

export const BookmarkSchema = t.keyof({
  FEMALE: null,
  MALE: null,
  COMPUTATION: null,
});

export type Bookmark = t.TypeOf<typeof BookmarkSchema>;
