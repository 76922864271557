// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Overall class represented by characters.
// Example: "G+"

export const overallClassMinLength = 1;
export const overallClassMaxLength = 3;

export const OverallClassSchema = t.refinement(t.string, n => n.length >= overallClassMinLength && n.length <= overallClassMaxLength, 'OverallClass');

export type OverallClass = string;
