// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ExteriorTraitIdealWeight, ExteriorTraitIdealWeightSchema } from 'api/gen/ExteriorTraitIdealWeight';

const requiredPart = t.interface({
  backAngle: ExteriorTraitIdealWeightSchema,
  backWidth: ExteriorTraitIdealWeightSchema,
  bodyDepth: ExteriorTraitIdealWeightSchema,
  bonesQuality: ExteriorTraitIdealWeightSchema,
  chestWidth: ExteriorTraitIdealWeightSchema,
  edginess: ExteriorTraitIdealWeightSchema,
  fitness: ExteriorTraitIdealWeightSchema,
  foreUdderAttachment: ExteriorTraitIdealWeightSchema,
  frame: ExteriorTraitIdealWeightSchema,
  frontTeatsPlacement: ExteriorTraitIdealWeightSchema,
  hoovesAngle: ExteriorTraitIdealWeightSchema,
  rearPosture: ExteriorTraitIdealWeightSchema,
  rearTeatsPlacement: ExteriorTraitIdealWeightSchema,
  rearUdderAttachmentHeight: ExteriorTraitIdealWeightSchema,
  rearUdderAttachmentWidth: ExteriorTraitIdealWeightSchema,
  sidePosture: ExteriorTraitIdealWeightSchema,
  suspensoryLigament: ExteriorTraitIdealWeightSchema,
  teatsLength: ExteriorTraitIdealWeightSchema,
  udderDepth: ExteriorTraitIdealWeightSchema,
  walkQuality: ExteriorTraitIdealWeightSchema,
});

export const ExteriorTraitsWeightsSchema = excess(requiredPart);

export interface ExteriorTraitsWeights extends t.TypeOf<typeof ExteriorTraitsWeightsSchema> {}
