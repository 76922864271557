import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';
import {opt} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialLayoutState, LayoutState} from './state';

const layoutStateO = O.optic<LayoutState>();

export const layoutReducer = (
    state: LayoutState = initialLayoutState,
    action: AppAction,
): LayoutState => {
    switch (action.type) {
        case 'layout/SET_ITEM_LOADING': {
            const {id, isLoading} = action.payload;

            return O.modify(layoutStateO.prop('loadingItems'))(setItem(isLoading)(id))(state);
        }
        case 'layout/GET_APP_TUTORIALS_SUCCESS':
            return O.set(layoutStateO.prop('commonTutorialsUrls'))(opt(action.payload))(state);

        case 'layout/SET_STATISTICAL_COOKIE':
            return O.set(layoutStateO.prop('statisticalCookieEnabled'))(action.payload)(state);

        default:
            return state;
    }
};
