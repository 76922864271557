// Custom schema from src/api/custom/Uuid.ts

// Mandatory for pnpm gen-api command
import {StringPatternSchema} from '@fl/cmsch-fe-library';

export const genUuidRegex = () => /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i;

export const UuidSchema = StringPatternSchema<Uuid>(genUuidRegex());

export type Uuid = string;


