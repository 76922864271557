// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaFilterBase, CriteriaFilterBaseSchema } from 'api/gen/CriteriaFilterBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52, Decimal52Schema } from 'api/gen/Decimal52';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_5_2'),
  leftValue: t.union([Decimal52Schema, t.null]),
  rightValue: t.union([Decimal52Schema, t.null]),
});

export const CriteriaDecimal52FilterSchema = excess(t.intersection([CriteriaFilterBaseSchema.type, requiredPart]));

export interface CriteriaDecimal52Filter extends t.TypeOf<typeof CriteriaDecimal52FilterSchema> {}
