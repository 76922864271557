// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The controller name of the permission
// Example: "Admin"

export const permissionControllerMinLength = 1;
export const permissionControllerMaxLength = 128;

export const PermissionControllerSchema = t.refinement(t.string, n => n.length >= permissionControllerMinLength && n.length <= permissionControllerMaxLength, 'PermissionController');

export type PermissionController = string;
