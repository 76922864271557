// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Ear tag is a 14 characters long animal's sign consisting from 2-letter country code and 12-digits number, for example 'CZ000123456789'. This is base form called strict. There is
// an alternative form called 'stripped' with trimmed leading zeros from number part of the ear tag. This form is especially used for human input parsing, for example 'CZ000123456789'.
// Example: "CZ000123456789"

export const earTagRegexGen = (): RegExp => new RegExp("^([A-Z]{2})((?!000000000000)[0-9]{12})$");

export const EarTagSchema = StringPatternSchema<EarTag>(earTagRegexGen());

export type EarTag = string;
