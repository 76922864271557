// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApmvRange, ApmvRangeSchema } from 'api/gen/ApmvRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DataSource, DataSourceSchema } from 'api/gen/DataSource';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DaysBetweenExamAndCalving, DaysBetweenExamAndCalvingSchema } from 'api/gen/DaysBetweenExamAndCalving';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkedTotalRange, MilkedTotalRangeSchema } from 'api/gen/MilkedTotalRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingTimeRange, MilkingTimeRangeSchema } from 'api/gen/MilkingTimeRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TechnicianNumber, TechnicianNumberSchema } from 'api/gen/TechnicianNumber';

const requiredPart = t.interface({
  apmv: ApmvRangeSchema,
  breedAlpha6: BreedAlpha6Schema,
  breederName: BreederNameSchema,
  calveDate: DateSchema,
  dataSource: DataSourceSchema,
  daysBetweenExamAndCalving: DaysBetweenExamAndCalvingSchema,
  earTag: EarTagSchema,
  examinationDate: DateSchema,
  fatherLineRegistry: t.union([LineRegistrySchema, t.null]),
  id: PositiveIntegerSchema,
  lactationOrder: PositiveIntegerSchema,
  lastChangedBy: t.string,
  milkedTotal: MilkedTotalRangeSchema,
  milkingTime: MilkingTimeRangeSchema,
  organizationName: t.string,
  stableCode: StableCodeSchema,
  stableName: StableNameSchema,
  subsidyClaim: t.boolean,
  technicianNumber: t.union([TechnicianNumberSchema, t.null]),
  updated: DateTimeSchema,
});

export const MilkabilitySchema = excess(requiredPart);

export interface Milkability extends t.TypeOf<typeof MilkabilitySchema> {}
