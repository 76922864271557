// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The abbreviation of the full name of an animal
// Example: "IRON ORE"

export const animalCodeNameMinLength = 1;
export const animalCodeNameMaxLength = 30;

export const AnimalCodeNameSchema = t.refinement(t.string, n => n.length >= animalCodeNameMinLength && n.length <= animalCodeNameMaxLength, 'AnimalCodeName');

export type AnimalCodeName = string;
