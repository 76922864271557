// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Username, UsernameSchema } from 'api/gen/Username';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Uuid, UuidSchema } from 'api/gen/Uuid';

const requiredPart = t.interface({
  aaGuid: UuidSchema,
  active: t.boolean,
  firstName: t.union([FirstNameSchema, t.null]),
  lastName: t.union([LastNameSchema, t.null]),
  registrationEmail: t.union([t.string, t.null]),
  userProfileId: PositiveIntegerSchema,
  username: UsernameSchema,
});

export const UserViewSchema = excess(requiredPart);

export interface UserView extends t.TypeOf<typeof UserViewSchema> {}
