// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { MaleFleckviehAnimalInList, MaleFleckviehAnimalInListSchema } from 'api/gen/MaleFleckviehAnimalInList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';

const requiredPart = t.interface({
  content: t.union([t.array(MaleFleckviehAnimalInListSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelMaleFleckviehAnimalInListSchema = excess(requiredPart);

export interface PagedModelMaleFleckviehAnimalInList extends t.TypeOf<typeof PagedModelMaleFleckviehAnimalInListSchema> {}
