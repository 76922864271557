// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A breed in the alpha-13 format.
// Example: "H88 C12"

export const breedAlpha13RegexGen = (): RegExp => new RegExp("^[A-Z0-9 ]{1,13}$");

export const BreedAlpha13Schema = StringPatternSchema<BreedAlpha13>(breedAlpha13RegexGen());

export type BreedAlpha13 = string;
