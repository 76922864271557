interface PathParams { [key: string]: string }

export const createPathRegex = (path: string, params?: PathParams): RegExp => {
    const regexString = path.replace(/:([^/]+)/g, (_, paramName) => {
        const paramValue = params?.[paramName];
        return paramValue !== undefined ? paramValue : '[^/]+';
    });

    return new RegExp(`^${regexString}`);
};
