// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProductionAreaName, ProductionAreaNameSchema } from 'api/gen/ProductionAreaName';

const requiredPart = t.interface({
  id: PositiveOrZeroIntegerSchema,
  name: ProductionAreaNameSchema,
});

export const ProductionAreaSchema = excess(requiredPart);

export interface ProductionArea extends t.TypeOf<typeof ProductionAreaSchema> {}
