// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of the list.
// Example: "Kühe"

export const listNameMinLength = 1;
export const listNameMaxLength = 75;

export const ListNameSchema = t.refinement(t.string, n => n.length >= listNameMinLength && n.length <= listNameMaxLength, 'ListName');

export type ListName = string;
