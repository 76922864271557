// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Animal's exterior breeding value.
// Example: "Ext1"

export const exteriorMinLength = 1;
export const exteriorMaxLength = 7;

export const ExteriorSchema = t.refinement(t.string, n => n.length >= exteriorMinLength && n.length <= exteriorMaxLength, 'Exterior');

export type Exterior = string;
