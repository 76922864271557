// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenterCode, OrganizationCenterCodeSchema } from 'api/gen/OrganizationCenterCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenterName, OrganizationCenterNameSchema } from 'api/gen/OrganizationCenterName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  code: OrganizationCenterCodeSchema,
  id: PositiveIntegerSchema,
  name: t.union([OrganizationCenterNameSchema, t.null]),
});

export const OrganizationCenterSchema = excess(requiredPart);

export interface OrganizationCenter extends t.TypeOf<typeof OrganizationCenterSchema> {}
