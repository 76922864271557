// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Code of the subject.
// Example: "FZabovreCh"

export const subjectCodeMinLength = 1;
export const subjectCodeMaxLength = 10;

export const SubjectCodeSchema = t.refinement(t.string, n => n.length >= subjectCodeMinLength && n.length <= subjectCodeMaxLength, 'SubjectCode');

export type SubjectCode = string;
