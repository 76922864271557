// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: DISCARD

export const CorrectionTypeSchema = t.keyof({
  DISCARD: null,
  UPDATE: null,
  EAR_TAG_UPDATE: null,
});

export type CorrectionType = t.TypeOf<typeof CorrectionTypeSchema>;
