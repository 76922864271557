// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of a role
// Example: "Plemenné knihy - administrátor"

export const roleNameV1MinLength = 1;
export const roleNameV1MaxLength = 256;

export const RoleNameV1Schema = t.refinement(t.string, n => n.length >= roleNameV1MinLength && n.length <= roleNameV1MaxLength, 'RoleNameV1');

export type RoleNameV1 = string;
