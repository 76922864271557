// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederRegistrationNumber, BreederRegistrationNumberSchema } from 'api/gen/BreederRegistrationNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  businessName: t.union([SubjectBusinessNameSchema, t.null]),
  id: t.Integer,
  registrationNumber: BreederRegistrationNumberSchema,
});

export const CabBreederAutocompleteSchema = excess(requiredPart);

export interface CabBreederAutocomplete extends t.TypeOf<typeof CabBreederAutocompleteSchema> {}
