// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDetailsBase, CriteriaDetailsBaseSchema } from 'api/gen/CriteriaDetailsBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52CriteriaValues, Decimal52CriteriaValuesSchema } from 'api/gen/Decimal52CriteriaValues';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_5_2'),
  values: Decimal52CriteriaValuesSchema,
});

export const Decimal52CriteriaDetailsSchema = excess(t.intersection([CriteriaDetailsBaseSchema.type, requiredPart]));

export interface Decimal52CriteriaDetails extends t.TypeOf<typeof Decimal52CriteriaDetailsSchema> {}
