// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateApcDiscard, CreateApcDiscardSchema } from 'api/gen/CreateApcDiscard';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateApcEarTagUpdate, CreateApcEarTagUpdateSchema } from 'api/gen/CreateApcEarTagUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateApcUpdate, CreateApcUpdateSchema } from 'api/gen/CreateApcUpdate';

export const CreateApcSchema = t.union([CreateApcDiscardSchema, CreateApcEarTagUpdateSchema, CreateApcUpdateSchema]);

export type CreateApc = t.TypeOf<typeof CreateApcSchema>;
