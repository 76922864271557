// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDefinitionBase, CriteriaDefinitionBaseSchema } from 'api/gen/CriteriaDefinitionBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43CriteriaValues, Decimal43CriteriaValuesSchema } from 'api/gen/Decimal43CriteriaValues';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_4_3'),
  values: Decimal43CriteriaValuesSchema,
});

export const Decimal43CriteriaDefinitionSchema = excess(t.intersection([CriteriaDefinitionBaseSchema.type, requiredPart]));

export interface Decimal43CriteriaDefinition extends t.TypeOf<typeof Decimal43CriteriaDefinitionSchema> {}
