// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmRow, FarmRowSchema } from 'api/gen/FarmRow';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenterRow, OrganizationCenterRowSchema } from 'api/gen/OrganizationCenterRow';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleCode, RoleCodeSchema } from 'api/gen/RoleCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleName, RoleNameSchema } from 'api/gen/RoleName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';

const requiredPart = t.interface({
  accessToAllFarms: t.boolean,
  accessToAllOrganizationCenters: t.boolean,
  approvedFrom: DateSchema,
  approvedTo: t.union([DateSchema, t.null]),
  farms: t.union([t.array(FarmRowSchema), t.null]),
  organizationCenters: t.union([t.array(OrganizationCenterRowSchema), t.null]),
  roleCode: RoleCodeSchema,
  roleId: PositiveIntegerSchema,
  roleName: RoleNameSchema,
  rowId: RowIdSchema,
  userProfileOrganizationRoleId: PositiveIntegerSchema,
});

export const OrganizationRoleFacilitySchema = excess(requiredPart);

export interface OrganizationRoleFacility extends t.TypeOf<typeof OrganizationRoleFacilitySchema> {}
