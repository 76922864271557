// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(8, 0).
// Example: 8

export const decimal80Minimum = -100000000.0; // exclusive
export const decimal80Maximum = 100000000.0; // exclusive
export const decimal80MinimumInclusive = -99999998.99999999;
export const decimal80MaximumInclusive = 99999998.99999999;
export const decimal80MinMaxInclusive: [number | null, number | null] = [-99999998.99999999, 99999998.99999999];
export const decimal80DecimalPlaces = 0;
export const decimal80RegexGen = new RegExp("^-?\\d{1,8}$");

export const Decimal80Schema = t.refinement(t.number, x => x > decimal80Minimum && x < decimal80Maximum && Number.isInteger(parseFloat((x / 1.0).toFixed(10))), 'Decimal80');

export type Decimal80 = t.TypeOf<typeof Decimal80Schema>;
