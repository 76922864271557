// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApmvRange, ApmvRangeSchema } from 'api/gen/ApmvRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkedTotalRange, MilkedTotalRangeSchema } from 'api/gen/MilkedTotalRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingTimeRange, MilkingTimeRangeSchema } from 'api/gen/MilkingTimeRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TechnicianNumber, TechnicianNumberSchema } from 'api/gen/TechnicianNumber';

const requiredPart = t.interface({
  apmv: ApmvRangeSchema,
  earTag: EarTagSchema,
  examinationDate: DateSchema,
  id: PositiveIntegerSchema,
  milkedTotal: MilkedTotalRangeSchema,
  milkingTime: MilkingTimeRangeSchema,
  technicianNumber: t.union([TechnicianNumberSchema, t.null]),
});

export const MilkabilityDetailSchema = excess(requiredPart);

export interface MilkabilityDetail extends t.TypeOf<typeof MilkabilityDetailSchema> {}
