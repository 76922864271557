// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Corrected average monthly milking (APMV) value boundaries validation annotation.
// Example: 7.0

export const apmvRangeMinimum = 0.7; // inclusive
export const apmvRangeMaximum = 10.0; // inclusive
export const apmvRangeMinimumInclusive = 0.7;
export const apmvRangeMaximumInclusive = 10.0;
export const apmvRangeMinMaxInclusive: [number | null, number | null] = [0.7, 10.0];
export const apmvRangeDecimalPlaces = 2;
export const apmvRangeRegexGen = new RegExp("^\\d{1,2}[,.]\\d{2}$");

export const ApmvRangeSchema = t.refinement(t.number, x => x >= apmvRangeMinimum && x <= apmvRangeMaximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'ApmvRange');

export type ApmvRange = t.TypeOf<typeof ApmvRangeSchema>;
