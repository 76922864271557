// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Milking time value boundaries in seconds.
// Example: 120

export const milkingTimeRangeMinimum = 120.0; // inclusive
export const milkingTimeRangeMaximum = 1500.0; // inclusive

export const MilkingTimeRangeSchema = t.refinement(t.Integer, x => x >= milkingTimeRangeMinimum && x <= milkingTimeRangeMaximum, 'MilkingTimeRange');

export type MilkingTimeRange = t.TypeOf<typeof MilkingTimeRangeSchema>;
