// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of a production area
// Example: "Kukuřičná"

export const productionAreaNameMinLength = 1;
export const productionAreaNameMaxLength = 50;

export const ProductionAreaNameSchema = t.refinement(t.string, n => n.length >= productionAreaNameMinLength && n.length <= productionAreaNameMaxLength, 'ProductionAreaName');

export type ProductionAreaName = string;
