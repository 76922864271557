// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDetailsBase, CriteriaDetailsBaseSchema } from 'api/gen/CriteriaDetailsBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43CriteriaValues, Decimal43CriteriaValuesSchema } from 'api/gen/Decimal43CriteriaValues';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_4_3'),
  values: Decimal43CriteriaValuesSchema,
});

export const Decimal43CriteriaDetailsSchema = excess(t.intersection([CriteriaDetailsBaseSchema.type, requiredPart]));

export interface Decimal43CriteriaDetails extends t.TypeOf<typeof Decimal43CriteriaDetailsSchema> {}
