// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: E_MAIL

export const ContactTypeSchema = t.keyof({
  E_MAIL: null,
  PHONE: null,
  FAX: null,
});

export type ContactType = t.TypeOf<typeof ContactTypeSchema>;
