import {none, Opt} from 'ts-opt';

import {ModuleGuideUrls} from 'api/gen/ModuleGuideUrls';

import {LoadingItemId} from '../types';

export interface LayoutState {
    loadingItems: Array<LoadingItemId>;
    commonTutorialsUrls: Opt<ModuleGuideUrls>;
    statisticalCookieEnabled: boolean;
}

export const initialLayoutState: LayoutState = {
    loadingItems: [],
    commonTutorialsUrls: none,
    statisticalCookieEnabled: false,
};
