// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Organization center name.
// Example: "Josef Bárta"

export const organizationCenterNameMinLength = 1;
export const organizationCenterNameMaxLength = 100;

export const OrganizationCenterNameSchema = t.refinement(t.string, n => n.length >= organizationCenterNameMinLength && n.length <= organizationCenterNameMaxLength, 'OrganizationCenterName');

export type OrganizationCenterName = string;
