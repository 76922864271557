import { classNames } from '@fl/cmsch-fe-library';
import React, { memo } from 'react';
import { Ant } from 'common/ant';
import styles from './styles.sass';
interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  fullWidth?: boolean;
  titleSize?: 'big';
  noPadding?: boolean;
  leftMargin?: boolean;
  extra?: React.ReactNode;
  small?: boolean;
  noHeadBorder?: boolean;
  testId?: string;
}
const CardBase: React.FC<Props> = props => {
  const {
    title,
    children,
    fullWidth,
    titleSize,
    noPadding,
    extra,
    small,
    leftMargin,
    noHeadBorder,
    testId
  } = props;
  const className = classNames(fullWidth ? 'w-100' : 'w-auto', titleSize === 'big' && styles.titleSizeBig, styles.card, leftMargin && styles.leftMargin);
  const bodyStyle = noPadding ? {
    padding: 0
  } : undefined;
  const headStyle = noHeadBorder ? {
    border: 'none'
  } : undefined;
  return <Ant.Card data-test-id={testId} title={title} className={className} bodyStyle={bodyStyle} headStyle={headStyle} extra={extra} size={small ? 'small' : undefined} data-sentry-element="unknown" data-sentry-component="CardBase" data-sentry-source-file="index.tsx">
            {children}
        </Ant.Card>;
};
export const Card = memo(CardBase);