// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Unique identifier for rows in authorization module.
// Example: "1.12"

export const rowIdRegexGen = (): RegExp => new RegExp("^[0-9]+(\\.[0-9]+([./][0-9]+)?)?$");

export const RowIdSchema = StringPatternSchema<RowId>(rowIdRegexGen());

export type RowId = string;
