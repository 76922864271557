// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AuthDiscriminator, AuthDiscriminatorSchema } from 'api/gen/AuthDiscriminator';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleCode, RoleCodeSchema } from 'api/gen/RoleCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleName, RoleNameSchema } from 'api/gen/RoleName';

const requiredPart = t.interface({
  code: RoleCodeSchema,
  discriminator: AuthDiscriminatorSchema,
  id: PositiveIntegerSchema,
  name: RoleNameSchema,
  permissionCount: PositiveOrZeroIntegerSchema,
  userCount: PositiveOrZeroIntegerSchema,
  userEnable: t.boolean,
});

export const RoleViewSchema = excess(requiredPart);

export interface RoleView extends t.TypeOf<typeof RoleViewSchema> {}
