// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateApcBase, ValidateApcBaseSchema } from 'api/gen/ValidateApcBase';

const requiredPart = t.interface({
  correctionType: t.literal('UPDATE'),
});

export const ValidateApcUpdateSchema = excess(t.intersection([ValidateApcBaseSchema.type, requiredPart]));

export interface ValidateApcUpdate extends t.TypeOf<typeof ValidateApcUpdateSchema> {}
