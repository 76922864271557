// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDefinitionBase, CriteriaDefinitionBaseSchema } from 'api/gen/CriteriaDefinitionBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52CriteriaValues, Decimal52CriteriaValuesSchema } from 'api/gen/Decimal52CriteriaValues';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_5_2'),
  values: Decimal52CriteriaValuesSchema,
});

export const Decimal52CriteriaDefinitionSchema = excess(t.intersection([CriteriaDefinitionBaseSchema.type, requiredPart]));

export interface Decimal52CriteriaDefinition extends t.TypeOf<typeof Decimal52CriteriaDefinitionSchema> {}
