// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Milk production represented by integer values.
// Example: 0

export const milkProductionBvMinimum = -99999.0; // inclusive
export const milkProductionBvMaximum = 99999.0; // inclusive

export const MilkProductionBvSchema = t.refinement(t.Integer, x => x >= milkProductionBvMinimum && x <= milkProductionBvMaximum, 'MilkProductionBv');

export type MilkProductionBv = t.TypeOf<typeof MilkProductionBvSchema>;
