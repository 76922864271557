// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(8, 0).
// Example: 8

export const positiveOrZeroDecimal80Minimum = 0.0; // inclusive
export const positiveOrZeroDecimal80Maximum = 100000000.0; // exclusive
export const positiveOrZeroDecimal80MinimumInclusive = 0.0;
export const positiveOrZeroDecimal80MaximumInclusive = 99999998.99999999;
export const positiveOrZeroDecimal80MinMaxInclusive: [number | null, number | null] = [0.0, 99999998.99999999];
export const positiveOrZeroDecimal80DecimalPlaces = 0;
export const positiveOrZeroDecimal80RegexGen = new RegExp("^\\d{1,8}$");

export const PositiveOrZeroDecimal80Schema = t.refinement(t.number, x => x >= positiveOrZeroDecimal80Minimum && x < positiveOrZeroDecimal80Maximum && Number.isInteger(parseFloat((x / 1.0).toFixed(10))), 'PositiveOrZeroDecimal80');

export type PositiveOrZeroDecimal80 = t.TypeOf<typeof PositiveOrZeroDecimal80Schema>;
