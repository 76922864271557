// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(4, 2).
// Example: 4.0e-2

export const decimal42Minimum = -100.0; // exclusive
export const decimal42Maximum = 100.0; // exclusive
export const decimal42MinimumInclusive = -99.99;
export const decimal42MaximumInclusive = 99.99;
export const decimal42MinMaxInclusive: [number | null, number | null] = [-99.99, 99.99];
export const decimal42DecimalPlaces = 2;
export const decimal42RegexGen = new RegExp("^-?\\d{1,2}[,.]\\d{2}$");

export const Decimal42Schema = t.refinement(t.number, x => x > decimal42Minimum && x < decimal42Maximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'Decimal42');

export type Decimal42 = t.TypeOf<typeof Decimal42Schema>;
