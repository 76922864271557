import {replace, split, size} from 'lodash/fp';
import {flow, at, orElse} from 'ts-opt';

const decimalPartIndex = 1;

export const countDecimalPlaces: (inputString: string) => number = flow(
    replace(',', '.'),
    split('.'),
    at(decimalPartIndex),
    orElse(''),
    size,
);
