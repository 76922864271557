import {beDateFormat, DateRangeType} from '@fl/cmsch-fe-library';
import moment from 'moment';

const isGreater = (firstValue: string, secondValue: string): boolean =>
    moment(firstValue).toDate().getTime() > moment(secondValue).toDate().getTime();

const isValidBeDate = (value: string): boolean =>
    moment(value, beDateFormat, true).isValid();

export const validateFormat = (value: DateRangeType | undefined | null): string | undefined => {
    if (value?.[0] && !isValidBeDate(value[0])) {
        return 'Počáteční datum je chybně vyplněno.';
    }
    if (value?.[1] && !isValidBeDate(value[1])) {
        return 'Koncové datum je chybně vyplněno.';
    }

    return undefined;
};

export const maxIsGreater = (value: DateRangeType | undefined | null): string | undefined =>
    value && (value[0] !== null && value[1] !== null && isGreater(value[0], value[1]))
        ? 'Počáteční datum musí obsahovat dřívější a nebo stejné datum jako koncové datum.'
        : undefined;

export const validateMaxValue = (max: string) =>
    (value: DateRangeType | undefined | null): string | undefined =>
        value?.[1] && isGreater(value[1], max)
            ? `Koncové datum nesmí být vyšší než ${max}`
            : undefined;

export const validateMinValue = (min: string) =>
    (value: DateRangeType | undefined | null): string | undefined =>
        value?.[0] && isGreater(min, value[0])
            ? `Počáteční datum nesmí být nižší než ${min}`
            : undefined;
