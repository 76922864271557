/* eslint-disable max-lines, @typescript-eslint/no-unnecessary-condition */

import {
    KeysOfType,
    isCinControlSumValid,
    cinPattern,
    TimeInputValue,
    isAccountNumberControlSumValid,
    accountNumberPattern,
    emailPattern,
} from '@fl/cmsch-fe-library';
import {TFuncKey} from 'i18next';
import {includes, find, forEach, isArray, isString, isFinite, isNumber} from 'lodash/fp';
import {opt, optEmptyString} from 'ts-opt';

import {t} from 'app/translations';

import {formatNumberByCurrentLanguage} from './format-number-by-current-language';

const te = t('common/validator');

export interface ValidationError {
    [_: string]: string;
}

type MapOptionalFields<T, U> = { [K in keyof T]?: MapOptionalFieldsAndArrays<T[K], U> };
type MapArray<U, A> = Array<MapOptionalFieldsAndArrays<A, U>>;
type MapOptionalFieldsAndArrays<T, U> = T extends object
    ? MapOptionalFields<T, U>
    : T extends Array<infer A>
        ? MapArray<U, A>
        : U;
export type ObjectErrors<T> = MapOptionalFields<T, string>;
// type ArrayErrors<T extends Array<A>, A> = MapArray<string, A>;
export type Errors<T> = MapOptionalFieldsAndArrays<T, string>;

export type Warnings<Values> = {
    [K in keyof Values]?: string;
};

type BetweenNumbersMessageType = 'time' | 'betweenNumbers';

const getBetweenNumbersMessage = (
    type: BetweenNumbersMessageType,
    unit: string | undefined,
): TFuncKey<'common/validator'> => {
    if (type === 'time') return 'betweenTimes';
    return unit ? 'betweenNumbers' : 'betweenNumbersNoUnit';
};
interface BetweenNumbersOptions<T> {
    fieldName: keyof T;
    min: number;
    max: number;
    label: string;
    unit?: string;
    precision?: number;
    inclusive?: boolean;
    messageType?: BetweenNumbersMessageType;
    convertErrorDisplayValue?(value: number): number;
}

const validationRegularExpressions = Object.freeze({
    dic: '^CZ[0-9]{8,10}$',
    pin: '^[0-9]{6}[/]?[0-9]{3,4}$',
    zip: '^[0-9]{3}[ ]?[0-9]{2}$',
});

/**
 * Validates form values.
 */
export class Validator<Values> {
    protected readonly errors: ObjectErrors<Values>;

    protected readonly formatNumber = formatNumberByCurrentLanguage();

    constructor(protected readonly values: Values) {
        this.errors = {};
        opt(values).orCrash('values are missing');
    }

    public static genIsRequiredError(label: string): string {
        return te('isRequired', {label});
    }

    public static genMustBeTrueError(label: string): string {
        return te('mustBeChecked', {label});
    }

    /**
     * Characters word translation.
     * @param count
     */
    public static charsT(count: number): string {
        const ONE = 1;
        const FOUR = 4;
        if (count === ONE) {
            return te('oneChar');
        } else if (count <= FOUR) {
            return te('lessThanFiveChars');
        } else {
            return te('moreChars');
        }
    }

    public static genMinLenError(label: string, minLen: number): string {
        return te('minLength', {label, minLen, characters: this.charsT(minLen)});
    }

    public static genMaxLenError(label: string, maxLen: number): string {
        return te('maxLength', {label, maxLen, characters: this.charsT(maxLen)});
    }

    public static getInvalidIcoError(label: string): string {
        return te('mustHaveFormat', {label});
    }

    public static getInvalidDicError(label: string): string {
        return te('mustHaveFormat', {label});
    }

    public static getInvalidPinError(label: string): string {
        return te('mustHaveFormat', {label});
    }

    public static getInvalidZipError(label: string): string {
        return te('mustHaveFormat', {label});
    }

    public static getInvalidAccountNumberError(label: string): string {
        return te('mustHaveFormat', {label});
    }

    public static genPatternError(label: string): string {
        return te('wrongFormat', {label});
    }

    public static genIntegerNumberError(label: string): string {
        return te('isNotInteger', {label});
    }

    public static genFloatNumberError(label: string): string {
        return te('isNotFloatNumber', {label});
    }

    public static genMaxNumberError(label: string, max: number): string {
        return te('maxNumber', {label, max});
    }

    public static genMinNumberError(label: string, min: number): string {
        return te('minNumber', {label, min});
    }

    public static genNonNegativeNumberError(label: string): string {
        return te('cantBeNegativeNumber', {label});
    }

    public static genNumberMaxOneDecimalPlaceError(label: string): string {
        return te('isNotFloatOneDecimal', {label});
    }

    public static genArrayLengthError(label: string, length: number): string {
        return te('arrayLength', {label, length});
    }

    public static genUniqueError(label: string): string {
        return te('uniqueError', {label});
    }

    /**
     * Is field empty?
     * String is considered also empty if it contains only whitespace characters.
     * @param fieldName
     */
    public checkIsEmpty(fieldName: keyof Values): boolean {
        const value = this.values[fieldName];
        if (opt(value).isEmpty) {
            return true;
        } else if (isString(value) && !value.trim()) {
            return true;
        } else if (isArray(value) && value.length === 0) {
            return true;
        }

        return false;
    }

    /**
     * Validates field to have a filled value.
     * @param fieldName
     * @param label
     */
    public nonEmpty(fieldName: keyof Values, label: string): void {
        if (this.checkIsEmpty(fieldName)) {
            const errStr = Validator.genIsRequiredError(label);
            this.setErrorForField(fieldName, errStr);
        }
    }

    /**
     * Is field checked
     * @param fieldName
     * @param label
     */
    public isTrue(fieldName: KeysOfType<Values, boolean | undefined | null>, label: string): void {
        const value: unknown = this.values[fieldName];
        if (value !== true) {
            const errStr = Validator.genMustBeTrueError(label);
            this.setErrorForField(fieldName, errStr);
        }
    }

    /**
     * Validates fields to have a filled value one of them.
     * @param fieldNames
     * @param label
     */
    public oneIsFilled(fieldNames: Array<keyof Values>, label: string): void {
        const isFilled = find((x: keyof Values) => !this.checkIsEmpty(x), fieldNames);
        if (isFilled === undefined) {
            forEach((x: keyof Values) => this.setErrorForField(x, label), fieldNames);
        }
    }

    /**
     * Validates string field to have at least {@param minLen} characters.
     * Does not fail when a field is empty. Use {@link nonEmpty} as well, if you don't want to allow empty strings.
     * @param fieldName
     * @param minLen
     * @param label
     */
    public minStringLength(
        fieldName: KeysOfType<Values, string | undefined | null>,
        minLen: number,
        label: string,
    ): void {
        if (minLen <= 0) {
            throw new Error(`Invalid minLen = ${minLen}.`);
        }
        const value: unknown = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its length.`);
        }
        if (value.length < minLen) {
            this.setErrorForField(fieldName, Validator.genMinLenError(label, minLen));
        }
    }

    /**
     * Validates string field to have at most {@param maxLen} characters.
     * @param fieldName
     * @param maxLen
     * @param label
     */
    public maxStringLength(
        fieldName: KeysOfType<Values, string | undefined | null>,
        maxLen: number,
        label: string,
    ): void {
        if (maxLen <= 0) {
            throw new Error(`Invalid maxLen = ${maxLen}.`);
        }
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its length.`);
        }
        if (value.length > maxLen) {
            this.setErrorForField(fieldName, Validator.genMaxLenError(label, maxLen));
        }
    }

    /**
     * Check if ico is in valid format
     * @param fieldName
     * @param label
     */
    public validIco(fieldName: KeysOfType<Values, string | undefined | null>, label: string): void {
        const value = this.values[fieldName];

        if (optEmptyString(value).isEmpty) return;

        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its ico validity.`);
        }

        if (!cinPattern.test(value)) {
            this.setErrorForField(fieldName, Validator.getInvalidIcoError(label));
        } else if (!isCinControlSumValid(value)) {
            this.setErrorForField(fieldName, te('incorrectChecksum'));
        }
    }

    /**
     * Check if dic is in valid format
     * @param fieldName
     * @param label
     */
    public validDic(fieldName: KeysOfType<Values, string | undefined | null>, label: string): void {
        const regex = RegExp(validationRegularExpressions.dic);
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its dic validity.`);
        }
        if (!regex.test(value)) {
            this.setErrorForField(fieldName, Validator.getInvalidDicError(label));
        }
    }

    /**
     * Check if pin is in valid format
     * @param fieldName
     * @param label
     */
    public validPin(fieldName: KeysOfType<Values, string | undefined | null>, label: string): void {
        const regex = RegExp(validationRegularExpressions.pin);
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its pin validity.`);
        }
        if (!regex.test(value)) {
            this.setErrorForField(fieldName, Validator.getInvalidPinError(label));
        }
    }

    /**
     * Check if zip is in valid format
     * @param fieldName
     * @param label
     */
    public validZip(fieldName: KeysOfType<Values, string | undefined | null>, label: string): void {
        const regex = RegExp(validationRegularExpressions.zip);
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its zip validity.`);
        }
        if (!regex.test(value)) {
            this.setErrorForField(fieldName, Validator.getInvalidZipError(label));
        }
    }

    /**
     * Check if account number is in valid format
     * @param fieldName
     * @param label
     */
    public validAccountNumber(fieldName: KeysOfType<Values, string | undefined | null>, label: string): void {
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its account number validity.`);
        }
        if (!accountNumberPattern.test(value)) {
            this.setErrorForField(fieldName, Validator.getInvalidAccountNumberError(label));
        } else if (!isAccountNumberControlSumValid(value)) {
            this.setErrorForField(fieldName, te('incorrectChecksum'));
        }
    }

    /**
     * Validates string field to match a regular expression.
     * @param fieldName
     * @param regex
     * @param label
     */
    public pattern(fieldName: KeysOfType<Values, string | undefined | null>, regex: RegExp, label: string): void {
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate pattern.`);
        }
        if (!regex.test(value)) {
            this.setErrorForField(fieldName, Validator.genPatternError(label));
        }
    }

    /**
     * Validates field to be an actual number.
     * @param fieldName
     * @param label
     */
    public floatNumber(fieldName: KeysOfType<Values, number | undefined | null>, label: string): void {
        const value = this.values[fieldName];
        if (value === undefined || value === null) return;
        if (typeof value !== 'number' || !isFinite(value)) {
            this.setErrorForField(fieldName, Validator.genFloatNumberError(label));
        }
    }

    /**
     * Validates field to be an actual whole number.
     * @param fieldName
     * @param label
     */
    public integerNumber(fieldName: KeysOfType<Values, number | undefined | null>, label: string): void {
        const value = this.values[fieldName];
        if (value === undefined || value === null) return;
        if (typeof value !== 'number' || !isFinite(value) || !Number.isInteger(value)) {
            this.setErrorForField(fieldName, Validator.genIntegerNumberError(label));
        }
    }

    /**
     * Validates field to be an non-negative number.
     * @param fieldName
     * @param label
     */
    public nonNegativeNumber(fieldName: KeysOfType<Values, number | undefined | null>, label: string): void {
        const value = this.values[fieldName];

        if (value === undefined || value === null) return;
        if (typeof value !== 'number' || !isFinite(value) || value < 0) {
            this.setErrorForField(fieldName, Validator.genNonNegativeNumberError(label));
        }
    }

    /**
     * Validates number field to be a number lower than max
     * @param {string} fieldName
     * @param {number} max
     * @param label
     */
    public maxNumber(fieldName: KeysOfType<Values, number | undefined | null>, label: string, max: number): void {
        const value = this.values[fieldName];
        if (value === undefined || value === null) return;

        if (typeof value !== 'number') {
            throw new Error(`Value ${String(value)} is not number`);
        }

        if (value > max) {
            this.setErrorForField(fieldName, Validator.genMaxNumberError(label, max));
        }
    }

    /**
     * Validates number field to be a number higher than min
     * @param {string} fieldName
     * @param {number} min
     * @param label
     */
    public minNumber(fieldName: KeysOfType<Values, number | undefined | null>, label: string, min: number): void {
        const value = this.values[fieldName];
        if (value === undefined || value === null) return;

        if (typeof value !== 'number') {
            throw new Error(`Value ${String(value)} is not number`);
        }

        if (value < min) {
            this.setErrorForField(fieldName, Validator.genMinNumberError(label, min));
        }
    }

    /**
     * Validates array field to be of exact length.
     * @param fieldName
     * @param length
     * @param label
     */
    public arrayLength(fieldName: keyof Values, length: number, label: string): void {
        const value = this.values[fieldName];
        if (opt(value).isEmpty) return;
        if (!isArray(value)) {
            throw new Error(`Field ${String(fieldName)} is not an array. Cannot validate array length.`);
        }
        if (value.length !== length) {
            this.setErrorForField(fieldName, Validator.genArrayLengthError(label, length));
        }
    }

    /**
     * Validates field to have a unique value.
     * @param fieldName
     * @param collection
     * @param label
     */
    public unique(fieldName: keyof Values, collection: Array<Values[keyof Values]>, label: string): void {
        const value = this.values[fieldName];
        if (!value) return;
        const duplicity = includes(value, collection);
        if (duplicity) {
            this.setErrorForField(fieldName, Validator.genUniqueError(label));
        }
    }

    public email(fieldName: KeysOfType<Values, string | undefined | null>, label: string): void {
        this.pattern(fieldName, emailPattern, label);
    }

    /**
     * Validates password field to be equal.
     * @param fieldName1
     * @param fieldName2
     */
    public passwordsAreEqual(
        fieldName1: KeysOfType<Values, string | undefined | null>,
        fieldName2: KeysOfType<Values, string | undefined | null>,
    ): void {
        const value1 = this.values[fieldName1];
        const value2 = this.values[fieldName2];
        if (!value1 || !value2) return;
        if (value1 !== value2) {
            this.setErrorForField(fieldName1, te('passwordsMustMatch'));
            this.setErrorForField(fieldName2, te('passwordsMustMatch'));
        }
    }

    public betweenNumbers({
        fieldName,
        label,
        max,
        min,
        precision = 0,
        unit,
        inclusive,
        messageType = 'betweenNumbers',
        convertErrorDisplayValue,
    }: BetweenNumbersOptions<Values>): void {
        const value = this.values[fieldName];
        this.calculateBetweenNumbersError(value, {
            label,
            min,
            max,
            precision,
            unit,
            inclusive,
            convertErrorDisplayValue,
            fieldName,
            messageType,
        });
    }

    // eslint-disable-next-line max-lines-per-function
    public betweenTimes({
        fieldName,
        label,
        max,
        min,
        precision = 0,
        unit,
        inclusive,
        convertErrorDisplayValue,
        messageType = 'time',
    }: BetweenNumbersOptions<Values>): void {
        const timeInputValue = this.values[fieldName] as unknown as TimeInputValue;
        this.calculateBetweenNumbersError(timeInputValue?.value, {
            label,
            min,
            max,
            precision,
            unit,
            inclusive,
            convertErrorDisplayValue,
            fieldName,
            messageType,
        });

        if (timeInputValue?.secondsOverLimit > 0) {
            this.setErrorForField(fieldName, te(getBetweenNumbersMessage(messageType, unit), {
                min: this.formatNumber(precision)(convertErrorDisplayValue?.(min) || min),
                max: this.formatNumber(precision)(convertErrorDisplayValue?.(max) || max),
                unit,
                label,
            }));
        }
    }

    public customRule(fieldName: keyof Values, isError: boolean, errorMessage: string): void {
        if (isError) this.setErrorForField(fieldName, errorMessage);
    }

    /**
     * Return accumulated warnings.
     */
    public generateWarningsObject(): Warnings<Values> {
        return this.errors as Warnings<Values>;
    }

    /**
     * Return accumulated errors.
     */
    public generateErrorsObject(): ObjectErrors<Values> {
        return this.errors;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    protected setErrorForField(fieldName: keyof Values, error: any): void {
        this.errors[fieldName] = error;
    }

    private readonly calculateBetweenNumbersError = (value: number | Values[keyof Values], {
        label,
        unit,
        min,
        max,
        inclusive,
        fieldName,
        precision = 0,
        messageType = 'betweenNumbers',
        convertErrorDisplayValue,
    }: BetweenNumbersOptions<Values>): void => {
        if (opt(value).isEmpty) return;
        if (!isNumber(value)) {
            throw new Error(`Field ${String(fieldName)} is not a number.`);
        }

        if (inclusive ? value < min || value > max : value <= min || value >= max) {
            this.setErrorForField(fieldName, te(getBetweenNumbersMessage(messageType, unit), {
                min: this.formatNumber(precision)(convertErrorDisplayValue?.(min) || min),
                max: this.formatNumber(precision)(convertErrorDisplayValue?.(max) || max),
                unit,
                label,
            }));
        }
    };
}
