// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The value of the contact.
// Example: "michal.hajek@mhservis.cz"

export const contactValueMinLength = 1;
export const contactValueMaxLength = 256;

export const ContactValueSchema = t.refinement(t.string, n => n.length >= contactValueMinLength && n.length <= contactValueMaxLength, 'ContactValue');

export type ContactValue = string;
