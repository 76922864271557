import React, { FC, memo } from 'react';
import styles from './styles.sass';
interface Props {
  image: string;
  title?: string;
}
const SvgImageBase: FC<Props> = props => {
  const {
    image,
    title
  } = props;
  return <span title={title}
  // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
  dangerouslySetInnerHTML={{
    __html: image
  }} className={styles.svgImage} data-sentry-component="SvgImageBase" data-sentry-source-file="svg-image.tsx" />;
};
export const SvgImage = memo(SvgImageBase);