// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  MsSqlHintsInterceptor: t.string,
  custExtensions: t.string,
  eSkotDAL: t.string,
  eSkotLib: t.string,
  eSkotTranslations: t.string,
  eSkotWeb: t.string,
});

export const AssemblyVersionsSchema = excess(requiredPart);

export interface AssemblyVersions extends t.TypeOf<typeof AssemblyVersionsSchema> {}
