// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateOrUpdateGridUserSettings, CreateOrUpdateGridUserSettingsSchema } from 'api/gen/CreateOrUpdateGridUserSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ListName, ListNameSchema } from 'api/gen/ListName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { WriteCriteria, WriteCriteriaSchema } from 'api/gen/WriteCriteria';

const requiredPart = t.interface({
  criteria: t.array(WriteCriteriaSchema),
  gridUserSettings: t.union([CreateOrUpdateGridUserSettingsSchema, t.null]),
  name: ListNameSchema,
});

export const CreateCriteriaListSchema = excess(requiredPart);

export interface CreateCriteriaList extends t.TypeOf<typeof CreateCriteriaListSchema> {}
