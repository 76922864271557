import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/app';
const getAppElement = (): HTMLElement => {
  const element = document.getElementById('app');
  if (!element) throw new Error('app element not found');
  return element;
};
const root = createRoot(getAppElement());
const renderApplication = (): void => {
  root.render(<App />);
};
renderApplication();