// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-layout-components-screen-header-___styles__tutorialsWrapper___YVxZt{margin-left:auto}.src-common-layout-components-screen-header-___styles__tutorialIcon___vdf4v{display:inline-flex;margin-right:10px}.src-common-layout-components-screen-header-___styles__tutorialIcon___vdf4v span>svg{width:25px;height:25px}
`, "",{"version":3,"sources":["webpack://./src/common/layout/components/screen-header/styles.sass"],"names":[],"mappings":"AAAA,gFAAkB,gBAAA,CAAiB,4EAAc,mBAAA,CAAoB,iBAAA,CAAkB,qFAAuB,UAAA,CAAW,WAAA","sourcesContent":[".tutorialsWrapper{margin-left:auto}.tutorialIcon{display:inline-flex;margin-right:10px}.tutorialIcon span>svg{width:25px;height:25px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tutorialsWrapper": `src-common-layout-components-screen-header-___styles__tutorialsWrapper___YVxZt`,
	"tutorialIcon": `src-common-layout-components-screen-header-___styles__tutorialIcon___vdf4v`
};
export default ___CSS_LOADER_EXPORT___;
