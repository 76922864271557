// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateOrUpdateGridUserSettings, CreateOrUpdateGridUserSettingsSchema } from 'api/gen/CreateOrUpdateGridUserSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowVersion, RowVersionSchema } from 'api/gen/RowVersion';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { WriteCriteria, WriteCriteriaSchema } from 'api/gen/WriteCriteria';

const requiredPart = t.interface({
  criteria: t.array(WriteCriteriaSchema),
  gridUserSettings: t.union([CreateOrUpdateGridUserSettingsSchema, t.null]),
  version: RowVersionSchema,
});

export const UpdateCriteriaListSchema = excess(requiredPart);

export interface UpdateCriteriaList extends t.TypeOf<typeof UpdateCriteriaListSchema> {}
