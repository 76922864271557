// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  pdfUrl: t.union([t.string, t.null]),
  videoUrl: t.union([t.string, t.null]),
});

export const ModuleGuideUrlsSchema = excess(requiredPart);

export interface ModuleGuideUrls extends t.TypeOf<typeof ModuleGuideUrlsSchema> {}
