import {beDateFormat, insertElemsAt, removeAt} from '@fl/cmsch-fe-library';
import moment, {Moment} from 'moment';

interface KeyboardDate {
    date: Moment;
    formattedDate: string;
    isEmpty: boolean;
    isValid: boolean;
}

const dateFormat = 'D.M.YYYY';

export const insertedDateOnKeyboard = (previousDate: string, key: string, position: number | null): KeyboardDate => {
    const isBackspace = key === 'Backspace';
    const positionIndex = position !== null ? position : 0;
    const insertedDate = !isBackspace
        ? insertElemsAt(positionIndex)(previousDate)(key)
        : removeAt(positionIndex - 1)(previousDate);
    const insertedDateWithNoSpaces = insertedDate.replace(/ /g, '');
    const momentWholeDate = moment(insertedDateWithNoSpaces, dateFormat, true);
    const formattedDate = momentWholeDate.format(beDateFormat);
    const isEmpty = insertedDate.length === 0;

    return {
        date: momentWholeDate,
        formattedDate,
        isEmpty,
        isValid: momentWholeDate.isValid(),
    };
};
