// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TextOrientation, TextOrientationSchema } from 'api/gen/TextOrientation';

const requiredPart = t.interface({
  alwaysVisible: t.boolean,
  description: t.string,
  field: t.string,
  fulltextFilterEnabled: t.boolean,
  header: t.string,
  locked: t.boolean,
  textOrientation: TextOrientationSchema,
  visible: t.boolean,
  width: PositiveIntegerSchema,
});

export const ColumnSettingsSchema = excess(requiredPart);

export interface ColumnSettings extends t.TypeOf<typeof ColumnSettingsSchema> {}
