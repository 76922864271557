// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(3, 1).
// Example: 0.3

export const decimal31Minimum = -100.0; // exclusive
export const decimal31Maximum = 100.0; // exclusive
export const decimal31MinimumInclusive = -99.9;
export const decimal31MaximumInclusive = 99.9;
export const decimal31MinMaxInclusive: [number | null, number | null] = [-99.9, 99.9];
export const decimal31DecimalPlaces = 1;
export const decimal31RegexGen = new RegExp("^-?\\d{1,2}[,.]\\d{1}$");

export const Decimal31Schema = t.refinement(t.number, x => x > decimal31Minimum && x < decimal31Maximum && Number.isInteger(parseFloat((x / 0.1).toFixed(10))), 'Decimal31');

export type Decimal31 = t.TypeOf<typeof Decimal31Schema>;
