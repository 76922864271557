// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDetailsBase, CriteriaDetailsBaseSchema } from 'api/gen/CriteriaDetailsBase';

const requiredPart = t.interface({
  tag: t.literal('BOOL'),
  value: t.union([t.boolean, t.null]),
});

export const BooleanCriteriaDetailsSchema = excess(t.intersection([CriteriaDetailsBaseSchema.type, requiredPart]));

export interface BooleanCriteriaDetails extends t.TypeOf<typeof BooleanCriteriaDetailsSchema> {}
