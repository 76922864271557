// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateCreateBreederRole, ValidateCreateBreederRoleSchema } from 'api/gen/ValidateCreateBreederRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateUpdateBreederRole, ValidateUpdateBreederRoleSchema } from 'api/gen/ValidateUpdateBreederRole';

export const ValidateBreederRoleSchema = t.union([ValidateCreateBreederRoleSchema, ValidateUpdateBreederRoleSchema]);

export type ValidateBreederRole = t.TypeOf<typeof ValidateBreederRoleSchema>;
