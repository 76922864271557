import {none, Opt, optEmptyString} from 'ts-opt';

import {isLocalStorageAvailable} from 'utils/is-local-storage-available';

import {CookieApproval, CookieApprovalSchema} from '../types/cookie-approval';

export const localStorageItem = 'cookieApproval';
const cookieApprovalRegex = /^{"necessary":(true|false),"preferential":(true|false),"statistical":(true|false)}$/;

class CookieApprovalStore {
    private static readonly allAccepted: CookieApproval = Object.freeze({
        necessary: true,
        preferential: true,
        statistical: true,
    });

    private static readonly necesaryAccepted: CookieApproval = Object.freeze({
        necessary: true,
        preferential: false,
        statistical: false,
    });

    public get(): Opt<CookieApproval> {
        if (!isLocalStorageAvailable()) return none;
        const cookieValue = localStorage.getItem(localStorageItem);

        return optEmptyString(cookieValue)
            .map(x => cookieApprovalRegex.test(x) && JSON.parse(x))
            .narrow(CookieApprovalSchema.is);
    }

    public isPreferenceAccepted(): boolean {
        return this.get().prop('preferential').orFalse();
    }

    public isStatisticalAccepted(): boolean {
        return this.get().prop('statistical').orFalse();
    }

    public acceptAll(): void {
        localStorage.setItem(localStorageItem, JSON.stringify(CookieApprovalStore.allAccepted));
    }

    public acceptNecessary(): void {
        localStorage.setItem(localStorageItem, JSON.stringify(CookieApprovalStore.necesaryAccepted));
    }

    public acceptSelected(preferential: boolean, statistical: boolean): void {
        localStorage.setItem(localStorageItem, JSON.stringify({
            necessary: true,
            preferential,
            statistical,
        }));
    }
}

export const cookieApprovalStore = new CookieApprovalStore();
