// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkedTotalRange, MilkedTotalRangeSchema } from 'api/gen/MilkedTotalRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingTimeRange, MilkingTimeRangeSchema } from 'api/gen/MilkingTimeRange';

const requiredPart = t.interface({
  earTag: EarTagSchema,
  examinationDate: DateSchema,
  milkedTotal: MilkedTotalRangeSchema,
  milkingTime: MilkingTimeRangeSchema,
});

export const CalculateApmvSchema = excess(requiredPart);

export interface CalculateApmv extends t.TypeOf<typeof CalculateApmvSchema> {}
