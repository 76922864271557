import { toPairs } from 'lodash/fp';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { opt } from 'ts-opt';
import { Language } from 'api/gen/Language';
import { Config } from 'app/config';
import { genMenuItems } from 'app/navigation';
import { appRoutePaths } from 'app/routes/route-paths';
import { LANGUAGES, useOurTranslation } from 'app/translations';
import { i18n } from 'app/translations/i18n';
import { simpleUserSelector, userAction } from 'app/user/model';
import { LanguageOption } from 'common/layout/components/main-menu/language-select';
import { MainMenu, MenuType } from 'common/layout/index';
interface Props {
  menuType: MenuType;
  isSideMenuCollapsed: boolean;
  setIsSideMenuCollapsed(isCollapsed: boolean): void;
}
const defaultLanguageOption: LanguageOption = {
  icon: LANGUAGES.CS.icon,
  label: LANGUAGES.CS.label,
  language: LANGUAGES.CS.label
};
const languageOptions: Array<LanguageOption> = toPairs(LANGUAGES).map(([key, value]) => ({
  language: key,
  ...value
}));
export const MainMenuMemoizedWrapper: FC<Props> = props => {
  const {
    menuType,
    isSideMenuCollapsed,
    setIsSideMenuCollapsed
  } = props;
  const {
    t
  } = useOurTranslation('common/menu');
  const currentUser = useSelector(simpleUserSelector.currentUser);
  const isUserLoading = useSelector(simpleUserSelector.isUserLoading);
  const currentUserPermissions = useSelector(simpleUserSelector.currentUserPermissions);
  const locationPath = useLocation().pathname;
  const dispatch = useDispatch();
  const selectedLanguage: LanguageOption = useMemo(() => opt(languageOptions.find(l => l.language === i18n.language)).orElse(defaultLanguageOption), [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSideMenuCollapse = useCallback(() => {
    setIsSideMenuCollapsed(true);
  }, [isSideMenuCollapsed]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeAndSaveLanguage = useCallback((language: Language) => {
    dispatch(userAction.changeAndSaveLanguage(language));
  }, [dispatch]);
  return useMemo(() => <MainMenu menuItems={genMenuItems(t, currentUser.orUndef(), currentUserPermissions)} locationPath={locationPath} homePath={appRoutePaths.home} isUserLoading={isUserLoading} menuType={menuType} languageOptions={languageOptions} selectedLanguage={selectedLanguage} onMenuItemClick={menuType === 'side' ? handleSideMenuCollapse : undefined} onLanguageChange={changeAndSaveLanguage} environment={Config.environment} isSideMenuCollapsed={isSideMenuCollapsed} />, [changeAndSaveLanguage, currentUser, currentUserPermissions, handleSideMenuCollapse, isSideMenuCollapsed, isUserLoading, locationPath, menuType, selectedLanguage, t]);
};