// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmName, FarmNameSchema } from 'api/gen/FarmName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  code: FarmCodeSchema,
  id: PositiveIntegerSchema,
  name: t.union([FarmNameSchema, t.null]),
});

export const FarmSchema = excess(requiredPart);

export interface Farm extends t.TypeOf<typeof FarmSchema> {}
