// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AuthDiscriminator, AuthDiscriminatorSchema } from 'api/gen/AuthDiscriminator';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionActionNameV2, PermissionActionNameV2Schema } from 'api/gen/PermissionActionNameV2';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionCodeV2, PermissionCodeV2Schema } from 'api/gen/PermissionCodeV2';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionCommentV2, PermissionCommentV2Schema } from 'api/gen/PermissionCommentV2';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionControllerNameV2, PermissionControllerNameV2Schema } from 'api/gen/PermissionControllerNameV2';

const requiredPart = t.interface({
  actionName: PermissionActionNameV2Schema,
  code: PermissionCodeV2Schema,
  comment: t.union([PermissionCommentV2Schema, t.null]),
  controllerName: PermissionControllerNameV2Schema,
  discriminator: AuthDiscriminatorSchema,
  userEnable: t.boolean,
});

export const CreatePermissionSchema = excess(requiredPart);

export interface CreatePermission extends t.TypeOf<typeof CreatePermissionSchema> {}
