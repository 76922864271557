// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The 13 characters long code of stable. The code consists from 2-letter country code, blank space and 10-digits code.
// Example: "CZ 0000123456"

export const stableCodeRegexGen = (): RegExp => new RegExp("^([A-Z]{2}( )([0-9]{10}))$");

export const StableCodeSchema = StringPatternSchema<StableCode>(stableCodeRegexGen());

export type StableCode = string;
