// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EditApcDiscard, EditApcDiscardSchema } from 'api/gen/EditApcDiscard';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EditApcEarTagUpdate, EditApcEarTagUpdateSchema } from 'api/gen/EditApcEarTagUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EditApcUpdate, EditApcUpdateSchema } from 'api/gen/EditApcUpdate';

export const EditApcSchema = t.union([EditApcDiscardSchema, EditApcEarTagUpdateSchema, EditApcUpdateSchema]);

export type EditApc = t.TypeOf<typeof EditApcSchema>;
