// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ContactValue, ContactValueSchema } from 'api/gen/ContactValue';

const requiredPart = t.interface({
  value: ContactValueSchema,
});

export const UpdateUserContactSchema = excess(requiredPart);

export interface UpdateUserContact extends t.TypeOf<typeof UpdateUserContactSchema> {}
