// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: ASC

export const DirectionSchema = t.keyof({
  ASC: null,
  DESC: null,
});

export type Direction = t.TypeOf<typeof DirectionSchema>;
