// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The bull line-registry schema.
// Example: "ZPI-490"

export const lineRegistryRegexGen = (): RegExp => new RegExp("^(([A-Z]{1,3})|([0-9]{3}))-([0-9]{3})$");

export const LineRegistrySchema = StringPatternSchema<LineRegistry>(lineRegistryRegexGen());

export type LineRegistry = string;
