// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApcDateListWithStables, ApcDateListWithStablesSchema } from 'api/gen/ApcDateListWithStables';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApcStableListWithDates, ApcStableListWithDatesSchema } from 'api/gen/ApcStableListWithDates';

export const ApcStableDateSchema = t.union([ApcDateListWithStablesSchema, ApcStableListWithDatesSchema]);

export type ApcStableDate = t.TypeOf<typeof ApcStableDateSchema>;
