// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ScoringResult, ScoringResultSchema } from 'api/gen/ScoringResult';

const requiredPart = t.interface({
  results: t.array(ScoringResultSchema),
  sumOfRelatedPairs: PositiveOrZeroIntegerSchema,
});

export const MatingResultSchema = excess(requiredPart);

export interface MatingResult extends t.TypeOf<typeof MatingResultSchema> {}
