// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaValuesBase, CriteriaValuesBaseSchema } from 'api/gen/CriteriaValuesBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal31, Decimal31Schema } from 'api/gen/Decimal31';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_3_1'),
  leftValue: t.union([Decimal31Schema, t.null]),
  rightValue: t.union([Decimal31Schema, t.null]),
});

export const Decimal31CriteriaValuesSchema = excess(t.intersection([CriteriaValuesBaseSchema.type, requiredPart]));

export interface Decimal31CriteriaValues extends t.TypeOf<typeof Decimal31CriteriaValuesSchema> {}
