// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-buttons-components-upload-button-___styles__hiddenInput___h_Zqr{display:none!important}.src-common-buttons-components-upload-button-___styles__label___Ui65V{display:block;margin-bottom:0}
`, "",{"version":3,"sources":["webpack://./src/common/buttons/components/upload-button/styles.sass"],"names":[],"mappings":"AAAA,4EAAa,sBAAA,CAAwB,sEAAO,aAAA,CAAc,eAAA","sourcesContent":[".hiddenInput{display:none !important}.label{display:block;margin-bottom:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hiddenInput": `src-common-buttons-components-upload-button-___styles__hiddenInput___h_Zqr`,
	"label": `src-common-buttons-components-upload-button-___styles__label___Ui65V`
};
export default ___CSS_LOADER_EXPORT___;
