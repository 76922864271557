// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  code: StableCodeSchema,
  id: t.Integer,
  name: t.union([StableNameSchema, t.null]),
  subjectBusinessName: t.union([SubjectBusinessNameSchema, t.null]),
});

export const CabStableAutocompleteSchema = excess(requiredPart);

export interface CabStableAutocomplete extends t.TypeOf<typeof CabStableAutocompleteSchema> {}
