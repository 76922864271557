// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Mating score represented by floating point values with format decimal(5, 2).
// Example: 5.0e-2

export const matingScoreMinimum = -1000.0; // exclusive
export const matingScoreMaximum = 1000.0; // exclusive
export const matingScoreMinimumInclusive = -999.99;
export const matingScoreMaximumInclusive = 999.99;
export const matingScoreMinMaxInclusive: [number | null, number | null] = [-999.99, 999.99];
export const matingScoreDecimalPlaces = 2;
export const matingScoreRegexGen = new RegExp("^-?\\d{1,3}[,.]\\d{2}$");

export const MatingScoreSchema = t.refinement(t.number, x => x > matingScoreMinimum && x < matingScoreMaximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'MatingScore');

export type MatingScore = t.TypeOf<typeof MatingScoreSchema>;
