// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Milked total value boundaries in liters.
// Example: 20.0

export const milkedTotalRangeMinimum = 3.0; // inclusive
export const milkedTotalRangeMaximum = 40.0; // inclusive
export const milkedTotalRangeMinimumInclusive = 3.0;
export const milkedTotalRangeMaximumInclusive = 40.0;
export const milkedTotalRangeMinMaxInclusive: [number | null, number | null] = [3.0, 40.0];
export const milkedTotalRangeDecimalPlaces = 2;
export const milkedTotalRangeRegexGen = new RegExp("^\\d{1,2}[,.]\\d{2}$");

export const MilkedTotalRangeSchema = t.refinement(t.number, x => x >= milkedTotalRangeMinimum && x <= milkedTotalRangeMaximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'MilkedTotalRange');

export type MilkedTotalRange = t.TypeOf<typeof MilkedTotalRangeSchema>;
