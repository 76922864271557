// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridCode, GridCodeSchema } from 'api/gen/GridCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridSettingsOption, GridSettingsOptionSchema } from 'api/gen/GridSettingsOption';

const requiredPart = t.interface({
  code: GridCodeSchema,
  gridSettings: t.union([t.array(GridSettingsOptionSchema), t.null]),
  gridUserSettings: t.union([t.array(GridSettingsOptionSchema), t.null]),
});

export const ModuleGridSettingsSchema = excess(requiredPart);

export interface ModuleGridSettings extends t.TypeOf<typeof ModuleGridSettingsSchema> {}
