// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of the criteria.
// Example: "Jalovice (ANO) / Kráva (NE)"

export const criteriaNameMinLength = 1;
export const criteriaNameMaxLength = 75;

export const CriteriaNameSchema = t.refinement(t.string, n => n.length >= criteriaNameMinLength && n.length <= criteriaNameMaxLength, 'CriteriaName');

export type CriteriaName = string;
