// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Unique code of permission.
// Example: "CreatePermission"

export const permissionCodeV2MinLength = 1;
export const permissionCodeV2MaxLength = 20;

export const PermissionCodeV2Schema = t.refinement(t.string, n => n.length >= permissionCodeV2MinLength && n.length <= permissionCodeV2MaxLength, 'PermissionCodeV2');

export type PermissionCodeV2 = string;
