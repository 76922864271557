// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalCodeName, AnimalCodeNameSchema } from 'api/gen/AnimalCodeName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederRegistrationNumber, BreederRegistrationNumberSchema } from 'api/gen/BreederRegistrationNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal41, Decimal41Schema } from 'api/gen/Decimal41';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal42, Decimal42Schema } from 'api/gen/Decimal42';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52, Decimal52Schema } from 'api/gen/Decimal52';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal92, Decimal92Schema } from 'api/gen/Decimal92';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Exterior, ExteriorSchema } from 'api/gen/Exterior';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmName, FarmNameSchema } from 'api/gen/FarmName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GenotypeExt, GenotypeExtSchema } from 'api/gen/GenotypeExt';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Huid, HuidSchema } from 'api/gen/Huid';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StudbookSection, StudbookSectionSchema } from 'api/gen/StudbookSection';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  alive: t.union([t.boolean, t.null]),
  animalId: PositiveIntegerSchema,
  avgLactationCount: t.union([t.Integer, t.null]),
  avgLactationDays: t.union([t.Integer, t.null]),
  avgLactationFatKg: t.union([t.Integer, t.null]),
  avgLactationFatPercentage: t.union([Decimal52Schema, t.null]),
  avgLactationMilkKg: t.union([t.Integer, t.null]),
  avgLactationProteinKg: t.union([t.Integer, t.null]),
  avgLactationProteinPercentage: t.union([Decimal52Schema, t.null]),
  birthDate: t.union([DateSchema, t.null]),
  bodyStructure: t.union([t.Integer, t.null]),
  breedAlpha6: t.union([BreedAlpha6Schema, t.null]),
  breederRegistrationNumber: BreederRegistrationNumberSchema,
  calveDate: t.union([DateSchema, t.null]),
  changeCode: t.union([t.Integer, t.null]),
  currentLactationDays: t.union([t.Integer, t.null]),
  currentLactationFatKg: t.union([t.Integer, t.null]),
  currentLactationFatPercentage: t.union([Decimal42Schema, t.null]),
  currentLactationLactoseKg: t.union([t.Integer, t.null]),
  currentLactationLactosePercentage: t.union([Decimal42Schema, t.null]),
  currentLactationMilkKg: t.union([t.Integer, t.null]),
  currentLactationMilkKgSum: t.union([t.Integer, t.null]),
  currentLactationOrder: t.union([t.Integer, t.null]),
  currentLactationProteinKg: t.union([t.Integer, t.null]),
  currentLactationProteinPercentage: t.union([Decimal42Schema, t.null]),
  currentLactationSomaticCells: t.union([t.Integer, t.null]),
  currentLactationUrea: t.union([Decimal52Schema, t.null]),
  daysFromBirth: t.union([t.Integer, t.null]),
  districtCode: t.union([t.Integer, t.null]),
  earTag: t.union([EarTagSchema, t.null]),
  exterior: t.union([ExteriorSchema, t.null]),
  extremities: t.union([t.Integer, t.null]),
  farmCode: FarmCodeSchema,
  farmName: t.union([FarmNameSchema, t.null]),
  fatherLineRegistry: t.union([LineRegistrySchema, t.null]),
  fatherName: t.union([AnimalCodeNameSchema, t.null]),
  frame: t.union([Decimal41Schema, t.null]),
  genotype: t.union([GenotypeExtSchema, t.null]),
  huid: t.union([HuidSchema, t.null]),
  inseminationOrder: t.union([t.Integer, t.null]),
  lastLactationDate: t.union([DateSchema, t.null]),
  lastLactationDays: t.union([t.Integer, t.null]),
  lastLactationFatKg: t.union([t.Integer, t.null]),
  lastLactationFatPercentage: t.union([Decimal42Schema, t.null]),
  lastLactationFatProteinKg: t.union([t.Integer, t.null]),
  lastLactationMilkKg: t.union([t.Integer, t.null]),
  lastLactationOrder: t.union([t.Integer, t.null]),
  lastLactationProteinKg: t.union([t.Integer, t.null]),
  lastLactationProteinPercentage: t.union([Decimal42Schema, t.null]),
  lastMatingDate: t.union([DateSchema, t.null]),
  lastMilkYield: t.union([Decimal92Schema, t.null]),
  lifelongLactationDaysSum: t.union([t.Integer, t.null]),
  lifelongLactationFatKg: t.union([t.Integer, t.null]),
  lifelongLactationFatPercentage: t.union([Decimal52Schema, t.null]),
  lifelongLactationMilkKg: t.union([t.Integer, t.null]),
  lifelongLactationMilkKgSum: t.union([t.Integer, t.null]),
  lifelongLactationProteinKg: t.union([t.Integer, t.null]),
  lifelongLactationProteinPercentage: t.union([Decimal52Schema, t.null]),
  lifelongStandardizedLactationDays: t.union([t.Integer, t.null]),
  maxLactationDays: t.union([t.Integer, t.null]),
  maxLactationFatKg: t.union([t.Integer, t.null]),
  maxLactationFatPercentage: t.union([Decimal52Schema, t.null]),
  maxLactationFatProteinKg: t.union([t.Integer, t.null]),
  maxLactationMilkKg: t.union([t.Integer, t.null]),
  maxLactationOrder: t.union([t.Integer, t.null]),
  maxLactationProteinKg: t.union([t.Integer, t.null]),
  maxLactationProteinPercentage: t.union([Decimal52Schema, t.null]),
  milkKgAvgPerDay: t.union([Decimal92Schema, t.null]),
  milkKgAvgPerLactationDay: t.union([Decimal92Schema, t.null]),
  milkStrength: t.union([Decimal41Schema, t.null]),
  motherEarTag: t.union([EarTagSchema, t.null]),
  mothersFatherLineRegistry: t.union([LineRegistrySchema, t.null]),
  mothersFatherName: t.union([AnimalCodeNameSchema, t.null]),
  musculature: t.union([t.Integer, t.null]),
  name: t.union([AnimalCodeNameSchema, t.null]),
  organizationCenterInsCode: t.union([OrganizationCodeSchema, t.null]),
  organizationCenterPtCode: t.union([OrganizationCodeSchema, t.null]),
  organizationInsCode: t.union([OrganizationCodeSchema, t.null]),
  organizationPtCode: t.union([OrganizationCodeSchema, t.null]),
  pregnant: t.union([t.boolean, t.null]),
  proficiencyTest: t.union([t.boolean, t.null]),
  proficiencyTestDate: t.union([DateSchema, t.null]),
  servicePeriod: t.union([t.Integer, t.null]),
  sihIndex: t.union([Decimal41Schema, t.null]),
  stableCode: StableCodeSchema,
  stableName: t.union([StableNameSchema, t.null]),
  studbookSection: t.union([StudbookSectionSchema, t.null]),
  subjectBusinessName: t.union([SubjectBusinessNameSchema, t.null]),
  totalBreedingValue: t.union([Decimal41Schema, t.null]),
  udder: t.union([t.Integer, t.null]),
});

export const CabBreedingFemaleSchema = excess(requiredPart);

export interface CabBreedingFemale extends t.TypeOf<typeof CabBreedingFemaleSchema> {}
