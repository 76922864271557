// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederRegistrationNumber, BreederRegistrationNumberSchema } from 'api/gen/BreederRegistrationNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmName, FarmNameSchema } from 'api/gen/FarmName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProductionAreaName, ProductionAreaNameSchema } from 'api/gen/ProductionAreaName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableInStudbookRegistry, StableInStudbookRegistrySchema } from 'api/gen/StableInStudbookRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breederName: t.union([BreederNameSchema, t.null]),
  breederRegistrationNumber: BreederRegistrationNumberSchema,
  farmAnimalCount: t.union([PositiveOrZeroIntegerSchema, t.null]),
  farmCode: FarmCodeSchema,
  farmId: PositiveIntegerSchema,
  farmName: t.union([FarmNameSchema, t.null]),
  id: PositiveIntegerSchema,
  organizationCode: t.union([OrganizationCodeSchema, t.null]),
  productionArea: t.union([ProductionAreaNameSchema, t.null]),
  proficiencyTest: t.union([t.boolean, t.null]),
  stableAnimalCount: t.union([PositiveOrZeroIntegerSchema, t.null]),
  stableCode: StableCodeSchema,
  stableInStudbookRegistry: t.union([StableInStudbookRegistrySchema, t.null]),
  stableName: t.union([StableNameSchema, t.null]),
});

export const StableToChooseSchema = excess(requiredPart);

export interface StableToChoose extends t.TypeOf<typeof StableToChooseSchema> {}
