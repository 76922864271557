// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Name of the association.
// Example: "Český svaz chovatelů masného skotu, z.s."

export const associationNameMinLength = 1;
export const associationNameMaxLength = 100;

export const AssociationNameSchema = t.refinement(t.string, n => n.length >= associationNameMinLength && n.length <= associationNameMaxLength, 'AssociationName');

export type AssociationName = string;
