// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of the grid settings.
// Example: "Výchozí nastavení"

export const gridSettingsNameMinLength = 1;
export const gridSettingsNameMaxLength = 50;

export const GridSettingsNameSchema = t.refinement(t.string, n => n.length >= gridSettingsNameMinLength && n.length <= gridSettingsNameMaxLength, 'GridSettingsName');

export type GridSettingsName = string;
