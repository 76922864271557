// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateMilkabilityReportBase, ValidateMilkabilityReportBaseSchema } from 'api/gen/ValidateMilkabilityReportBase';

const requiredPart = t.interface({
  tag: t.literal('ValidateMilkabilityReportCreate'),
});

export const ValidateMilkabilityReportCreateSchema = excess(t.intersection([ValidateMilkabilityReportBaseSchema.type, requiredPart]));

export interface ValidateMilkabilityReportCreate extends t.TypeOf<typeof ValidateMilkabilityReportCreateSchema> {}
