// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateCreateOtherSubjectRole, ValidateCreateOtherSubjectRoleSchema } from 'api/gen/ValidateCreateOtherSubjectRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateUpdateOtherSubjectRole, ValidateUpdateOtherSubjectRoleSchema } from 'api/gen/ValidateUpdateOtherSubjectRole';

export const ValidateOtherSubjectRoleSchema = t.union([ValidateCreateOtherSubjectRoleSchema, ValidateUpdateOtherSubjectRoleSchema]);

export type ValidateOtherSubjectRole = t.TypeOf<typeof ValidateOtherSubjectRoleSchema>;
