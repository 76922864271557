// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenterCode, OrganizationCenterCodeSchema } from 'api/gen/OrganizationCenterCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';

const requiredPart = t.interface({
  organizationCenterCode: OrganizationCenterCodeSchema,
  organizationCenterId: PositiveIntegerSchema,
  rowId: RowIdSchema,
});

export const OrganizationCenterRowSchema = excess(requiredPart);

export interface OrganizationCenterRow extends t.TypeOf<typeof OrganizationCenterRowSchema> {}
