import { usePrevious } from '@fl/cmsch-fe-library';
import React, { FC, memo, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { opt } from 'ts-opt';
import { Config } from 'app/config';
import { userAction } from 'app/user';
import { MenuLayout } from '../main-menu/menu-layout';
const MenuAppWrapperBase: FC<PropsWithChildren> = props => {
  const {
    children
  } = props;
  const dispatch = useDispatch();
  const isE2E = Config.e2e;
  const [isSiderActive, setIsSiderActive] = useState<boolean>(false);
  const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState<boolean>(true);
  const locationPath = useLocation().pathname;
  const prevPathname = usePrevious(locationPath);
  useEffect(() => {
    if (prevPathname !== locationPath) {
      dispatch(userAction.setPreviousAndCurrentPathname(opt(prevPathname), opt(locationPath)));
    }
  }, [dispatch, locationPath, prevPathname]);
  return <MenuLayout isE2E={isE2E} isSideMenuCollapsed={isSideMenuCollapsed} isSiderActive={isSiderActive} setIsSiderActive={setIsSiderActive} setIsSideMenuCollapsed={setIsSideMenuCollapsed} data-sentry-element="MenuLayout" data-sentry-component="MenuAppWrapperBase" data-sentry-source-file="index.tsx">
            {children}
        </MenuLayout>;
};
export const MenuAppWrapper = memo(MenuAppWrapperBase);