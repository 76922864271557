// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Unique code of role.
// Example: "Reprodukce - Admin"

export const roleCodeMinLength = 1;
export const roleCodeMaxLength = 20;

export const RoleCodeSchema = t.refinement(t.string, n => n.length >= roleCodeMinLength && n.length <= roleCodeMaxLength, 'RoleCode');

export type RoleCode = string;
