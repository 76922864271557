// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Action name of a permission.
// Example: "TiskoveDavky"

export const permissionActionNameV2MinLength = 1;
export const permissionActionNameV2MaxLength = 128;

export const PermissionActionNameV2Schema = t.refinement(t.string, n => n.length >= permissionActionNameV2MinLength && n.length <= permissionActionNameV2MaxLength, 'PermissionActionNameV2');

export type PermissionActionNameV2 = string;
