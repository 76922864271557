// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EditApcBase, EditApcBaseSchema } from 'api/gen/EditApcBase';

const requiredPart = t.interface({
  correctionType: t.literal('DISCARD'),
});

export const EditApcDiscardSchema = excess(t.intersection([EditApcBaseSchema.type, requiredPart]));

export interface EditApcDiscard extends t.TypeOf<typeof EditApcDiscardSchema> {}
