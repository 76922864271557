// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaFilterBase, CriteriaFilterBaseSchema } from 'api/gen/CriteriaFilterBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal31, Decimal31Schema } from 'api/gen/Decimal31';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_3_1'),
  leftValue: t.union([Decimal31Schema, t.null]),
  rightValue: t.union([Decimal31Schema, t.null]),
});

export const CriteriaDecimal31FilterSchema = excess(t.intersection([CriteriaFilterBaseSchema.type, requiredPart]));

export interface CriteriaDecimal31Filter extends t.TypeOf<typeof CriteriaDecimal31FilterSchema> {}
