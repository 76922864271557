// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CorrectionType, CorrectionTypeSchema } from 'api/gen/CorrectionType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TechnicianNumber, TechnicianNumberSchema } from 'api/gen/TechnicianNumber';

const requiredPart = t.interface({
  correctionType: CorrectionTypeSchema,
  earTag: EarTagSchema,
  proficiencyTestDate: DateSchema,
  technicianNumber: TechnicianNumberSchema,
});

export const CreateApcBaseSchema = excess(requiredPart);

export interface CreateApcBase extends t.TypeOf<typeof CreateApcBaseSchema> {}
