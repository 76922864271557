// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-layout-components-our-collapse-___styles__collapse___vWzv0{border-bottom:1px #d9d9d9 solid}.src-common-layout-components-our-collapse-___styles__header___u6vW8{padding:12px 0 12px 14px;display:flex;justify-content:space-between}.src-common-layout-components-our-collapse-___styles__headerTitle___RIMQk{cursor:pointer;display:flex;align-items:center}.src-common-layout-components-our-collapse-___styles__arrow___Wtejs,.src-common-layout-components-our-collapse-___styles__openArrow___Dc9hm,.src-common-layout-components-our-collapse-___styles__closedArrow___uDgM0{display:grid;height:12px;align-content:center;transition:.2s;align-items:center}.src-common-layout-components-our-collapse-___styles__openArrow___Dc9hm{transform:rotate(90deg)}.src-common-layout-components-our-collapse-___styles__content___CTZwd,.src-common-layout-components-our-collapse-___styles__uncollapsedContent___RB335,.src-common-layout-components-our-collapse-___styles__collapsedContent___A3fwq{overflow-y:clip}.src-common-layout-components-our-collapse-___styles__collapsedContent___A3fwq{max-height:0;opacity:.3;transition:max-height .07s}.src-common-layout-components-our-collapse-___styles__uncollapsedContent___RB335{max-height:100%;opacity:1;transition:max-height .2s;margin-bottom:16px;margin-top:4px}@media (max-width: 767px){.src-common-layout-components-our-collapse-___styles__header___u6vW8{padding:12px 0}}.src-common-layout-components-our-collapse-___styles__actions___XI58j{display:flex;flex-wrap:wrap!important;gap:10px}@media (max-width: 575px){.src-common-layout-components-our-collapse-___styles__actions___XI58j{margin-top:10px;width:100%}.src-common-layout-components-our-collapse-___styles__actions___XI58j button{width:100%}}@media (min-width: 1200px){.src-common-layout-components-our-collapse-___styles__actions___XI58j{margin-right:17px}}
`, "",{"version":3,"sources":["webpack://./src/common/layout/components/our-collapse/styles.sass"],"names":[],"mappings":"AAAA,uEAAU,+BAAA,CAAgC,qEAA1C,wBAAA,CAA2E,YAAA,CAAa,6BAAA,CAA8B,0EAAa,cAAA,CAAe,YAAA,CAAa,kBAAA,CAAmB,sNAA+B,YAAA,CAAa,WAAA,CAAY,oBAAA,CAAqB,cAAA,CAAe,kBAAA,CAAmB,wEAAW,uBAAA,CAAwB,sOAA+C,eAAA,CAAgB,+EAAkB,YAAA,CAAa,UAAA,CAAW,0BAAA,CAA2B,iFAAoB,eAAA,CAAgB,SAAA,CAAU,yBAAA,CAA0B,kBAAA,CAAmB,cAAA,CAAe,0BAAyB,qEAA3kB,cAAA,CAAA,CAA0mB,sEAAS,YAAA,CAAa,wBAAA,CAA0B,QAAA,CAAS,0BAAyB,sEAAS,eAAA,CAAgB,UAAA,CAAW,6EAAwB,UAAA,CAAA,CAAY,2BAA0B,sEAAS,iBAAA,CAAA","sourcesContent":[".collapse{border-bottom:1px #d9d9d9 solid}.header{padding:12px 0 12px 14px;display:flex;justify-content:space-between}.headerTitle{cursor:pointer;display:flex;align-items:center}.arrow,.openArrow,.closedArrow{display:grid;height:12px;align-content:center;transition:.2s;align-items:center}.openArrow{transform:rotate(90deg)}.content,.uncollapsedContent,.collapsedContent{overflow-y:clip}.collapsedContent{max-height:0;opacity:.3;transition:max-height .07s}.uncollapsedContent{max-height:100%;opacity:1;transition:max-height .2s;margin-bottom:16px;margin-top:4px}@media(max-width: 767px){.header{padding:12px 0 12px 0}}.actions{display:flex;flex-wrap:wrap !important;gap:10px}@media(max-width: 575px){.actions{margin-top:10px;width:100%}.actions :global button{width:100%}}@media(min-width: 1200px){.actions{margin-right:17px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": `src-common-layout-components-our-collapse-___styles__collapse___vWzv0`,
	"header": `src-common-layout-components-our-collapse-___styles__header___u6vW8`,
	"headerTitle": `src-common-layout-components-our-collapse-___styles__headerTitle___RIMQk`,
	"arrow": `src-common-layout-components-our-collapse-___styles__arrow___Wtejs`,
	"openArrow": `src-common-layout-components-our-collapse-___styles__openArrow___Dc9hm`,
	"closedArrow": `src-common-layout-components-our-collapse-___styles__closedArrow___uDgM0`,
	"content": `src-common-layout-components-our-collapse-___styles__content___CTZwd`,
	"uncollapsedContent": `src-common-layout-components-our-collapse-___styles__uncollapsedContent___RB335`,
	"collapsedContent": `src-common-layout-components-our-collapse-___styles__collapsedContent___A3fwq`,
	"actions": `src-common-layout-components-our-collapse-___styles__actions___XI58j`
};
export default ___CSS_LOADER_EXPORT___;
