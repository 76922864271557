// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Animal trait represented by integer values.
// Example: 0

export const animalTraitBvIntegerMinimum = -999.0; // inclusive
export const animalTraitBvIntegerMaximum = 999.0; // inclusive

export const AnimalTraitBvIntegerSchema = t.refinement(t.Integer, x => x >= animalTraitBvIntegerMinimum && x <= animalTraitBvIntegerMaximum, 'AnimalTraitBvInteger');

export type AnimalTraitBvInteger = t.TypeOf<typeof AnimalTraitBvIntegerSchema>;
