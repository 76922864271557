import { classNames } from '@fl/cmsch-fe-library';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { memo } from 'react';
import { Ant } from 'common/ant';
import styles from './styles.sass';
interface Props {
  children: React.ReactNode;
  fullWidth?: boolean;
  direction?: 'horizontal' | 'vertical';
  size?: SizeType;
  align?: 'start' | 'end' | 'center' | 'baseline';
  disableLastToRight?: boolean;
}
const SpaceBase: React.FC<Props> = props => {
  const {
    children,
    fullWidth,
    direction,
    size,
    align,
    disableLastToRight
  } = props;
  const finalClassName = classNames(fullWidth && 'w-100', !disableLastToRight && styles.lastToRight);
  return <Ant.Space className={finalClassName} direction={direction} size={size} align={align} data-sentry-element="unknown" data-sentry-component="SpaceBase" data-sentry-source-file="index.tsx">
            {children}
        </Ant.Space>;
};
export const Space = memo(SpaceBase);