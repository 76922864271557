// Generated from 2a748ad5 (milestone/mating-filters)
import * as t from 'io-ts';
import { Response, EmptyString, EmptyStringSchema, ArrayBufferSchema, FormDataSchema, excess, validateSchema } from '@fl/cmsch-fe-library';

import { apiRequester, legacyApiRequester, staticRequester } from 'app/api';


// @ts-ignore: Workaround for "is declared but its value is never read."
import { Accordion, AccordionSchema } from 'api/gen/Accordion';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnalysisProtocol, AnalysisProtocolSchema } from 'api/gen/AnalysisProtocol';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalInSession, AnimalInSessionSchema } from 'api/gen/AnimalInSession';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalList, AnimalListSchema } from 'api/gen/AnimalList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalListValidation, AnimalListValidationSchema } from 'api/gen/AnimalListValidation';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalSearchLabel, AnimalSearchLabelSchema } from 'api/gen/AnimalSearchLabel';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalType, AnimalTypeSchema } from 'api/gen/AnimalType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalsBrowserFarm, AnimalsBrowserFarmSchema } from 'api/gen/AnimalsBrowserFarm';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalsBrowserStable, AnimalsBrowserStableSchema } from 'api/gen/AnimalsBrowserStable';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApcStableDate, ApcStableDateSchema } from 'api/gen/ApcStableDate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApmvResponse, ApmvResponseSchema } from 'api/gen/ApmvResponse';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AppInfo, AppInfoSchema } from 'api/gen/AppInfo';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AppModule, AppModuleSchema } from 'api/gen/AppModule';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Association, AssociationSchema } from 'api/gen/Association';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AssociationType, AssociationTypeSchema } from 'api/gen/AssociationType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AutocompleteFilterText, AutocompleteFilterTextSchema } from 'api/gen/AutocompleteFilterText';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AvailableAnimal, AvailableAnimalSchema } from 'api/gen/AvailableAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BasicRole, BasicRoleSchema } from 'api/gen/BasicRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Bookmark, BookmarkSchema } from 'api/gen/Bookmark';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breeder, BreederSchema } from 'api/gen/Breeder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederToChoose, BreederToChooseSchema } from 'api/gen/BreederToChoose';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingFemaleFilter, BreedingFemaleFilterSchema } from 'api/gen/BreedingFemaleFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CabBreederAutocomplete, CabBreederAutocompleteSchema } from 'api/gen/CabBreederAutocomplete';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CabDistrictAutocomplete, CabDistrictAutocompleteSchema } from 'api/gen/CabDistrictAutocomplete';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CabFarmAutocomplete, CabFarmAutocompleteSchema } from 'api/gen/CabFarmAutocomplete';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CabStableAutocomplete, CabStableAutocompleteSchema } from 'api/gen/CabStableAutocomplete';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CalculateApmv, CalculateApmvSchema } from 'api/gen/CalculateApmv';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CalfFilter, CalfFilterSchema } from 'api/gen/CalfFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CorrectionReportDetail, CorrectionReportDetailSchema } from 'api/gen/CorrectionReportDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateAnimalList, CreateAnimalListSchema } from 'api/gen/CreateAnimalList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateAnimalListFromIds, CreateAnimalListFromIdsSchema } from 'api/gen/CreateAnimalListFromIds';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateApc, CreateApcSchema } from 'api/gen/CreateApc';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateCriteriaList, CreateCriteriaListSchema } from 'api/gen/CreateCriteriaList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateMilkability, CreateMilkabilitySchema } from 'api/gen/CreateMilkability';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateOrUpdateGridUserSettings, CreateOrUpdateGridUserSettingsSchema } from 'api/gen/CreateOrUpdateGridUserSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateOrUpdateRole, CreateOrUpdateRoleSchema } from 'api/gen/CreateOrUpdateRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreatePermission, CreatePermissionSchema } from 'api/gen/CreatePermission';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateSubjectMember, CreateSubjectMemberSchema } from 'api/gen/CreateSubjectMember';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateUserProfileAssociationRole, CreateUserProfileAssociationRoleSchema } from 'api/gen/CreateUserProfileAssociationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateUserProfileBreederRole, CreateUserProfileBreederRoleSchema } from 'api/gen/CreateUserProfileBreederRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateUserProfileOrganizationRole, CreateUserProfileOrganizationRoleSchema } from 'api/gen/CreateUserProfileOrganizationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateUserProfileOtherSubjectRole, CreateUserProfileOtherSubjectRoleSchema } from 'api/gen/CreateUserProfileOtherSubjectRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDefinition, CriteriaDefinitionSchema } from 'api/gen/CriteriaDefinition';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaFilters, CriteriaFiltersSchema } from 'api/gen/CriteriaFilters';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaList, CriteriaListSchema } from 'api/gen/CriteriaList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaListDetails, CriteriaListDetailsSchema } from 'api/gen/CriteriaListDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaListValidation, CriteriaListValidationSchema } from 'api/gen/CriteriaListValidation';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CsvExportParams, CsvExportParamsSchema } from 'api/gen/CsvExportParams';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CsvImportResult, CsvImportResultSchema } from 'api/gen/CsvImportResult';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { District, DistrictSchema } from 'api/gen/District';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EditApc, EditApcSchema } from 'api/gen/EditApc';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Farm, FarmSchema } from 'api/gen/Farm';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmToChoose, FarmToChooseSchema } from 'api/gen/FarmToChoose';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridCode, GridCodeSchema } from 'api/gen/GridCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HuidWrapper, HuidWrapperSchema } from 'api/gen/HuidWrapper';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Language, LanguageSchema } from 'api/gen/Language';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LegacyBeAppInfo, LegacyBeAppInfoSchema } from 'api/gen/LegacyBeAppInfo';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LoginRequest, LoginRequestSchema } from 'api/gen/LoginRequest';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MatingResult, MatingResultSchema } from 'api/gen/MatingResult';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkabilityDetail, MilkabilityDetailSchema } from 'api/gen/MilkabilityDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ModuleGridSettings, ModuleGridSettingsSchema } from 'api/gen/ModuleGridSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ModuleGuideUrls, ModuleGuideUrlsSchema } from 'api/gen/ModuleGuideUrls';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NewUserProfileContact, NewUserProfileContactSchema } from 'api/gen/NewUserProfileContact';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Organization, OrganizationSchema } from 'api/gen/Organization';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenter, OrganizationCenterSchema } from 'api/gen/OrganizationCenter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCenterAutocomplete, OrganizationCenterAutocompleteSchema } from 'api/gen/OrganizationCenterAutocomplete';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationToChoose, OrganizationToChooseSchema } from 'api/gen/OrganizationToChoose';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OtherSubject, OtherSubjectSchema } from 'api/gen/OtherSubject';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelAssociationRole, PagedModelAssociationRoleSchema } from 'api/gen/PagedModelAssociationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelBasicRole, PagedModelBasicRoleSchema } from 'api/gen/PagedModelBasicRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelBreederRole, PagedModelBreederRoleSchema } from 'api/gen/PagedModelBreederRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelBreedingFemale, PagedModelBreedingFemaleSchema } from 'api/gen/PagedModelBreedingFemale';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelCabBreedingFemale, PagedModelCabBreedingFemaleSchema } from 'api/gen/PagedModelCabBreedingFemale';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelCabCalf, PagedModelCabCalfSchema } from 'api/gen/PagedModelCabCalf';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelCalf, PagedModelCalfSchema } from 'api/gen/PagedModelCalf';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelCorrectionReport, PagedModelCorrectionReportSchema } from 'api/gen/PagedModelCorrectionReport';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelFemaleHolsteinAnimalInList, PagedModelFemaleHolsteinAnimalInListSchema } from 'api/gen/PagedModelFemaleHolsteinAnimalInList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelMaleFleckviehAnimalInList, PagedModelMaleFleckviehAnimalInListSchema } from 'api/gen/PagedModelMaleFleckviehAnimalInList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelMaleHolsteinAnimalInList, PagedModelMaleHolsteinAnimalInListSchema } from 'api/gen/PagedModelMaleHolsteinAnimalInList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelMilkability, PagedModelMilkabilitySchema } from 'api/gen/PagedModelMilkability';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelOrganizationRole, PagedModelOrganizationRoleSchema } from 'api/gen/PagedModelOrganizationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelOtherSubjectRole, PagedModelOtherSubjectRoleSchema } from 'api/gen/PagedModelOtherSubjectRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelPermissionDetail, PagedModelPermissionDetailSchema } from 'api/gen/PagedModelPermissionDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelRolePermission, PagedModelRolePermissionSchema } from 'api/gen/PagedModelRolePermission';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelRoleView, PagedModelRoleViewSchema } from 'api/gen/PagedModelRoleView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelStableToChoose, PagedModelStableToChooseSchema } from 'api/gen/PagedModelStableToChoose';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelUserProfileContact, PagedModelUserProfileContactSchema } from 'api/gen/PagedModelUserProfileContact';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelUserSubjectView, PagedModelUserSubjectViewSchema } from 'api/gen/PagedModelUserSubjectView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagedModelUserView, PagedModelUserViewSchema } from 'api/gen/PagedModelUserView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PaymentCode, PaymentCodeSchema } from 'api/gen/PaymentCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Permission, PermissionSchema } from 'api/gen/Permission';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Principal, PrincipalSchema } from 'api/gen/Principal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProductionArea, ProductionAreaSchema } from 'api/gen/ProductionArea';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProductionAreaInCE, ProductionAreaInCESchema } from 'api/gen/ProductionAreaInCE';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RenameAnimalList, RenameAnimalListSchema } from 'api/gen/RenameAnimalList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RenameCriteriaList, RenameCriteriaListSchema } from 'api/gen/RenameCriteriaList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleDetail, RoleDetailSchema } from 'api/gen/RoleDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleV1, RoleV1Schema } from 'api/gen/RoleV1';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowVersion, RowVersionSchema } from 'api/gen/RowVersion';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SafeCharacters, SafeCharactersSchema } from 'api/gen/SafeCharacters';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Sex, SexSchema } from 'api/gen/Sex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableDateRelationMilking, StableDateRelationMilkingSchema } from 'api/gen/StableDateRelationMilking';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Subject, SubjectSchema } from 'api/gen/Subject';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFilter, TableFilterSchema } from 'api/gen/TableFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TraitsWeights, TraitsWeightsSchema } from 'api/gen/TraitsWeights';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateCriteriaList, UpdateCriteriaListSchema } from 'api/gen/UpdateCriteriaList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateMilkability, UpdateMilkabilitySchema } from 'api/gen/UpdateMilkability';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdatePermission, UpdatePermissionSchema } from 'api/gen/UpdatePermission';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateSubjectMember, UpdateSubjectMemberSchema } from 'api/gen/UpdateSubjectMember';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateUserContact, UpdateUserContactSchema } from 'api/gen/UpdateUserContact';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateUserProfileAssociationRole, UpdateUserProfileAssociationRoleSchema } from 'api/gen/UpdateUserProfileAssociationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateUserProfileBreederRole, UpdateUserProfileBreederRoleSchema } from 'api/gen/UpdateUserProfileBreederRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateUserProfileOrganizationRole, UpdateUserProfileOrganizationRoleSchema } from 'api/gen/UpdateUserProfileOrganizationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateUserProfileOtherSubjectRole, UpdateUserProfileOtherSubjectRoleSchema } from 'api/gen/UpdateUserProfileOtherSubjectRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserDetail, UserDetailSchema } from 'api/gen/UserDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserProfile, UserProfileSchema } from 'api/gen/UserProfile';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateApc, ValidateApcSchema } from 'api/gen/ValidateApc';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateAssociationRole, ValidateAssociationRoleSchema } from 'api/gen/ValidateAssociationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateBreederRole, ValidateBreederRoleSchema } from 'api/gen/ValidateBreederRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateMilkabilityReport, ValidateMilkabilityReportSchema } from 'api/gen/ValidateMilkabilityReport';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateOrganizationRole, ValidateOrganizationRoleSchema } from 'api/gen/ValidateOrganizationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateOtherSubjectRole, ValidateOtherSubjectRoleSchema } from 'api/gen/ValidateOtherSubjectRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidatePermission, ValidatePermissionSchema } from 'api/gen/ValidatePermission';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateRole, ValidateRoleSchema } from 'api/gen/ValidateRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateSubjectMember, ValidateSubjectMemberSchema } from 'api/gen/ValidateSubjectMember';
// imports for custom endpoints import {Config} from 'app/config';


function fileToFormData(file: File): FormData {
    const data = new FormData();
    data.append('file', file);
    return data;
}

const getAccordionsQueryParamsRequiredPart = t.interface({
  associationType: AssociationTypeSchema,
});
export const GetAccordionsQueryParamsSchema = excess(getAccordionsQueryParamsRequiredPart);
export interface GetAccordionsQueryParams extends t.TypeOf<typeof GetAccordionsQueryParamsSchema> {}


const deletePermissionPathParamsRequiredPart = t.interface({
  permissionId: PositiveIntegerSchema,
});
export const DeletePermissionPathParamsSchema = excess(deletePermissionPathParamsRequiredPart);
export interface DeletePermissionPathParams extends t.TypeOf<typeof DeletePermissionPathParamsSchema> {}


const findPermissionsQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindPermissionsQueryParamsSchema = excess(findPermissionsQueryParamsRequiredPart);
export interface FindPermissionsQueryParams extends t.TypeOf<typeof FindPermissionsQueryParamsSchema> {}


const updatePermissionPathParamsRequiredPart = t.interface({
  permissionId: PositiveIntegerSchema,
});
export const UpdatePermissionPathParamsSchema = excess(updatePermissionPathParamsRequiredPart);
export interface UpdatePermissionPathParams extends t.TypeOf<typeof UpdatePermissionPathParamsSchema> {}


const addPermissionToRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
  permissionId: PositiveIntegerSchema,
});
export const AddPermissionToRolePathParamsSchema = excess(addPermissionToRolePathParamsRequiredPart);
export interface AddPermissionToRolePathParams extends t.TypeOf<typeof AddPermissionToRolePathParamsSchema> {}


const addSubordinateRoleToRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
  subordinateRoleId: PositiveIntegerSchema,
});
export const AddSubordinateRoleToRolePathParamsSchema = excess(addSubordinateRoleToRolePathParamsRequiredPart);
export interface AddSubordinateRoleToRolePathParams extends t.TypeOf<typeof AddSubordinateRoleToRolePathParamsSchema> {}


const deleteRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const DeleteRolePathParamsSchema = excess(deleteRolePathParamsRequiredPart);
export interface DeleteRolePathParams extends t.TypeOf<typeof DeleteRolePathParamsSchema> {}


const getPermissionsAssignedToRoleQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetPermissionsAssignedToRoleQueryParamsSchema = excess(getPermissionsAssignedToRoleQueryParamsRequiredPart);
export interface GetPermissionsAssignedToRoleQueryParams extends t.TypeOf<typeof GetPermissionsAssignedToRoleQueryParamsSchema> {}


const getPermissionsAssignedToRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const GetPermissionsAssignedToRolePathParamsSchema = excess(getPermissionsAssignedToRolePathParamsRequiredPart);
export interface GetPermissionsAssignedToRolePathParams extends t.TypeOf<typeof GetPermissionsAssignedToRolePathParamsSchema> {}


const getPermissionsUnassignedToRoleQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetPermissionsUnassignedToRoleQueryParamsSchema = excess(getPermissionsUnassignedToRoleQueryParamsRequiredPart);
export interface GetPermissionsUnassignedToRoleQueryParams extends t.TypeOf<typeof GetPermissionsUnassignedToRoleQueryParamsSchema> {}


const getPermissionsUnassignedToRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const GetPermissionsUnassignedToRolePathParamsSchema = excess(getPermissionsUnassignedToRolePathParamsRequiredPart);
export interface GetPermissionsUnassignedToRolePathParams extends t.TypeOf<typeof GetPermissionsUnassignedToRolePathParamsSchema> {}


const getRoleDetailPathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const GetRoleDetailPathParamsSchema = excess(getRoleDetailPathParamsRequiredPart);
export interface GetRoleDetailPathParams extends t.TypeOf<typeof GetRoleDetailPathParamsSchema> {}


const getRolesPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetRolesPageQueryParamsSchema = excess(getRolesPageQueryParamsRequiredPart);
export interface GetRolesPageQueryParams extends t.TypeOf<typeof GetRolesPageQueryParamsSchema> {}


const getRolesUnassignedToRoleQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetRolesUnassignedToRoleQueryParamsSchema = excess(getRolesUnassignedToRoleQueryParamsRequiredPart);
export interface GetRolesUnassignedToRoleQueryParams extends t.TypeOf<typeof GetRolesUnassignedToRoleQueryParamsSchema> {}


const getRolesUnassignedToRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const GetRolesUnassignedToRolePathParamsSchema = excess(getRolesUnassignedToRolePathParamsRequiredPart);
export interface GetRolesUnassignedToRolePathParams extends t.TypeOf<typeof GetRolesUnassignedToRolePathParamsSchema> {}


const getSubordinateRolesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetSubordinateRolesQueryParamsSchema = excess(getSubordinateRolesQueryParamsRequiredPart);
export interface GetSubordinateRolesQueryParams extends t.TypeOf<typeof GetSubordinateRolesQueryParamsSchema> {}


const getSubordinateRolesPathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const GetSubordinateRolesPathParamsSchema = excess(getSubordinateRolesPathParamsRequiredPart);
export interface GetSubordinateRolesPathParams extends t.TypeOf<typeof GetSubordinateRolesPathParamsSchema> {}


const removePermissionFromRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
  permissionId: PositiveIntegerSchema,
});
export const RemovePermissionFromRolePathParamsSchema = excess(removePermissionFromRolePathParamsRequiredPart);
export interface RemovePermissionFromRolePathParams extends t.TypeOf<typeof RemovePermissionFromRolePathParamsSchema> {}


const removeSubordinateRoleFromRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
  subordinateRoleId: PositiveIntegerSchema,
});
export const RemoveSubordinateRoleFromRolePathParamsSchema = excess(removeSubordinateRoleFromRolePathParamsRequiredPart);
export interface RemoveSubordinateRoleFromRolePathParams extends t.TypeOf<typeof RemoveSubordinateRoleFromRolePathParamsSchema> {}


const updateRolePathParamsRequiredPart = t.interface({
  roleId: PositiveIntegerSchema,
});
export const UpdateRolePathParamsSchema = excess(updateRolePathParamsRequiredPart);
export interface UpdateRolePathParams extends t.TypeOf<typeof UpdateRolePathParamsSchema> {}


const getUserProfileV2PathParamsRequiredPart = t.interface({
  userProfileId: PositiveIntegerSchema,
});
export const GetUserProfileV2PathParamsSchema = excess(getUserProfileV2PathParamsRequiredPart);
export interface GetUserProfileV2PathParams extends t.TypeOf<typeof GetUserProfileV2PathParamsSchema> {}


const getUsersPageV2QueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetUsersPageV2QueryParamsSchema = excess(getUsersPageV2QueryParamsRequiredPart);
export interface GetUsersPageV2QueryParams extends t.TypeOf<typeof GetUsersPageV2QueryParamsSchema> {}


const getUserAssociationsPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetUserAssociationsPageQueryParamsSchema = excess(getUserAssociationsPageQueryParamsRequiredPart);
export interface GetUserAssociationsPageQueryParams extends t.TypeOf<typeof GetUserAssociationsPageQueryParamsSchema> {}


const getUserAssociationsPagePathParamsRequiredPart = t.interface({
  userProfileId: t.Integer,
});
export const GetUserAssociationsPagePathParamsSchema = excess(getUserAssociationsPagePathParamsRequiredPart);
export interface GetUserAssociationsPagePathParams extends t.TypeOf<typeof GetUserAssociationsPagePathParamsSchema> {}


const searchAssociationRolesQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchAssociationRolesQueryParamsSchema = excess(searchAssociationRolesQueryParamsRequiredPart);
export interface SearchAssociationRolesQueryParams extends t.TypeOf<typeof SearchAssociationRolesQueryParamsSchema> {}


const searchAssociationsQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchAssociationsQueryParamsSchema = excess(searchAssociationsQueryParamsRequiredPart);
export interface SearchAssociationsQueryParams extends t.TypeOf<typeof SearchAssociationsQueryParamsSchema> {}


const findAllFarmsByBreederIdPathParamsRequiredPart = t.interface({
  breederId: t.Integer,
});
export const FindAllFarmsByBreederIdPathParamsSchema = excess(findAllFarmsByBreederIdPathParamsRequiredPart);
export interface FindAllFarmsByBreederIdPathParams extends t.TypeOf<typeof FindAllFarmsByBreederIdPathParamsSchema> {}


const getUserBreedersPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetUserBreedersPageQueryParamsSchema = excess(getUserBreedersPageQueryParamsRequiredPart);
export interface GetUserBreedersPageQueryParams extends t.TypeOf<typeof GetUserBreedersPageQueryParamsSchema> {}


const getUserBreedersPagePathParamsRequiredPart = t.interface({
  userProfileId: t.Integer,
});
export const GetUserBreedersPagePathParamsSchema = excess(getUserBreedersPagePathParamsRequiredPart);
export interface GetUserBreedersPagePathParams extends t.TypeOf<typeof GetUserBreedersPagePathParamsSchema> {}


const searchBreederRolesQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchBreederRolesQueryParamsSchema = excess(searchBreederRolesQueryParamsRequiredPart);
export interface SearchBreederRolesQueryParams extends t.TypeOf<typeof SearchBreederRolesQueryParamsSchema> {}


const searchBreedersQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchBreedersQueryParamsSchema = excess(searchBreedersQueryParamsRequiredPart);
export interface SearchBreedersQueryParams extends t.TypeOf<typeof SearchBreedersQueryParamsSchema> {}


const deleteUserContactPathParamsRequiredPart = t.interface({
  userContactId: PositiveIntegerSchema,
});
export const DeleteUserContactPathParamsSchema = excess(deleteUserContactPathParamsRequiredPart);
export interface DeleteUserContactPathParams extends t.TypeOf<typeof DeleteUserContactPathParamsSchema> {}


const getUserContactsPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetUserContactsPageQueryParamsSchema = excess(getUserContactsPageQueryParamsRequiredPart);
export interface GetUserContactsPageQueryParams extends t.TypeOf<typeof GetUserContactsPageQueryParamsSchema> {}


const getUserContactsPagePathParamsRequiredPart = t.interface({
  userProfileId: t.Integer,
});
export const GetUserContactsPagePathParamsSchema = excess(getUserContactsPagePathParamsRequiredPart);
export interface GetUserContactsPagePathParams extends t.TypeOf<typeof GetUserContactsPagePathParamsSchema> {}


const updateUserContactPathParamsRequiredPart = t.interface({
  userContactId: PositiveIntegerSchema,
});
export const UpdateUserContactPathParamsSchema = excess(updateUserContactPathParamsRequiredPart);
export interface UpdateUserContactPathParams extends t.TypeOf<typeof UpdateUserContactPathParamsSchema> {}


const findAllCentersByOrganizationIdPathParamsRequiredPart = t.interface({
  organizationId: PositiveIntegerSchema,
});
export const FindAllCentersByOrganizationIdPathParamsSchema = excess(findAllCentersByOrganizationIdPathParamsRequiredPart);
export interface FindAllCentersByOrganizationIdPathParams extends t.TypeOf<typeof FindAllCentersByOrganizationIdPathParamsSchema> {}


const findAllFarmsByOrganizationIdPathParamsRequiredPart = t.interface({
  organizationId: PositiveIntegerSchema,
});
export const FindAllFarmsByOrganizationIdPathParamsSchema = excess(findAllFarmsByOrganizationIdPathParamsRequiredPart);
export interface FindAllFarmsByOrganizationIdPathParams extends t.TypeOf<typeof FindAllFarmsByOrganizationIdPathParamsSchema> {}


const getUserOrganizationPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetUserOrganizationPageQueryParamsSchema = excess(getUserOrganizationPageQueryParamsRequiredPart);
export interface GetUserOrganizationPageQueryParams extends t.TypeOf<typeof GetUserOrganizationPageQueryParamsSchema> {}


const getUserOrganizationPagePathParamsRequiredPart = t.interface({
  userProfileId: t.Integer,
});
export const GetUserOrganizationPagePathParamsSchema = excess(getUserOrganizationPagePathParamsRequiredPart);
export interface GetUserOrganizationPagePathParams extends t.TypeOf<typeof GetUserOrganizationPagePathParamsSchema> {}


const searchOrganizationRolesQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchOrganizationRolesQueryParamsSchema = excess(searchOrganizationRolesQueryParamsRequiredPart);
export interface SearchOrganizationRolesQueryParams extends t.TypeOf<typeof SearchOrganizationRolesQueryParamsSchema> {}


const searchOrganizationsQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchOrganizationsQueryParamsSchema = excess(searchOrganizationsQueryParamsRequiredPart);
export interface SearchOrganizationsQueryParams extends t.TypeOf<typeof SearchOrganizationsQueryParamsSchema> {}


const getUserOtherSubjectPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetUserOtherSubjectPageQueryParamsSchema = excess(getUserOtherSubjectPageQueryParamsRequiredPart);
export interface GetUserOtherSubjectPageQueryParams extends t.TypeOf<typeof GetUserOtherSubjectPageQueryParamsSchema> {}


const getUserOtherSubjectPagePathParamsRequiredPart = t.interface({
  userProfileId: t.Integer,
});
export const GetUserOtherSubjectPagePathParamsSchema = excess(getUserOtherSubjectPagePathParamsRequiredPart);
export interface GetUserOtherSubjectPagePathParams extends t.TypeOf<typeof GetUserOtherSubjectPagePathParamsSchema> {}


const searchOtherSubjectRolesQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchOtherSubjectRolesQueryParamsSchema = excess(searchOtherSubjectRolesQueryParamsRequiredPart);
export interface SearchOtherSubjectRolesQueryParams extends t.TypeOf<typeof SearchOtherSubjectRolesQueryParamsSchema> {}


const searchOtherSubjectsQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchOtherSubjectsQueryParamsSchema = excess(searchOtherSubjectsQueryParamsRequiredPart);
export interface SearchOtherSubjectsQueryParams extends t.TypeOf<typeof SearchOtherSubjectsQueryParamsSchema> {}


const getSubjectMembersPageQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetSubjectMembersPageQueryParamsSchema = excess(getSubjectMembersPageQueryParamsRequiredPart);
export interface GetSubjectMembersPageQueryParams extends t.TypeOf<typeof GetSubjectMembersPageQueryParamsSchema> {}


const getSubjectMembersPagePathParamsRequiredPart = t.interface({
  userProfileId: t.Integer,
});
export const GetSubjectMembersPagePathParamsSchema = excess(getSubjectMembersPagePathParamsRequiredPart);
export interface GetSubjectMembersPagePathParams extends t.TypeOf<typeof GetSubjectMembersPagePathParamsSchema> {}


const searchSubjectsQueryParamsRequiredPart = t.interface({
  text: t.union([t.string, t.null]),
});
export const SearchSubjectsQueryParamsSchema = excess(searchSubjectsQueryParamsRequiredPart);
export interface SearchSubjectsQueryParams extends t.TypeOf<typeof SearchSubjectsQueryParamsSchema> {}


const updateSubjectMemberApprovalPathParamsRequiredPart = t.interface({
  subjectMemberId: t.Integer,
});
export const UpdateSubjectMemberApprovalPathParamsSchema = excess(updateSubjectMemberApprovalPathParamsRequiredPart);
export interface UpdateSubjectMemberApprovalPathParams extends t.TypeOf<typeof UpdateSubjectMemberApprovalPathParamsSchema> {}


const deleteCorrectionReportPathParamsRequiredPart = t.interface({
  apcId: PositiveIntegerSchema,
});
export const DeleteCorrectionReportPathParamsSchema = excess(deleteCorrectionReportPathParamsRequiredPart);
export interface DeleteCorrectionReportPathParams extends t.TypeOf<typeof DeleteCorrectionReportPathParamsSchema> {}


const editCorrectionReportPathParamsRequiredPart = t.interface({
  apcId: PositiveIntegerSchema,
});
export const EditCorrectionReportPathParamsSchema = excess(editCorrectionReportPathParamsRequiredPart);
export interface EditCorrectionReportPathParams extends t.TypeOf<typeof EditCorrectionReportPathParamsSchema> {}


const exportCorrectionReportsQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportCorrectionReportsQueryParamsSchema = excess(exportCorrectionReportsQueryParamsRequiredPart);
export interface ExportCorrectionReportsQueryParams extends t.TypeOf<typeof ExportCorrectionReportsQueryParamsSchema> {}


const getAnalysisProtocolQueryParamsRequiredPart = t.interface({
  earTag: EarTagSchema,
  proficiencyTestDate: DateSchema,
});
export const GetAnalysisProtocolQueryParamsSchema = excess(getAnalysisProtocolQueryParamsRequiredPart);
export interface GetAnalysisProtocolQueryParams extends t.TypeOf<typeof GetAnalysisProtocolQueryParamsSchema> {}


const getCorrectionReportPathParamsRequiredPart = t.interface({
  apcId: PositiveIntegerSchema,
});
export const GetCorrectionReportPathParamsSchema = excess(getCorrectionReportPathParamsRequiredPart);
export interface GetCorrectionReportPathParams extends t.TypeOf<typeof GetCorrectionReportPathParamsSchema> {}


const getCorrectionReportsQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetCorrectionReportsQueryParamsSchema = excess(getCorrectionReportsQueryParamsRequiredPart);
export interface GetCorrectionReportsQueryParams extends t.TypeOf<typeof GetCorrectionReportsQueryParamsSchema> {}


const getStableDateRelationQueryParamsRequiredPart = t.interface({
  stableCode: StableCodeSchema,
  proficiencyTestDate: DateSchema,
});
export const GetStableDateRelationQueryParamsSchema = excess(getStableDateRelationQueryParamsRequiredPart);
export interface GetStableDateRelationQueryParams extends t.TypeOf<typeof GetStableDateRelationQueryParamsSchema> {}


const getStableDateRelationsQueryParamsRequiredPart = t.interface({
  earTag: EarTagSchema,
});
export const GetStableDateRelationsQueryParamsSchema = excess(getStableDateRelationsQueryParamsRequiredPart);
export interface GetStableDateRelationsQueryParams extends t.TypeOf<typeof GetStableDateRelationsQueryParamsSchema> {}


const deleteAnimalListQueryParamsRequiredPart = t.interface({
  version: RowVersionSchema,
});
export const DeleteAnimalListQueryParamsSchema = excess(deleteAnimalListQueryParamsRequiredPart);
export interface DeleteAnimalListQueryParams extends t.TypeOf<typeof DeleteAnimalListQueryParamsSchema> {}


const deleteAnimalListPathParamsRequiredPart = t.interface({
  animalListId: PositiveIntegerSchema,
});
export const DeleteAnimalListPathParamsSchema = excess(deleteAnimalListPathParamsRequiredPart);
export interface DeleteAnimalListPathParams extends t.TypeOf<typeof DeleteAnimalListPathParamsSchema> {}


const getAnimalListsQueryParamsRequiredPart = t.interface({
  module: AppModuleSchema,
  code: GridCodeSchema,
});
export const GetAnimalListsQueryParamsSchema = excess(getAnimalListsQueryParamsRequiredPart);
export interface GetAnimalListsQueryParams extends t.TypeOf<typeof GetAnimalListsQueryParamsSchema> {}


const renameAnimalListPathParamsRequiredPart = t.interface({
  animalListId: PositiveIntegerSchema,
});
export const RenameAnimalListPathParamsSchema = excess(renameAnimalListPathParamsRequiredPart);
export interface RenameAnimalListPathParams extends t.TypeOf<typeof RenameAnimalListPathParamsSchema> {}


const updateAnimalListFromIdsQueryParamsRequiredPart = t.interface({
  version: RowVersionSchema,
  animalIds: t.array(PositiveIntegerSchema),
});
export const UpdateAnimalListFromIdsQueryParamsSchema = excess(updateAnimalListFromIdsQueryParamsRequiredPart);
export interface UpdateAnimalListFromIdsQueryParams extends t.TypeOf<typeof UpdateAnimalListFromIdsQueryParamsSchema> {}


const updateAnimalListFromIdsPathParamsRequiredPart = t.interface({
  animalListId: PositiveIntegerSchema,
});
export const UpdateAnimalListFromIdsPathParamsSchema = excess(updateAnimalListFromIdsPathParamsRequiredPart);
export interface UpdateAnimalListFromIdsPathParams extends t.TypeOf<typeof UpdateAnimalListFromIdsPathParamsSchema> {}


const updateAnimalListFromSessionQueryParamsRequiredPart = t.interface({
  version: RowVersionSchema,
});
export const UpdateAnimalListFromSessionQueryParamsSchema = excess(updateAnimalListFromSessionQueryParamsRequiredPart);
export interface UpdateAnimalListFromSessionQueryParams extends t.TypeOf<typeof UpdateAnimalListFromSessionQueryParamsSchema> {}


const updateAnimalListFromSessionPathParamsRequiredPart = t.interface({
  animalListId: PositiveIntegerSchema,
});
export const UpdateAnimalListFromSessionPathParamsSchema = excess(updateAnimalListFromSessionPathParamsRequiredPart);
export interface UpdateAnimalListFromSessionPathParams extends t.TypeOf<typeof UpdateAnimalListFromSessionPathParamsSchema> {}


const addAnimalsToListQueryParamsRequiredPart = t.interface({
  animalIds: t.array(PositiveIntegerSchema), // ids of animals to be added to the user's session
});
export const AddAnimalsToListQueryParamsSchema = excess(addAnimalsToListQueryParamsRequiredPart);
export interface AddAnimalsToListQueryParams extends t.TypeOf<typeof AddAnimalsToListQueryParamsSchema> {}


const addAnimalsToListPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const AddAnimalsToListPathParamsSchema = excess(addAnimalsToListPathParamsRequiredPart);
export interface AddAnimalsToListPathParams extends t.TypeOf<typeof AddAnimalsToListPathParamsSchema> {}


const exportAnimalsQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportAnimalsQueryParamsSchema = excess(exportAnimalsQueryParamsRequiredPart);
export interface ExportAnimalsQueryParams extends t.TypeOf<typeof ExportAnimalsQueryParamsSchema> {}


const exportAnimalsPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const ExportAnimalsPathParamsSchema = excess(exportAnimalsPathParamsRequiredPart);
export interface ExportAnimalsPathParams extends t.TypeOf<typeof ExportAnimalsPathParamsSchema> {}


const exportAnimalsInListQueryParamsRequiredPart = t.interface({
  fileName: SafeCharactersSchema,
});
export const ExportAnimalsInListQueryParamsSchema = excess(exportAnimalsInListQueryParamsRequiredPart);
export interface ExportAnimalsInListQueryParams extends t.TypeOf<typeof ExportAnimalsInListQueryParamsSchema> {}


const exportAnimalsInListPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const ExportAnimalsInListPathParamsSchema = excess(exportAnimalsInListPathParamsRequiredPart);
export interface ExportAnimalsInListPathParams extends t.TypeOf<typeof ExportAnimalsInListPathParamsSchema> {}


const filterAnimalsInListPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const FilterAnimalsInListPathParamsSchema = excess(filterAnimalsInListPathParamsRequiredPart);
export interface FilterAnimalsInListPathParams extends t.TypeOf<typeof FilterAnimalsInListPathParamsSchema> {}


const getAvailableAnimalsQueryParamsRequiredPart = t.interface({
  label: t.union([t.string, t.null]),
});
export const GetAvailableAnimalsQueryParamsSchema = excess(getAvailableAnimalsQueryParamsRequiredPart);
export interface GetAvailableAnimalsQueryParams extends t.TypeOf<typeof GetAvailableAnimalsQueryParamsSchema> {}


const getAvailableAnimalsPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const GetAvailableAnimalsPathParamsSchema = excess(getAvailableAnimalsPathParamsRequiredPart);
export interface GetAvailableAnimalsPathParams extends t.TypeOf<typeof GetAvailableAnimalsPathParamsSchema> {}


const getFemaleHolsteinAnimalsInListQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetFemaleHolsteinAnimalsInListQueryParamsSchema = excess(getFemaleHolsteinAnimalsInListQueryParamsRequiredPart);
export interface GetFemaleHolsteinAnimalsInListQueryParams extends t.TypeOf<typeof GetFemaleHolsteinAnimalsInListQueryParamsSchema> {}


const getMaleFleckviehAnimalsInListQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetMaleFleckviehAnimalsInListQueryParamsSchema = excess(getMaleFleckviehAnimalsInListQueryParamsRequiredPart);
export interface GetMaleFleckviehAnimalsInListQueryParams extends t.TypeOf<typeof GetMaleFleckviehAnimalsInListQueryParamsSchema> {}


const getMaleHolsteinAnimalsInListQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetMaleHolsteinAnimalsInListQueryParamsSchema = excess(getMaleHolsteinAnimalsInListQueryParamsRequiredPart);
export interface GetMaleHolsteinAnimalsInListQueryParams extends t.TypeOf<typeof GetMaleHolsteinAnimalsInListQueryParamsSchema> {}


const getMatingAnimalSessionStatusPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const GetMatingAnimalSessionStatusPathParamsSchema = excess(getMatingAnimalSessionStatusPathParamsRequiredPart);
export interface GetMatingAnimalSessionStatusPathParams extends t.TypeOf<typeof GetMatingAnimalSessionStatusPathParamsSchema> {}


const importAnimalsInListPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const ImportAnimalsInListPathParamsSchema = excess(importAnimalsInListPathParamsRequiredPart);
export interface ImportAnimalsInListPathParams extends t.TypeOf<typeof ImportAnimalsInListPathParamsSchema> {}


const importAnimalsInListFromDbQueryParamsRequiredPart = t.interface({
  associationType: AssociationTypeSchema,
});
export const ImportAnimalsInListFromDbQueryParamsSchema = excess(importAnimalsInListFromDbQueryParamsRequiredPart);
export interface ImportAnimalsInListFromDbQueryParams extends t.TypeOf<typeof ImportAnimalsInListFromDbQueryParamsSchema> {}


const importAnimalsInListFromDbPathParamsRequiredPart = t.interface({
  animalListId: PositiveIntegerSchema,
});
export const ImportAnimalsInListFromDbPathParamsSchema = excess(importAnimalsInListFromDbPathParamsRequiredPart);
export interface ImportAnimalsInListFromDbPathParams extends t.TypeOf<typeof ImportAnimalsInListFromDbPathParamsSchema> {}


const removeAnimalFromListPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
  animalId: PositiveIntegerSchema,
});
export const RemoveAnimalFromListPathParamsSchema = excess(removeAnimalFromListPathParamsRequiredPart);
export interface RemoveAnimalFromListPathParams extends t.TypeOf<typeof RemoveAnimalFromListPathParamsSchema> {}


const resetListPathParamsRequiredPart = t.interface({
  sex: SexSchema,
  associationType: AssociationTypeSchema,
});
export const ResetListPathParamsSchema = excess(resetListPathParamsRequiredPart);
export interface ResetListPathParams extends t.TypeOf<typeof ResetListPathParamsSchema> {}


const exportBreedingFemalesQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportBreedingFemalesQueryParamsSchema = excess(exportBreedingFemalesQueryParamsRequiredPart);
export interface ExportBreedingFemalesQueryParams extends t.TypeOf<typeof ExportBreedingFemalesQueryParamsSchema> {}


const exportBreedingFemalesPathParamsRequiredPart = t.interface({
  stableId: PositiveIntegerSchema,
});
export const ExportBreedingFemalesPathParamsSchema = excess(exportBreedingFemalesPathParamsRequiredPart);
export interface ExportBreedingFemalesPathParams extends t.TypeOf<typeof ExportBreedingFemalesPathParamsSchema> {}


const getBreedingFemalesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetBreedingFemalesQueryParamsSchema = excess(getBreedingFemalesQueryParamsRequiredPart);
export interface GetBreedingFemalesQueryParams extends t.TypeOf<typeof GetBreedingFemalesQueryParamsSchema> {}


const getBreedingFemalesPathParamsRequiredPart = t.interface({
  stableId: PositiveIntegerSchema,
});
export const GetBreedingFemalesPathParamsSchema = excess(getBreedingFemalesPathParamsRequiredPart);
export interface GetBreedingFemalesPathParams extends t.TypeOf<typeof GetBreedingFemalesPathParamsSchema> {}


const exportCalvesByFarmQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportCalvesByFarmQueryParamsSchema = excess(exportCalvesByFarmQueryParamsRequiredPart);
export interface ExportCalvesByFarmQueryParams extends t.TypeOf<typeof ExportCalvesByFarmQueryParamsSchema> {}


const exportCalvesByFarmPathParamsRequiredPart = t.interface({
  farmId: PositiveIntegerSchema,
});
export const ExportCalvesByFarmPathParamsSchema = excess(exportCalvesByFarmPathParamsRequiredPart);
export interface ExportCalvesByFarmPathParams extends t.TypeOf<typeof ExportCalvesByFarmPathParamsSchema> {}


const getAnimalsBrowserFarmPathParamsRequiredPart = t.interface({
  farmId: PositiveIntegerSchema,
});
export const GetAnimalsBrowserFarmPathParamsSchema = excess(getAnimalsBrowserFarmPathParamsRequiredPart);
export interface GetAnimalsBrowserFarmPathParams extends t.TypeOf<typeof GetAnimalsBrowserFarmPathParamsSchema> {}


const getCalvesByFarmQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetCalvesByFarmQueryParamsSchema = excess(getCalvesByFarmQueryParamsRequiredPart);
export interface GetCalvesByFarmQueryParams extends t.TypeOf<typeof GetCalvesByFarmQueryParamsSchema> {}


const getCalvesByFarmPathParamsRequiredPart = t.interface({
  farmId: PositiveIntegerSchema,
});
export const GetCalvesByFarmPathParamsSchema = excess(getCalvesByFarmPathParamsRequiredPart);
export interface GetCalvesByFarmPathParams extends t.TypeOf<typeof GetCalvesByFarmPathParamsSchema> {}


const exportStablesQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportStablesQueryParamsSchema = excess(exportStablesQueryParamsRequiredPart);
export interface ExportStablesQueryParams extends t.TypeOf<typeof ExportStablesQueryParamsSchema> {}


const getAnimalsBrowserStablePathParamsRequiredPart = t.interface({
  stableId: PositiveIntegerSchema,
});
export const GetAnimalsBrowserStablePathParamsSchema = excess(getAnimalsBrowserStablePathParamsRequiredPart);
export interface GetAnimalsBrowserStablePathParams extends t.TypeOf<typeof GetAnimalsBrowserStablePathParamsSchema> {}


const getStablesToChooseQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetStablesToChooseQueryParamsSchema = excess(getStablesToChooseQueryParamsRequiredPart);
export interface GetStablesToChooseQueryParams extends t.TypeOf<typeof GetStablesToChooseQueryParamsSchema> {}


const getCabBreedersAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabBreedersAutocompleteListQueryParamsSchema = excess(getCabBreedersAutocompleteListQueryParamsRequiredPart);
export interface GetCabBreedersAutocompleteListQueryParams extends t.TypeOf<typeof GetCabBreedersAutocompleteListQueryParamsSchema> {}


const getCabDistrictsAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabDistrictsAutocompleteListQueryParamsSchema = excess(getCabDistrictsAutocompleteListQueryParamsRequiredPart);
export interface GetCabDistrictsAutocompleteListQueryParams extends t.TypeOf<typeof GetCabDistrictsAutocompleteListQueryParamsSchema> {}


const getCabFarmsAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabFarmsAutocompleteListQueryParamsSchema = excess(getCabFarmsAutocompleteListQueryParamsRequiredPart);
export interface GetCabFarmsAutocompleteListQueryParams extends t.TypeOf<typeof GetCabFarmsAutocompleteListQueryParamsSchema> {}


const getCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParamsSchema = excess(getCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParamsRequiredPart);
export interface GetCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParams extends t.TypeOf<typeof GetCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParamsSchema> {}


const getCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParamsSchema = excess(getCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParamsRequiredPart);
export interface GetCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParams extends t.TypeOf<typeof GetCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParamsSchema> {}


const getCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParamsSchema = excess(getCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParamsRequiredPart);
export interface GetCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParams extends t.TypeOf<typeof GetCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParamsSchema> {}


const getCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParamsSchema = excess(getCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParamsRequiredPart);
export interface GetCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParams extends t.TypeOf<typeof GetCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParamsSchema> {}


const getCabStableAutocompleteListQueryParamsRequiredPart = t.interface({
  text: AutocompleteFilterTextSchema,
});
export const GetCabStableAutocompleteListQueryParamsSchema = excess(getCabStableAutocompleteListQueryParamsRequiredPart);
export interface GetCabStableAutocompleteListQueryParams extends t.TypeOf<typeof GetCabStableAutocompleteListQueryParamsSchema> {}


const cabAddAnimalsToListQueryParamsRequiredPart = t.interface({
  animalIds: t.array(PositiveIntegerSchema),
});
export const CabAddAnimalsToListQueryParamsSchema = excess(cabAddAnimalsToListQueryParamsRequiredPart);
export interface CabAddAnimalsToListQueryParams extends t.TypeOf<typeof CabAddAnimalsToListQueryParamsSchema> {}


const cabAddAnimalsToListPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
});
export const CabAddAnimalsToListPathParamsSchema = excess(cabAddAnimalsToListPathParamsRequiredPart);
export interface CabAddAnimalsToListPathParams extends t.TypeOf<typeof CabAddAnimalsToListPathParamsSchema> {}


const cabGetAvailableAnimalsQueryParamsRequiredPart = t.interface({
  text: AnimalSearchLabelSchema,
});
export const CabGetAvailableAnimalsQueryParamsSchema = excess(cabGetAvailableAnimalsQueryParamsRequiredPart);
export interface CabGetAvailableAnimalsQueryParams extends t.TypeOf<typeof CabGetAvailableAnimalsQueryParamsSchema> {}


const cabGetAvailableAnimalsPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
});
export const CabGetAvailableAnimalsPathParamsSchema = excess(cabGetAvailableAnimalsPathParamsRequiredPart);
export interface CabGetAvailableAnimalsPathParams extends t.TypeOf<typeof CabGetAvailableAnimalsPathParamsSchema> {}


const cabImportAnimalListToUserSessionPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
  animalListId: PositiveIntegerSchema,
});
export const CabImportAnimalListToUserSessionPathParamsSchema = excess(cabImportAnimalListToUserSessionPathParamsRequiredPart);
export interface CabImportAnimalListToUserSessionPathParams extends t.TypeOf<typeof CabImportAnimalListToUserSessionPathParamsSchema> {}


const cabImportAnimalsFromCsvPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
});
export const CabImportAnimalsFromCsvPathParamsSchema = excess(cabImportAnimalsFromCsvPathParamsRequiredPart);
export interface CabImportAnimalsFromCsvPathParams extends t.TypeOf<typeof CabImportAnimalsFromCsvPathParamsSchema> {}


const deleteCabAnimalsInSessionPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
});
export const DeleteCabAnimalsInSessionPathParamsSchema = excess(deleteCabAnimalsInSessionPathParamsRequiredPart);
export interface DeleteCabAnimalsInSessionPathParams extends t.TypeOf<typeof DeleteCabAnimalsInSessionPathParamsSchema> {}


const exportCabAnimalsQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportCabAnimalsQueryParamsSchema = excess(exportCabAnimalsQueryParamsRequiredPart);
export interface ExportCabAnimalsQueryParams extends t.TypeOf<typeof ExportCabAnimalsQueryParamsSchema> {}


const exportCabAnimalsPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
});
export const ExportCabAnimalsPathParamsSchema = excess(exportCabAnimalsPathParamsRequiredPart);
export interface ExportCabAnimalsPathParams extends t.TypeOf<typeof ExportCabAnimalsPathParamsSchema> {}


const findCabBreedingFemalesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindCabBreedingFemalesQueryParamsSchema = excess(findCabBreedingFemalesQueryParamsRequiredPart);
export interface FindCabBreedingFemalesQueryParams extends t.TypeOf<typeof FindCabBreedingFemalesQueryParamsSchema> {}


const findCabCalvesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindCabCalvesQueryParamsSchema = excess(findCabCalvesQueryParamsRequiredPart);
export interface FindCabCalvesQueryParams extends t.TypeOf<typeof FindCabCalvesQueryParamsSchema> {}


const getCabAnimalSessionStatusPathParamsRequiredPart = t.interface({
  animalType: AnimalTypeSchema,
});
export const GetCabAnimalSessionStatusPathParamsSchema = excess(getCabAnimalSessionStatusPathParamsRequiredPart);
export interface GetCabAnimalSessionStatusPathParams extends t.TypeOf<typeof GetCabAnimalSessionStatusPathParamsSchema> {}


const findCriteriaDefinitionsQueryParamsRequiredPart = t.interface({
  bookmark: BookmarkSchema,
  associationType: AssociationTypeSchema,
});
export const FindCriteriaDefinitionsQueryParamsSchema = excess(findCriteriaDefinitionsQueryParamsRequiredPart);
export interface FindCriteriaDefinitionsQueryParams extends t.TypeOf<typeof FindCriteriaDefinitionsQueryParamsSchema> {}


const createCriteriaListQueryParamsRequiredPart = t.interface({
  bookmark: BookmarkSchema,
  associationType: AssociationTypeSchema,
});
export const CreateCriteriaListQueryParamsSchema = excess(createCriteriaListQueryParamsRequiredPart);
export interface CreateCriteriaListQueryParams extends t.TypeOf<typeof CreateCriteriaListQueryParamsSchema> {}


const deleteCriteriaListQueryParamsRequiredPart = t.interface({
  version: RowVersionSchema,
});
export const DeleteCriteriaListQueryParamsSchema = excess(deleteCriteriaListQueryParamsRequiredPart);
export interface DeleteCriteriaListQueryParams extends t.TypeOf<typeof DeleteCriteriaListQueryParamsSchema> {}


const deleteCriteriaListPathParamsRequiredPart = t.interface({
  criteriaListId: PositiveIntegerSchema,
});
export const DeleteCriteriaListPathParamsSchema = excess(deleteCriteriaListPathParamsRequiredPart);
export interface DeleteCriteriaListPathParams extends t.TypeOf<typeof DeleteCriteriaListPathParamsSchema> {}


const getAllCriteriaListsQueryParamsRequiredPart = t.interface({
  bookmark: BookmarkSchema,
  associationType: AssociationTypeSchema,
});
export const GetAllCriteriaListsQueryParamsSchema = excess(getAllCriteriaListsQueryParamsRequiredPart);
export interface GetAllCriteriaListsQueryParams extends t.TypeOf<typeof GetAllCriteriaListsQueryParamsSchema> {}


const getCriteriaListPathParamsRequiredPart = t.interface({
  criteriaListId: PositiveIntegerSchema,
});
export const GetCriteriaListPathParamsSchema = excess(getCriteriaListPathParamsRequiredPart);
export interface GetCriteriaListPathParams extends t.TypeOf<typeof GetCriteriaListPathParamsSchema> {}


const renameCriteriaListPathParamsRequiredPart = t.interface({
  criteriaListId: PositiveIntegerSchema,
});
export const RenameCriteriaListPathParamsSchema = excess(renameCriteriaListPathParamsRequiredPart);
export interface RenameCriteriaListPathParams extends t.TypeOf<typeof RenameCriteriaListPathParamsSchema> {}


const updateCriteriaListPathParamsRequiredPart = t.interface({
  criteriaListId: PositiveIntegerSchema,
});
export const UpdateCriteriaListPathParamsSchema = excess(updateCriteriaListPathParamsRequiredPart);
export interface UpdateCriteriaListPathParams extends t.TypeOf<typeof UpdateCriteriaListPathParamsSchema> {}


const validateCriteriaListQueryParamsRequiredPart = t.interface({
  bookmark: BookmarkSchema,
  associationType: AssociationTypeSchema,
});
export const ValidateCriteriaListQueryParamsSchema = excess(validateCriteriaListQueryParamsRequiredPart);
export interface ValidateCriteriaListQueryParams extends t.TypeOf<typeof ValidateCriteriaListQueryParamsSchema> {}


const getFarmsPathParamsRequiredPart = t.interface({
  breederId: PositiveIntegerSchema,
});
export const GetFarmsPathParamsSchema = excess(getFarmsPathParamsRequiredPart);
export interface GetFarmsPathParams extends t.TypeOf<typeof GetFarmsPathParamsSchema> {}


const getGridSettingsQueryParamsRequiredPart = t.interface({
  module: AppModuleSchema,
});
export const GetGridSettingsQueryParamsSchema = excess(getGridSettingsQueryParamsRequiredPart);
export interface GetGridSettingsQueryParams extends t.TypeOf<typeof GetGridSettingsQueryParamsSchema> {}


const resetUserSettingsPathParamsRequiredPart = t.interface({
  gridUserSettingsId: PositiveIntegerSchema,
});
export const ResetUserSettingsPathParamsSchema = excess(resetUserSettingsPathParamsRequiredPart);
export interface ResetUserSettingsPathParams extends t.TypeOf<typeof ResetUserSettingsPathParamsSchema> {}


const getHuidPathParamsRequiredPart = t.interface({
  earTag: EarTagSchema,
});
export const GetHuidPathParamsSchema = excess(getHuidPathParamsRequiredPart);
export interface GetHuidPathParams extends t.TypeOf<typeof GetHuidPathParamsSchema> {}


const setLegacyLanguageCookieQueryParamsRequiredPart = t.interface({
  locale: t.string,
});
export const SetLegacyLanguageCookieQueryParamsSchema = excess(setLegacyLanguageCookieQueryParamsRequiredPart);
export interface SetLegacyLanguageCookieQueryParams extends t.TypeOf<typeof SetLegacyLanguageCookieQueryParamsSchema> {}


const setLanguagePathParamsRequiredPart = t.interface({
  language: LanguageSchema,
});
export const SetLanguagePathParamsSchema = excess(setLanguagePathParamsRequiredPart);
export interface SetLanguagePathParams extends t.TypeOf<typeof SetLanguagePathParamsSchema> {}


const deleteMilkabilityReportPathParamsRequiredPart = t.interface({
  id: PositiveIntegerSchema,
});
export const DeleteMilkabilityReportPathParamsSchema = excess(deleteMilkabilityReportPathParamsRequiredPart);
export interface DeleteMilkabilityReportPathParams extends t.TypeOf<typeof DeleteMilkabilityReportPathParamsSchema> {}


const exportMilkabilityReportsQueryParamsRequiredPart = t.interface({
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.array(t.string),
});
export const ExportMilkabilityReportsQueryParamsSchema = excess(exportMilkabilityReportsQueryParamsRequiredPart);
export interface ExportMilkabilityReportsQueryParams extends t.TypeOf<typeof ExportMilkabilityReportsQueryParamsSchema> {}


const getMilkabilityReportDetailPathParamsRequiredPart = t.interface({
  id: PositiveIntegerSchema,
});
export const GetMilkabilityReportDetailPathParamsSchema = excess(getMilkabilityReportDetailPathParamsRequiredPart);
export interface GetMilkabilityReportDetailPathParams extends t.TypeOf<typeof GetMilkabilityReportDetailPathParamsSchema> {}


const getMilkabilityReportsQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const GetMilkabilityReportsQueryParamsSchema = excess(getMilkabilityReportsQueryParamsRequiredPart);
export interface GetMilkabilityReportsQueryParams extends t.TypeOf<typeof GetMilkabilityReportsQueryParamsSchema> {}


const updateMilkabilityReportPathParamsRequiredPart = t.interface({
  id: PositiveIntegerSchema,
});
export const UpdateMilkabilityReportPathParamsSchema = excess(updateMilkabilityReportPathParamsRequiredPart);
export interface UpdateMilkabilityReportPathParams extends t.TypeOf<typeof UpdateMilkabilityReportPathParamsSchema> {}


const getModuleGuideUrlsPathParamsRequiredPart = t.interface({
  appModule: AppModuleSchema,
});
export const GetModuleGuideUrlsPathParamsSchema = excess(getModuleGuideUrlsPathParamsRequiredPart);
export interface GetModuleGuideUrlsPathParams extends t.TypeOf<typeof GetModuleGuideUrlsPathParamsSchema> {}


const getOrganizationsQueryParamsRequiredPart = t.interface({
  associationType: AssociationTypeSchema,
});
export const GetOrganizationsQueryParamsSchema = excess(getOrganizationsQueryParamsRequiredPart);
export interface GetOrganizationsQueryParams extends t.TypeOf<typeof GetOrganizationsQueryParamsSchema> {}


const findLoggedInUserAssociationRolesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindLoggedInUserAssociationRolesQueryParamsSchema = excess(findLoggedInUserAssociationRolesQueryParamsRequiredPart);
export interface FindLoggedInUserAssociationRolesQueryParams extends t.TypeOf<typeof FindLoggedInUserAssociationRolesQueryParamsSchema> {}


const findLoggedInUserBreederRolesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindLoggedInUserBreederRolesQueryParamsSchema = excess(findLoggedInUserBreederRolesQueryParamsRequiredPart);
export interface FindLoggedInUserBreederRolesQueryParams extends t.TypeOf<typeof FindLoggedInUserBreederRolesQueryParamsSchema> {}


const findLoggedInUserContactsQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindLoggedInUserContactsQueryParamsSchema = excess(findLoggedInUserContactsQueryParamsRequiredPart);
export interface FindLoggedInUserContactsQueryParams extends t.TypeOf<typeof FindLoggedInUserContactsQueryParamsSchema> {}


const findLoggedInUserOrganizationRolesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindLoggedInUserOrganizationRolesQueryParamsSchema = excess(findLoggedInUserOrganizationRolesQueryParamsRequiredPart);
export interface FindLoggedInUserOrganizationRolesQueryParams extends t.TypeOf<typeof FindLoggedInUserOrganizationRolesQueryParamsSchema> {}


const findLoggedInUserOtherSubjectRolesQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindLoggedInUserOtherSubjectRolesQueryParamsSchema = excess(findLoggedInUserOtherSubjectRolesQueryParamsRequiredPart);
export interface FindLoggedInUserOtherSubjectRolesQueryParams extends t.TypeOf<typeof FindLoggedInUserOtherSubjectRolesQueryParamsSchema> {}


const findLoggedInUserSubjectMembersQueryParamsRequiredPart = t.interface({
  page: t.Integer, // Zero-based page index (0..N)
  size: t.union([t.Integer, t.null]), // The size of the page to be returned
  // Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
  sort: t.union([t.array(t.string), t.null]),
});
export const FindLoggedInUserSubjectMembersQueryParamsSchema = excess(findLoggedInUserSubjectMembersQueryParamsRequiredPart);
export interface FindLoggedInUserSubjectMembersQueryParams extends t.TypeOf<typeof FindLoggedInUserSubjectMembersQueryParamsSchema> {}



export class Api {
  // Returns a list of accordions. (/api/mating/accordions)
  public static async getAccordions(params: GetAccordionsQueryParams): Promise<Response<Accordion[]>> {
    validateSchema(GetAccordionsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/accordions`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(AccordionSchema),
      expectedStatus: 200,
    });
  }

  // Gets information about the application (version, deploy date etc.). (/api/admin/app-info)
  public static async getApplicationInfo(): Promise<Response<AppInfo>> {
    return apiRequester.makeRequest({
      url: `/api/admin/app-info`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AppInfoSchema,
      expectedStatus: 200,
    });
  }

  // Creates permission. (/api/auth/permissions)
  public static async createPermission(data: CreatePermission): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/permissions`,
      method: 'post',
      data,
      requestSchema: CreatePermissionSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Deletes permission. (/api/auth/permissions/{permissionId})
  public static async deletePermission(pathParams: DeletePermissionPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeletePermissionPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/permissions/${pathParams.permissionId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Returns page of permissions. (/api/auth/permissions/find)
  public static async findPermissions(data: TableFilter[], params: FindPermissionsQueryParams): Promise<Response<PagedModelPermissionDetail>> {
    validateSchema(FindPermissionsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/permissions/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelPermissionDetailSchema,
      expectedStatus: 200,
    });
  }

  // Updates permission. (/api/auth/permissions/{permissionId})
  public static async updatePermission(data: UpdatePermission, pathParams: UpdatePermissionPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdatePermissionPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/permissions/${pathParams.permissionId}`,
      method: 'put',
      data,
      requestSchema: UpdatePermissionSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates permission. (/api/auth/permissions/validation)
  public static async validatePermission(data: ValidatePermission): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/permissions/validation`,
      method: 'post',
      data,
      requestSchema: ValidatePermissionSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Assign permission to a role. (/api/auth/roles/{roleId}/permissions/{permissionId})
  public static async addPermissionToRole(pathParams: AddPermissionToRolePathParams): Promise<Response<EmptyString>> {
    validateSchema(AddPermissionToRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/permissions/${pathParams.permissionId}`,
      method: 'put',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Assign subordinate role to a role. (/api/auth/roles/{roleId}/subordinates/{subordinateRoleId})
  public static async addSubordinateRoleToRole(pathParams: AddSubordinateRoleToRolePathParams): Promise<Response<EmptyString>> {
    validateSchema(AddSubordinateRoleToRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/subordinates/${pathParams.subordinateRoleId}`,
      method: 'put',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates role. (/api/auth/roles)
  public static async createRole(data: CreateOrUpdateRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/roles`,
      method: 'post',
      data,
      requestSchema: CreateOrUpdateRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Deletes Role. (/api/auth/roles/{roleId})
  public static async deleteRole(pathParams: DeleteRolePathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Gets all permissions assigned to the role into the standard grid. (/api/auth/roles/{roleId}/permissions/assigned/find)
  public static async getPermissionsAssignedToRole(data: TableFilter[], params: GetPermissionsAssignedToRoleQueryParams, pathParams: GetPermissionsAssignedToRolePathParams): Promise<Response<PagedModelRolePermission>> {
    validateSchema(GetPermissionsAssignedToRoleQueryParamsSchema, 'FE')(params);
    validateSchema(GetPermissionsAssignedToRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/permissions/assigned/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelRolePermissionSchema,
      expectedStatus: 200,
    });
  }

  // Gets all permissions unassigned to the role into the standard grid. (/api/auth/roles/{roleId}/permissions/unassigned/find)
  public static async getPermissionsUnassignedToRole(data: TableFilter[], params: GetPermissionsUnassignedToRoleQueryParams, pathParams: GetPermissionsUnassignedToRolePathParams): Promise<Response<PagedModelRolePermission>> {
    validateSchema(GetPermissionsUnassignedToRoleQueryParamsSchema, 'FE')(params);
    validateSchema(GetPermissionsUnassignedToRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/permissions/unassigned/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelRolePermissionSchema,
      expectedStatus: 200,
    });
  }

  // Returns detail of role. (/api/auth/roles/{roleId})
  public static async getRoleDetail(pathParams: GetRoleDetailPathParams): Promise<Response<RoleDetail>> {
    validateSchema(GetRoleDetailPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: RoleDetailSchema,
      expectedStatus: 200,
    });
  }

  // Returns page of roles. (/api/auth/roles/find)
  public static async getRolesPage(data: TableFilter[], params: GetRolesPageQueryParams): Promise<Response<PagedModelRoleView>> {
    validateSchema(GetRolesPageQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelRoleViewSchema,
      expectedStatus: 200,
    });
  }

  // Retrieves the roles unassigned to a specific role. (/api/auth/roles/{roleId}/subordinates/unassigned/find)
  public static async getRolesUnassignedToRole(data: TableFilter[], params: GetRolesUnassignedToRoleQueryParams, pathParams: GetRolesUnassignedToRolePathParams): Promise<Response<PagedModelBasicRole>> {
    validateSchema(GetRolesUnassignedToRoleQueryParamsSchema, 'FE')(params);
    validateSchema(GetRolesUnassignedToRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/subordinates/unassigned/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelBasicRoleSchema,
      expectedStatus: 200,
    });
  }

  // Retrieves the roles assigned to a specific role. (/api/auth/roles/{roleId}/subordinates/assigned/find)
  public static async getSubordinateRoles(data: TableFilter[], params: GetSubordinateRolesQueryParams, pathParams: GetSubordinateRolesPathParams): Promise<Response<PagedModelBasicRole>> {
    validateSchema(GetSubordinateRolesQueryParamsSchema, 'FE')(params);
    validateSchema(GetSubordinateRolesPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/subordinates/assigned/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelBasicRoleSchema,
      expectedStatus: 200,
    });
  }

  // Remove a permission from a role. (/api/auth/roles/{roleId}/permissions/{permissionId})
  public static async removePermissionFromRole(pathParams: RemovePermissionFromRolePathParams): Promise<Response<EmptyString>> {
    validateSchema(RemovePermissionFromRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/permissions/${pathParams.permissionId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Remove subordinate role from a role. (/api/auth/roles/{roleId}/subordinates/{subordinateRoleId})
  public static async removeSubordinateRoleFromRole(pathParams: RemoveSubordinateRoleFromRolePathParams): Promise<Response<EmptyString>> {
    validateSchema(RemoveSubordinateRoleFromRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}/subordinates/${pathParams.subordinateRoleId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates role. (/api/auth/roles/{roleId})
  public static async updateRole(data: CreateOrUpdateRole, pathParams: UpdateRolePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateRolePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/roles/${pathParams.roleId}`,
      method: 'put',
      data,
      requestSchema: CreateOrUpdateRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates role. (/api/auth/roles/validation)
  public static async validateRole(data: ValidateRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/roles/validation`,
      method: 'post',
      data,
      requestSchema: ValidateRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Returns detail of a user profile. (/api/auth/user-profiles/{userProfileId})
  public static async getUserProfileV2(pathParams: GetUserProfileV2PathParams): Promise<Response<UserDetail>> {
    validateSchema(GetUserProfileV2PathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: UserDetailSchema,
      expectedStatus: 200,
    });
  }

  // Returns page of users. (/api/auth/user-profiles/find)
  public static async getUsersPageV2(data: TableFilter[], params: GetUsersPageV2QueryParams): Promise<Response<PagedModelUserView>> {
    validateSchema(GetUsersPageV2QueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelUserViewSchema,
      expectedStatus: 200,
    });
  }

  // Adds 1-N bindings between user profile, association and role. (/api/auth/association-roles)
  public static async addUserProfileAssociationRoleBinding(data: CreateUserProfileAssociationRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/association-roles`,
      method: 'post',
      data,
      requestSchema: CreateUserProfileAssociationRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Returns a page of user associations. (/api/auth/user-profiles/{userProfileId}/association-roles/find)
  public static async getUserAssociationsPage(data: TableFilter[], params: GetUserAssociationsPageQueryParams, pathParams: GetUserAssociationsPagePathParams): Promise<Response<PagedModelAssociationRole>> {
    validateSchema(GetUserAssociationsPageQueryParamsSchema, 'FE')(params);
    validateSchema(GetUserAssociationsPagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}/association-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelAssociationRoleSchema,
      expectedStatus: 200,
    });
  }

  // Returns a list of association roles based on the given search text. (/api/auth/association-roles/search)
  public static async searchAssociationRoles(params: SearchAssociationRolesQueryParams): Promise<Response<BasicRole[]>> {
    validateSchema(SearchAssociationRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/association-roles/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(BasicRoleSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of associations based on the given search text. (/api/auth/associations/search)
  public static async searchAssociations(params: SearchAssociationsQueryParams): Promise<Response<Association[]>> {
    validateSchema(SearchAssociationsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/associations/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(AssociationSchema),
      expectedStatus: 200,
    });
  }

  // Updates 1-N bindings between user profile, association and role. (/api/auth/association-roles)
  public static async updateUserProfileAssociationRoleBinding(data: UpdateUserProfileAssociationRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/association-roles`,
      method: 'put',
      data,
      requestSchema: UpdateUserProfileAssociationRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates 1-N bindings between user profile, association and role. (/api/auth/association-roles/validation)
  public static async validateUserProfileAssociationRoleBinding(data: ValidateAssociationRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/association-roles/validation`,
      method: 'post',
      data,
      requestSchema: ValidateAssociationRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Adds 1-N bindings between user profile, breeder and role (/api/auth/breeder-roles)
  public static async addUserProfileBreederRoleBindings(data: CreateUserProfileBreederRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/breeder-roles`,
      method: 'post',
      data,
      requestSchema: CreateUserProfileBreederRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Returns a list of farms, belonging to the provided breeder. (/api/auth/breeders/{breederId}/farms)
  public static async findAllFarmsByBreederId(pathParams: FindAllFarmsByBreederIdPathParams): Promise<Response<Farm[]>> {
    validateSchema(FindAllFarmsByBreederIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/breeders/${pathParams.breederId}/farms`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(FarmSchema),
      expectedStatus: 200,
    });
  }

  // Returns a page of user profile, breeder, farm and role bindings. (/api/auth/user-profiles/{userProfileId}/breeder-roles/find)
  public static async getUserBreedersPage(data: TableFilter[], params: GetUserBreedersPageQueryParams, pathParams: GetUserBreedersPagePathParams): Promise<Response<PagedModelBreederRole>> {
    validateSchema(GetUserBreedersPageQueryParamsSchema, 'FE')(params);
    validateSchema(GetUserBreedersPagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}/breeder-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelBreederRoleSchema,
      expectedStatus: 200,
    });
  }

  // Returns a list of breeder roles based on the given search text. (/api/auth/breeder-roles/search)
  public static async searchBreederRoles(params: SearchBreederRolesQueryParams): Promise<Response<BasicRole[]>> {
    validateSchema(SearchBreederRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/breeder-roles/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(BasicRoleSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of breeders based on the given search text. (/api/auth/breeders/search)
  public static async searchBreeders(params: SearchBreedersQueryParams): Promise<Response<Breeder[]>> {
    validateSchema(SearchBreedersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/breeders/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(BreederSchema),
      expectedStatus: 200,
    });
  }

  // Updates 1-N bindings between user profile, breeder and role (/api/auth/breeder-roles)
  public static async updateUserProfileBreederRoleBindings(data: UpdateUserProfileBreederRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/breeder-roles`,
      method: 'put',
      data,
      requestSchema: UpdateUserProfileBreederRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates binding between user profile, breeder and role. (/api/auth/breeder-roles/validation)
  public static async validateUserProfileBreederRoleBinding(data: ValidateBreederRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/breeder-roles/validation`,
      method: 'post',
      data,
      requestSchema: ValidateBreederRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates a user contact. (/api/auth/user-contacts)
  public static async createUserContact(data: NewUserProfileContact): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/user-contacts`,
      method: 'post',
      data,
      requestSchema: NewUserProfileContactSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Deletes user contact. (/api/auth/user-contacts/{userContactId})
  public static async deleteUserContact(pathParams: DeleteUserContactPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteUserContactPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-contacts/${pathParams.userContactId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Returns a page of user contacts. (/api/auth/user-profiles/{userProfileId}/user-contacts/find)
  public static async getUserContactsPage(data: TableFilter[], params: GetUserContactsPageQueryParams, pathParams: GetUserContactsPagePathParams): Promise<Response<PagedModelUserProfileContact>> {
    validateSchema(GetUserContactsPageQueryParamsSchema, 'FE')(params);
    validateSchema(GetUserContactsPagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}/user-contacts/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelUserProfileContactSchema,
      expectedStatus: 200,
    });
  }

  // Updates user contact. (/api/auth/user-contacts/{userContactId})
  public static async updateUserContact(data: UpdateUserContact, pathParams: UpdateUserContactPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateUserContactPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-contacts/${pathParams.userContactId}`,
      method: 'put',
      data,
      requestSchema: UpdateUserContactSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates if binding between user profile and contact does not exist. (/api/auth/user-contacts/validation)
  public static async validateUserProfileContact(data: NewUserProfileContact): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/user-contacts/validation`,
      method: 'post',
      data,
      requestSchema: NewUserProfileContactSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Adds 1-N bindings between user profile, organization and role (/api/auth/organization-roles)
  public static async addUserProfileOrganizationRoleBindings(data: CreateUserProfileOrganizationRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/organization-roles`,
      method: 'post',
      data,
      requestSchema: CreateUserProfileOrganizationRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Returns a list of organization centers belonging to the provided organization. (/api/auth/organizations/{organizationId}/organization-centers)
  public static async findAllCentersByOrganizationId(pathParams: FindAllCentersByOrganizationIdPathParams): Promise<Response<OrganizationCenter[]>> {
    validateSchema(FindAllCentersByOrganizationIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/organizations/${pathParams.organizationId}/organization-centers`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(OrganizationCenterSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of farms in which the organization performs insemination or proficiency tests. (/api/auth/organizations/{organizationId}/farms)
  public static async findAllFarmsByOrganizationId(pathParams: FindAllFarmsByOrganizationIdPathParams): Promise<Response<Farm[]>> {
    validateSchema(FindAllFarmsByOrganizationIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/organizations/${pathParams.organizationId}/farms`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(FarmSchema),
      expectedStatus: 200,
    });
  }

  // Returns a page of user profile, organization, organization center and role bindings. (/api/auth/user-profiles/{userProfileId}/organization-roles/find)
  public static async getUserOrganizationPage(data: TableFilter[], params: GetUserOrganizationPageQueryParams, pathParams: GetUserOrganizationPagePathParams): Promise<Response<PagedModelOrganizationRole>> {
    validateSchema(GetUserOrganizationPageQueryParamsSchema, 'FE')(params);
    validateSchema(GetUserOrganizationPagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}/organization-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelOrganizationRoleSchema,
      expectedStatus: 200,
    });
  }

  // Returns a list of organization roles based on the given search text. (/api/auth/organization-roles/search)
  public static async searchOrganizationRoles(params: SearchOrganizationRolesQueryParams): Promise<Response<BasicRole[]>> {
    validateSchema(SearchOrganizationRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/organization-roles/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(BasicRoleSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of organizations based on the given search text. (/api/auth/organizations/search)
  public static async searchOrganizations(params: SearchOrganizationsQueryParams): Promise<Response<Organization[]>> {
    validateSchema(SearchOrganizationsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/organizations/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OrganizationSchema),
      expectedStatus: 200,
    });
  }

  // Updates 1-N bindings between user profile, organization and role (/api/auth/organization-roles)
  public static async updateUserProfileOrganizationRoleBindings(data: UpdateUserProfileOrganizationRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/organization-roles`,
      method: 'put',
      data,
      requestSchema: UpdateUserProfileOrganizationRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates binding between user profile, organization and role. (/api/auth/organization-roles/validation)
  public static async validateUserProfileOrganizationRoleBinding(data: ValidateOrganizationRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/organization-roles/validation`,
      method: 'post',
      data,
      requestSchema: ValidateOrganizationRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Adds a binding between user profile, other subject and role (/api/auth/other-subject-roles)
  public static async addUserProfileOtherSubjectRoleBinding(data: CreateUserProfileOtherSubjectRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/other-subject-roles`,
      method: 'post',
      data,
      requestSchema: CreateUserProfileOtherSubjectRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Returns a page of other subjects for the user. (/api/auth/user-profiles/{userProfileId}/other-subject-roles/find)
  public static async getUserOtherSubjectPage(data: TableFilter[], params: GetUserOtherSubjectPageQueryParams, pathParams: GetUserOtherSubjectPagePathParams): Promise<Response<PagedModelOtherSubjectRole>> {
    validateSchema(GetUserOtherSubjectPageQueryParamsSchema, 'FE')(params);
    validateSchema(GetUserOtherSubjectPagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}/other-subject-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelOtherSubjectRoleSchema,
      expectedStatus: 200,
    });
  }

  // Returns a list of other subject roles based on the given search text. (/api/auth/other-subject-roles/search)
  public static async searchOtherSubjectRoles(params: SearchOtherSubjectRolesQueryParams): Promise<Response<BasicRole[]>> {
    validateSchema(SearchOtherSubjectRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/other-subject-roles/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(BasicRoleSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of other subjects based on the given search text. (/api/auth/other-subjects/search)
  public static async searchOtherSubjects(params: SearchOtherSubjectsQueryParams): Promise<Response<OtherSubject[]>> {
    validateSchema(SearchOtherSubjectsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/other-subjects/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OtherSubjectSchema),
      expectedStatus: 200,
    });
  }

  // Updates 1-N bindings between user profile, other subject and role (/api/auth/other-subject-roles)
  public static async updateUserProfileOtherSubjectRoleBindings(data: UpdateUserProfileOtherSubjectRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/other-subject-roles`,
      method: 'put',
      data,
      requestSchema: UpdateUserProfileOtherSubjectRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates binding between user profile, other subject and role. (/api/auth/other-subject-roles/validation)
  public static async validateUserProfileOtherSubjectRoleBinding(data: ValidateOtherSubjectRole): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/other-subject-roles/validation`,
      method: 'post',
      data,
      requestSchema: ValidateOtherSubjectRoleSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Assign user to a subject. (/api/auth/subject-members)
  public static async assignUserToSubject(data: CreateSubjectMember): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/subject-members`,
      method: 'post',
      data,
      requestSchema: CreateSubjectMemberSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Returns page of subjects for user. (/api/auth/user-profiles/{userProfileId}/subject-members/find)
  public static async getSubjectMembersPage(data: TableFilter[], params: GetSubjectMembersPageQueryParams, pathParams: GetSubjectMembersPagePathParams): Promise<Response<PagedModelUserSubjectView>> {
    validateSchema(GetSubjectMembersPageQueryParamsSchema, 'FE')(params);
    validateSchema(GetSubjectMembersPagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/user-profiles/${pathParams.userProfileId}/subject-members/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelUserSubjectViewSchema,
      expectedStatus: 200,
    });
  }

  // Returns a list of subjects based on the given search text. (/api/auth/subjects/search)
  public static async searchSubjects(params: SearchSubjectsQueryParams): Promise<Response<Subject[]>> {
    validateSchema(SearchSubjectsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/auth/subjects/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(SubjectSchema),
      expectedStatus: 200,
    });
  }

  // Updates the validity of the user as a member of the subject. (/api/auth/subject-members/{subjectMemberId})
  public static async updateSubjectMemberApproval(data: UpdateSubjectMember, pathParams: UpdateSubjectMemberApprovalPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateSubjectMemberApprovalPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/auth/subject-members/${pathParams.subjectMemberId}`,
      method: 'put',
      data,
      requestSchema: UpdateSubjectMemberSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates subject member. (/api/auth/subject-members/validation)
  public static async validateSubjectMember(data: ValidateSubjectMember): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/auth/subject-members/validation`,
      method: 'post',
      data,
      requestSchema: ValidateSubjectMemberSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Create correction report. (/api/reports/apcs)
  public static async createCorrectionReport(data: CreateApc): Promise<Response<CorrectionReportDetail>> {
    return apiRequester.makeRequest({
      url: `/api/reports/apcs`,
      method: 'post',
      data,
      requestSchema: CreateApcSchema,
      responseSchema: CorrectionReportDetailSchema,
      expectedStatus: 201,
    });
  }

  // Delete correction report. (/api/reports/apcs/{apcId})
  public static async deleteCorrectionReport(pathParams: DeleteCorrectionReportPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteCorrectionReportPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/${pathParams.apcId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Edit correction report. (/api/reports/apcs/{apcId})
  public static async editCorrectionReport(data: EditApc, pathParams: EditCorrectionReportPathParams): Promise<Response<CorrectionReportDetail>> {
    validateSchema(EditCorrectionReportPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/${pathParams.apcId}`,
      method: 'put',
      data,
      requestSchema: EditApcSchema,
      responseSchema: CorrectionReportDetailSchema,
      expectedStatus: 200,
    });
  }

  // Exports analysis protocol correction reports. (/api/reports/apcs/export)
  public static async exportCorrectionReports(data: CsvExportParams, params: ExportCorrectionReportsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportCorrectionReportsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets analysis protocol by ear tag and proficiency test date. (/api/reports/apcs/analysis-protocols)
  public static async getAnalysisProtocol(params: GetAnalysisProtocolQueryParams): Promise<Response<AnalysisProtocol>> {
    validateSchema(GetAnalysisProtocolQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/analysis-protocols`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: AnalysisProtocolSchema,
      expectedStatus: 200,
    });
  }

  // Gets analysis protocol correction report. (/api/reports/apcs/{apcId})
  public static async getCorrectionReport(pathParams: GetCorrectionReportPathParams): Promise<Response<CorrectionReportDetail>> {
    validateSchema(GetCorrectionReportPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/${pathParams.apcId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: CorrectionReportDetailSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of analysis protocol correction reports. (/api/reports/apcs/find)
  public static async getCorrectionReports(data: TableFilter[], params: GetCorrectionReportsQueryParams): Promise<Response<PagedModelCorrectionReport>> {
    validateSchema(GetCorrectionReportsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelCorrectionReportSchema,
      expectedStatus: 200,
    });
  }

  // Gets stable - proficiency test date relation data. (/api/reports/apcs/analysis-protocols/stable-date-relations/alternative)
  public static async getStableDateRelation(params: GetStableDateRelationQueryParams): Promise<Response<StableDateRelationMilking>> {
    validateSchema(GetStableDateRelationQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/analysis-protocols/stable-date-relations/alternative`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: StableDateRelationMilkingSchema,
      expectedStatus: 200,
    });
  }

  // Gets stables and proficiency test dates based on given earTag. (/api/reports/apcs/analysis-protocols/stable-date-relations)
  public static async getStableDateRelations(params: GetStableDateRelationsQueryParams): Promise<Response<ApcStableDate>> {
    validateSchema(GetStableDateRelationsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/analysis-protocols/stable-date-relations`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: ApcStableDateSchema,
      expectedStatus: 200,
    });
  }

  // Validates correction data. (/api/reports/apcs/validation)
  public static async validateCorrectionData(data: ValidateApc): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/reports/apcs/validation`,
      method: 'post',
      data,
      requestSchema: ValidateApcSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates an animal list from provided ids. (/api/animal-lists)
  public static async createAnimalListFromIds(data: CreateAnimalListFromIds): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/animal-lists`,
      method: 'post',
      data,
      requestSchema: CreateAnimalListFromIdsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates an animal list. (/api/animal-lists/session)
  public static async createAnimalListFromSession(data: CreateAnimalList): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/animal-lists/session`,
      method: 'post',
      data,
      requestSchema: CreateAnimalListSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Deletes the animal list with the given id. (/api/animal-lists/{animalListId})
  public static async deleteAnimalList(params: DeleteAnimalListQueryParams, pathParams: DeleteAnimalListPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteAnimalListQueryParamsSchema, 'FE')(params);
    validateSchema(DeleteAnimalListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animal-lists/${pathParams.animalListId}`,
      method: 'delete',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds details about animal lists. (/api/animal-lists)
  public static async getAnimalLists(params: GetAnimalListsQueryParams): Promise<Response<AnimalList[]>> {
    validateSchema(GetAnimalListsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/animal-lists`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(AnimalListSchema),
      expectedStatus: 200,
    });
  }

  // Renames the animal list with the given id. (/api/animal-lists/{animalListId})
  public static async renameAnimalList(data: RenameAnimalList, pathParams: RenameAnimalListPathParams): Promise<Response<EmptyString>> {
    validateSchema(RenameAnimalListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animal-lists/${pathParams.animalListId}`,
      method: 'patch',
      data,
      requestSchema: RenameAnimalListSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates an animal list with provided ids. (/api/animal-lists/{animalListId})
  public static async updateAnimalListFromIds(params: UpdateAnimalListFromIdsQueryParams, pathParams: UpdateAnimalListFromIdsPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateAnimalListFromIdsQueryParamsSchema, 'FE')(params);
    validateSchema(UpdateAnimalListFromIdsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animal-lists/${pathParams.animalListId}`,
      method: 'put',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates an animal list. (/api/animal-lists/{animalListId}/session)
  public static async updateAnimalListFromSession(params: UpdateAnimalListFromSessionQueryParams, pathParams: UpdateAnimalListFromSessionPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateAnimalListFromSessionQueryParamsSchema, 'FE')(params);
    validateSchema(UpdateAnimalListFromSessionPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animal-lists/${pathParams.animalListId}/session`,
      method: 'put',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates the name of the animal list. (/api/animal-lists/validation)
  public static async validateAnimalList(data: AnimalListValidation): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/animal-lists/validation`,
      method: 'post',
      data,
      requestSchema: AnimalListValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Adds animals to the user's session. (/api/mating/animals/{sex}/{associationType})
  public static async addAnimalsToList(params: AddAnimalsToListQueryParams, pathParams: AddAnimalsToListPathParams): Promise<Response<EmptyString>> {
    validateSchema(AddAnimalsToListQueryParamsSchema, 'FE')(params);
    validateSchema(AddAnimalsToListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}`,
      method: 'patch',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Return csv file with animals (/api/mating/animals/{sex}/{associationType}/export)
  public static async exportAnimals(data: CsvExportParams, params: ExportAnimalsQueryParams, pathParams: ExportAnimalsPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportAnimalsQueryParamsSchema, 'FE')(params);
    validateSchema(ExportAnimalsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports animals to CSV file for Mating module. (/api/mating/animals/{sex}/{associationType}/csv)
  public static async exportAnimalsInList(params: ExportAnimalsInListQueryParams, pathParams: ExportAnimalsInListPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportAnimalsInListQueryParamsSchema, 'FE')(params);
    validateSchema(ExportAnimalsInListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/csv`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Saves the filters to the user's session. (/api/mating/animals/{sex}/{associationType}/filter)
  public static async filterAnimalsInList(data: CriteriaFilters, pathParams: FilterAnimalsInListPathParams): Promise<Response<AnimalInSession>> {
    validateSchema(FilterAnimalsInListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/filter`,
      method: 'post',
      data,
      requestSchema: CriteriaFiltersSchema,
      responseSchema: AnimalInSessionSchema,
      expectedStatus: 200,
    });
  }

  // Returns searchable labels of animals that can be added by the current user into the list. (/api/mating/animals/{sex}/{associationType}/available)
  public static async getAvailableAnimals(params: GetAvailableAnimalsQueryParams, pathParams: GetAvailableAnimalsPathParams): Promise<Response<AvailableAnimal[]>> {
    validateSchema(GetAvailableAnimalsQueryParamsSchema, 'FE')(params);
    validateSchema(GetAvailableAnimalsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/available`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(AvailableAnimalSchema),
      expectedStatus: 200,
    });
  }

  // Returns a page of female holstein animals for Mating module. (/api/mating/animals/female/holstein/find)
  public static async getFemaleHolsteinAnimalsInList(data: TableFilter[], params: GetFemaleHolsteinAnimalsInListQueryParams): Promise<Response<PagedModelFemaleHolsteinAnimalInList>> {
    validateSchema(GetFemaleHolsteinAnimalsInListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/female/holstein/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelFemaleHolsteinAnimalInListSchema,
      expectedStatus: 200,
    });
  }

  // Returns a page of male fleckvieh animals for Mating module. (/api/mating/animals/male/fleckvieh/find)
  public static async getMaleFleckviehAnimalsInList(data: TableFilter[], params: GetMaleFleckviehAnimalsInListQueryParams): Promise<Response<PagedModelMaleFleckviehAnimalInList>> {
    validateSchema(GetMaleFleckviehAnimalsInListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/male/fleckvieh/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelMaleFleckviehAnimalInListSchema,
      expectedStatus: 200,
    });
  }

  // Returns a page of male holstein animals for Mating module. (/api/mating/animals/male/holstein/find)
  public static async getMaleHolsteinAnimalsInList(data: TableFilter[], params: GetMaleHolsteinAnimalsInListQueryParams): Promise<Response<PagedModelMaleHolsteinAnimalInList>> {
    validateSchema(GetMaleHolsteinAnimalsInListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/male/holstein/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelMaleHolsteinAnimalInListSchema,
      expectedStatus: 200,
    });
  }

  // Finds whether the session filters match any animals. (/api/mating/animals/{sex}/{associationType}/session)
  public static async getMatingAnimalSessionStatus(pathParams: GetMatingAnimalSessionStatusPathParams): Promise<Response<AnimalInSession>> {
    validateSchema(GetMatingAnimalSessionStatusPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/session`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AnimalInSessionSchema,
      expectedStatus: 200,
    });
  }

  // Imports animals from CSV file for Mating module. (/api/mating/animals/{sex}/{associationType}/csv)
  public static async importAnimalsInList(data: File, pathParams: ImportAnimalsInListPathParams): Promise<Response<CsvImportResult>> {
    validateSchema(ImportAnimalsInListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/csv`,
      method: 'patch',
      data: fileToFormData(data),
      requestSchema: FormDataSchema,
      responseSchema: CsvImportResultSchema,
      expectedStatus: 200,
    });
  }

  // Adds animals from the animal list with given id to the animal list in the user's session. (/api/mating/animals/animal-lists/{animalListId})
  public static async importAnimalsInListFromDb(params: ImportAnimalsInListFromDbQueryParams, pathParams: ImportAnimalsInListFromDbPathParams): Promise<Response<EmptyString>> {
    validateSchema(ImportAnimalsInListFromDbQueryParamsSchema, 'FE')(params);
    validateSchema(ImportAnimalsInListFromDbPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/animal-lists/${pathParams.animalListId}`,
      method: 'patch',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Removes animal from the animals list in session. (/api/mating/animals/{sex}/{associationType}/{animalId})
  public static async removeAnimalFromList(pathParams: RemoveAnimalFromListPathParams): Promise<Response<EmptyString>> {
    validateSchema(RemoveAnimalFromListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}/${pathParams.animalId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Removes all animals with the given sex and association type from the animal list in the user's session. (/api/mating/animals/{sex}/{associationType})
  public static async resetList(pathParams: ResetListPathParams): Promise<Response<EmptyString>> {
    validateSchema(ResetListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/animals/${pathParams.sex}/${pathParams.associationType}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports breeding females to a CSV file. (/api/animals-browser/stables/{stableId}/breeding-females/export)
  public static async exportBreedingFemales(data: CsvExportParams, params: ExportBreedingFemalesQueryParams, pathParams: ExportBreedingFemalesPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportBreedingFemalesQueryParamsSchema, 'FE')(params);
    validateSchema(ExportBreedingFemalesPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/stables/${pathParams.stableId}/breeding-females/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of breeding females. (/api/animals-browser/stables/{stableId}/breeding-females)
  public static async getBreedingFemales(data: TableFilter[], params: GetBreedingFemalesQueryParams, pathParams: GetBreedingFemalesPathParams): Promise<Response<PagedModelBreedingFemale>> {
    validateSchema(GetBreedingFemalesQueryParamsSchema, 'FE')(params);
    validateSchema(GetBreedingFemalesPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/stables/${pathParams.stableId}/breeding-females`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelBreedingFemaleSchema,
      expectedStatus: 200,
    });
  }

  // Exports calves belonging to a farm to a CSV file. (/api/animals-browser/farms/{farmId}/calves/export)
  public static async exportCalvesByFarm(data: CsvExportParams, params: ExportCalvesByFarmQueryParams, pathParams: ExportCalvesByFarmPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportCalvesByFarmQueryParamsSchema, 'FE')(params);
    validateSchema(ExportCalvesByFarmPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/farms/${pathParams.farmId}/calves/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets information about the farm. (/api/animals-browser/farms/{farmId})
  public static async getAnimalsBrowserFarm(pathParams: GetAnimalsBrowserFarmPathParams): Promise<Response<AnimalsBrowserFarm>> {
    validateSchema(GetAnimalsBrowserFarmPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/farms/${pathParams.farmId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AnimalsBrowserFarmSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of calves belonging to a farm. (/api/animals-browser/farms/{farmId}/calves)
  public static async getCalvesByFarm(data: TableFilter[], params: GetCalvesByFarmQueryParams, pathParams: GetCalvesByFarmPathParams): Promise<Response<PagedModelCalf>> {
    validateSchema(GetCalvesByFarmQueryParamsSchema, 'FE')(params);
    validateSchema(GetCalvesByFarmPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/farms/${pathParams.farmId}/calves`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelCalfSchema,
      expectedStatus: 200,
    });
  }

  // Export stables. (/api/animals-browser/stables/export)
  public static async exportStables(data: CsvExportParams, params: ExportStablesQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportStablesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/stables/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets information about the stable. (/api/animals-browser/stables/{stableId})
  public static async getAnimalsBrowserStable(pathParams: GetAnimalsBrowserStablePathParams): Promise<Response<AnimalsBrowserStable>> {
    validateSchema(GetAnimalsBrowserStablePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/stables/${pathParams.stableId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AnimalsBrowserStableSchema,
      expectedStatus: 200,
    });
  }

  // Returns a page of stables available to select by the logged in user in the Animals browser module. (/api/animals-browser/stables)
  public static async getStablesToChoose(data: TableFilter[], params: GetStablesToChooseQueryParams): Promise<Response<PagedModelStableToChoose>> {
    validateSchema(GetStablesToChooseQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/animals-browser/stables`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelStableToChooseSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about all breeders associated with the logged in user to select in the Mating module. (/api/mating/breeders)
  public static async findAllToChoose(): Promise<Response<BreederToChoose[]>> {
    return apiRequester.makeRequest({
      url: `/api/mating/breeders`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(BreederToChooseSchema),
      expectedStatus: 200,
    });
  }

  // Gets breeders list for dropdown autocompletion filtered by breeder registration number or its assigned subject business name. (/api/cab/breeders/search)
  public static async getCabBreedersAutocompleteList(params: GetCabBreedersAutocompleteListQueryParams): Promise<Response<CabBreederAutocomplete[]>> {
    validateSchema(GetCabBreedersAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/breeders/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(CabBreederAutocompleteSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered districts list for dropdown autocompletion. (/api/cab/districts/search)
  public static async getCabDistrictsAutocompleteList(params: GetCabDistrictsAutocompleteListQueryParams): Promise<Response<CabDistrictAutocomplete[]>> {
    validateSchema(GetCabDistrictsAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/districts/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(CabDistrictAutocompleteSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered farms list for dropdown autocompletion. (/api/cab/farms/search)
  public static async getCabFarmsAutocompleteList(params: GetCabFarmsAutocompleteListQueryParams): Promise<Response<CabFarmAutocomplete[]>> {
    validateSchema(GetCabFarmsAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/farms/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(CabFarmAutocompleteSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered organization centers list with insemination maintenance for dropdown autocompletion. (/api/cab/organization-centers/insemination)
  public static async getCabOrganizationCentersWithInseminationMaintenanceAutocompleteList(params: GetCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParams): Promise<Response<OrganizationCenterAutocomplete[]>> {
    validateSchema(GetCabOrganizationCentersWithInseminationMaintenanceAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/organization-centers/insemination`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OrganizationCenterAutocompleteSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered organization centers list with proficiency test maintenance for dropdown autocompletion. (/api/cab/organization-centers/proficiency-tests)
  public static async getCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteList(params: GetCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParams): Promise<Response<OrganizationCenterAutocomplete[]>> {
    validateSchema(GetCabOrganizationCentersWithProficiencyTestsMaintenanceAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/organization-centers/proficiency-tests`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OrganizationCenterAutocompleteSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered organizations list with insemination maintenance for dropdown autocompletion. (/api/cab/organizations/insemination)
  public static async getCabOrganizationsWithInseminationMaintenanceAutocompleteList(params: GetCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParams): Promise<Response<Organization[]>> {
    validateSchema(GetCabOrganizationsWithInseminationMaintenanceAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/organizations/insemination`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OrganizationSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered organizations list with proficiency test maintenance for dropdown autocompletion. (/api/cab/organizations/proficiency-tests)
  public static async getCabOrganizationsWithProficiencyTestMaintenanceAutocompleteList(params: GetCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParams): Promise<Response<Organization[]>> {
    validateSchema(GetCabOrganizationsWithProficiencyTestMaintenanceAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/organizations/proficiency-tests`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OrganizationSchema),
      expectedStatus: 200,
    });
  }

  // Gets filtered stable list for dropdown autocompletion. (/api/cab/stables/search)
  public static async getCabStableAutocompleteList(params: GetCabStableAutocompleteListQueryParams): Promise<Response<CabStableAutocomplete[]>> {
    validateSchema(GetCabStableAutocompleteListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/stables/search`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(CabStableAutocompleteSchema),
      expectedStatus: 200,
    });
  }

  // Adds animals to the user's session. (/api/cab/{animalType})
  public static async cabAddAnimalsToList(params: CabAddAnimalsToListQueryParams, pathParams: CabAddAnimalsToListPathParams): Promise<Response<EmptyString>> {
    validateSchema(CabAddAnimalsToListQueryParamsSchema, 'FE')(params);
    validateSchema(CabAddAnimalsToListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}`,
      method: 'patch',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Returns list of available animals that can be added by the current user into the list. (/api/cab/{animalType}/available)
  public static async cabGetAvailableAnimals(params: CabGetAvailableAnimalsQueryParams, pathParams: CabGetAvailableAnimalsPathParams): Promise<Response<AvailableAnimal[]>> {
    validateSchema(CabGetAvailableAnimalsQueryParamsSchema, 'FE')(params);
    validateSchema(CabGetAvailableAnimalsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}/available`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(AvailableAnimalSchema),
      expectedStatus: 200,
    });
  }

  // Imports the provided animal list from database to user session. (/api/cab/{animalType}/animal-lists/{animalListId})
  public static async cabImportAnimalListToUserSession(pathParams: CabImportAnimalListToUserSessionPathParams): Promise<Response<EmptyString>> {
    validateSchema(CabImportAnimalListToUserSessionPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}/animal-lists/${pathParams.animalListId}`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Imports animals from CSV file and adds them to session. (/api/cab/{animalType}/csv)
  public static async cabImportAnimalsFromCsv(data: File, pathParams: CabImportAnimalsFromCsvPathParams): Promise<Response<CsvImportResult>> {
    validateSchema(CabImportAnimalsFromCsvPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}/csv`,
      method: 'patch',
      data: fileToFormData(data),
      requestSchema: FormDataSchema,
      responseSchema: CsvImportResultSchema,
      expectedStatus: 200,
    });
  }

  // Deletes all the animals saved in user session. (/api/cab/{animalType})
  public static async deleteCabAnimalsInSession(pathParams: DeleteCabAnimalsInSessionPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteCabAnimalsInSessionPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports animals from session. (/api/cab/{animalType}/export)
  public static async exportCabAnimals(data: CsvExportParams, params: ExportCabAnimalsQueryParams, pathParams: ExportCabAnimalsPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportCabAnimalsQueryParamsSchema, 'FE')(params);
    validateSchema(ExportCabAnimalsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Saves the breeding female filters to the user's session. (/api/cab/breeding-females/filter)
  public static async filterCabBreedingFemales(data: BreedingFemaleFilter): Promise<Response<AnimalInSession>> {
    return apiRequester.makeRequest({
      url: `/api/cab/breeding-females/filter`,
      method: 'post',
      data,
      requestSchema: BreedingFemaleFilterSchema,
      responseSchema: AnimalInSessionSchema,
      expectedStatus: 200,
    });
  }

  // Saves the calf filters to the user's session. (/api/cab/calves/filter)
  public static async filterCabCalves(data: CalfFilter): Promise<Response<AnimalInSession>> {
    return apiRequester.makeRequest({
      url: `/api/cab/calves/filter`,
      method: 'post',
      data,
      requestSchema: CalfFilterSchema,
      responseSchema: AnimalInSessionSchema,
      expectedStatus: 200,
    });
  }

  // Gets breeding females from session. (/api/cab/breeding-females/find)
  public static async findCabBreedingFemales(data: TableFilter[], params: FindCabBreedingFemalesQueryParams): Promise<Response<PagedModelCabBreedingFemale>> {
    validateSchema(FindCabBreedingFemalesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/breeding-females/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelCabBreedingFemaleSchema,
      expectedStatus: 200,
    });
  }

  // Gets calves from session. (/api/cab/calves/find)
  public static async findCabCalves(data: TableFilter[], params: FindCabCalvesQueryParams): Promise<Response<PagedModelCabCalf>> {
    validateSchema(FindCabCalvesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/cab/calves/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelCabCalfSchema,
      expectedStatus: 200,
    });
  }

  // Finds whether the session filters match any animals. (/api/cab/{animalType}/session)
  public static async getCabAnimalSessionStatus(pathParams: GetCabAnimalSessionStatusPathParams): Promise<Response<AnimalInSession>> {
    validateSchema(GetCabAnimalSessionStatusPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/cab/${pathParams.animalType}/session`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AnimalInSessionSchema,
      expectedStatus: 200,
    });
  }

  // Returns all the criteria definitions for the provided bookmark. (/api/mating/criteria-definitions)
  public static async findCriteriaDefinitions(params: FindCriteriaDefinitionsQueryParams): Promise<Response<CriteriaDefinition[]>> {
    validateSchema(FindCriteriaDefinitionsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-definitions`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(CriteriaDefinitionSchema),
      expectedStatus: 200,
    });
  }

  // Creates a new criteria list. (/api/mating/criteria-lists)
  public static async createCriteriaList(data: CreateCriteriaList, params: CreateCriteriaListQueryParams): Promise<Response<EmptyString>> {
    validateSchema(CreateCriteriaListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists`,
      method: 'post',
      params: params,
      data,
      requestSchema: CreateCriteriaListSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Deletes the criteria list with the given id. (/api/mating/criteria-lists/{criteriaListId})
  public static async deleteCriteriaList(params: DeleteCriteriaListQueryParams, pathParams: DeleteCriteriaListPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteCriteriaListQueryParamsSchema, 'FE')(params);
    validateSchema(DeleteCriteriaListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists/${pathParams.criteriaListId}`,
      method: 'delete',
      params: params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all criteria lists matching given filtering criteria. (/api/mating/criteria-lists)
  public static async getAllCriteriaLists(params: GetAllCriteriaListsQueryParams): Promise<Response<CriteriaList[]>> {
    validateSchema(GetAllCriteriaListsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(CriteriaListSchema),
      expectedStatus: 200,
    });
  }

  // Returns the criteria list with the given id. (/api/mating/criteria-lists/{criteriaListId})
  public static async getCriteriaList(pathParams: GetCriteriaListPathParams): Promise<Response<CriteriaListDetails>> {
    validateSchema(GetCriteriaListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists/${pathParams.criteriaListId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: CriteriaListDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Renames the criteria list with the given id. (/api/mating/criteria-lists/{criteriaListId})
  public static async renameCriteriaList(data: RenameCriteriaList, pathParams: RenameCriteriaListPathParams): Promise<Response<EmptyString>> {
    validateSchema(RenameCriteriaListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists/${pathParams.criteriaListId}`,
      method: 'patch',
      data,
      requestSchema: RenameCriteriaListSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates criteria list. (/api/mating/criteria-lists/{criteriaListId})
  public static async updateCriteriaList(data: UpdateCriteriaList, pathParams: UpdateCriteriaListPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateCriteriaListPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists/${pathParams.criteriaListId}`,
      method: 'put',
      data,
      requestSchema: UpdateCriteriaListSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Validates the name of the criteria list. (/api/mating/criteria-lists/validation)
  public static async validateCriteriaList(data: CriteriaListValidation, params: ValidateCriteriaListQueryParams): Promise<Response<EmptyString>> {
    validateSchema(ValidateCriteriaListQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/criteria-lists/validation`,
      method: 'post',
      params: params,
      data,
      requestSchema: CriteriaListValidationSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all districts sorted by name (ascending). (/api/districts)
  public static async getDistricts(): Promise<Response<District[]>> {
    return apiRequester.makeRequest({
      url: `/api/districts`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(DistrictSchema),
      expectedStatus: 200,
    });
  }

  // Finds details about all farms of the given breeder to select in the Mating module. (/api/mating/breeders/{breederId}/farms)
  public static async getFarms(pathParams: GetFarmsPathParams): Promise<Response<FarmToChoose[]>> {
    validateSchema(GetFarmsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/mating/breeders/${pathParams.breederId}/farms`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(FarmToChooseSchema),
      expectedStatus: 200,
    });
  }

  // Creates (or updates existing) grid user settings. (/api/grid-user-settings)
  public static async createOrUpdateUserSettings(data: CreateOrUpdateGridUserSettings): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/grid-user-settings`,
      method: 'post',
      data,
      requestSchema: CreateOrUpdateGridUserSettingsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds grid settings for the given module and user. (/api/grid-user-settings)
  public static async getGridSettings(params: GetGridSettingsQueryParams): Promise<Response<ModuleGridSettings[]>> {
    validateSchema(GetGridSettingsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/grid-user-settings`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(ModuleGridSettingsSchema),
      expectedStatus: 200,
    });
  }

  // Resets the grid user's settings of a specific component of the eSkot application. (/api/grid-user-settings/{gridUserSettingsId})
  public static async resetUserSettings(pathParams: ResetUserSettingsPathParams): Promise<Response<EmptyString>> {
    validateSchema(ResetUserSettingsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/grid-user-settings/${pathParams.gridUserSettingsId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Calculates HUID for the ear tag. (/api/huid/{earTag})
  public static async getHuid(pathParams: GetHuidPathParams): Promise<Response<HuidWrapper>> {
    validateSchema(GetHuidPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/huid/${pathParams.earTag}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: HuidWrapperSchema,
      expectedStatus: 200,
    });
  }

  // User Login (/public/api/jwt/login)
  public static async login(data: LoginRequest): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/public/api/jwt/login`,
      method: 'post',
      data,
      requestSchema: LoginRequestSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Logs out the logged-in user. (/api/jwt/logout)
  public static async logout(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/jwt/logout`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Refreshes the access token. (/public/api/jwt/refresh)
  public static async refreshToken(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/public/api/jwt/refresh`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  public static async getLegacyAppInfo(): Promise<Response<LegacyBeAppInfo>> {
    return legacyApiRequester.makeRequest({
      url: `/Admin/JGetApplicationInfo`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: LegacyBeAppInfoSchema,
      expectedStatus: 200,
    });
  }

  public static async logoutLegacy(): Promise<Response<string>> {
    return legacyApiRequester.makeRequest({
      url: `/Account/JLogout`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.string,
      expectedStatus: 200,
    });
  }

  public static async setLegacyLanguageCookie(params: SetLegacyLanguageCookieQueryParams): Promise<Response<string>> {
    validateSchema(SetLegacyLanguageCookieQueryParamsSchema, 'FE')(params);
    return legacyApiRequester.makeRequest({
      url: `/Home/SetLocale`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.string,
      expectedStatus: 204,
    });
  }

  public static async userIsLoggedLegacy(): Promise<Response<EmptyString>> {
    return legacyApiRequester.makeRequest({
      url: `/Account/JLogged`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 200,
    });
  }

  // Gets basic information and permissions for the logged-in user extracted from JWT. (/api/user-profiles/principal)
  public static async getPrincipal(): Promise<Response<Principal>> {
    return apiRequester.makeRequest({
      url: `/api/user-profiles/principal`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: PrincipalSchema,
      expectedStatus: 200,
    });
  }

  // Sets the language localisation for currently logged-in user. (/api/user-profiles/principal/languages/{language})
  public static async setLanguage(pathParams: SetLanguagePathParams): Promise<Response<EmptyString>> {
    validateSchema(SetLanguagePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/principal/languages/${pathParams.language}`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Computes the mating score for animals in the user's session. (/api/mating/scoring)
  public static async getMatingScore(data: TraitsWeights): Promise<Response<MatingResult>> {
    return apiRequester.makeRequest({
      url: `/api/mating/scoring`,
      method: 'post',
      data,
      requestSchema: TraitsWeightsSchema,
      responseSchema: MatingResultSchema,
      expectedStatus: 200,
    });
  }

  // Calculates APMV. (/api/reports/milkability/apmv)
  public static async calculateApmv(data: CalculateApmv): Promise<Response<ApmvResponse>> {
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/apmv`,
      method: 'post',
      data,
      requestSchema: CalculateApmvSchema,
      responseSchema: ApmvResponseSchema,
      expectedStatus: 200,
    });
  }

  // Creates milkability report. (/api/reports/milkability)
  public static async createMilkabilityReport(data: CreateMilkability): Promise<Response<MilkabilityDetail>> {
    return apiRequester.makeRequest({
      url: `/api/reports/milkability`,
      method: 'post',
      data,
      requestSchema: CreateMilkabilitySchema,
      responseSchema: MilkabilityDetailSchema,
      expectedStatus: 201,
    });
  }

  // Delete milkability report. (/api/reports/milkability/{id})
  public static async deleteMilkabilityReport(pathParams: DeleteMilkabilityReportPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteMilkabilityReportPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/${pathParams.id}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Export milkability reports. (/api/reports/milkability/export)
  public static async exportMilkabilityReports(data: CsvExportParams, params: ExportMilkabilityReportsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportMilkabilityReportsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/export`,
      method: 'post',
      params: params,
      data,
      requestSchema: CsvExportParamsSchema,
      responseType: 'arraybuffer',
      timeout: 180000,
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets information about the milkability report. (/api/reports/milkability/{id})
  public static async getMilkabilityReportDetail(pathParams: GetMilkabilityReportDetailPathParams): Promise<Response<MilkabilityDetail>> {
    validateSchema(GetMilkabilityReportDetailPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/${pathParams.id}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: MilkabilityDetailSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of milkability reports. (/api/reports/milkability/find)
  public static async getMilkabilityReports(data: TableFilter[], params: GetMilkabilityReportsQueryParams): Promise<Response<PagedModelMilkability>> {
    validateSchema(GetMilkabilityReportsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelMilkabilitySchema,
      expectedStatus: 200,
    });
  }

  // Updates milkability report. (/api/reports/milkability/{id})
  public static async updateMilkabilityReport(data: UpdateMilkability, pathParams: UpdateMilkabilityReportPathParams): Promise<Response<MilkabilityDetail>> {
    validateSchema(UpdateMilkabilityReportPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/${pathParams.id}`,
      method: 'put',
      data,
      requestSchema: UpdateMilkabilitySchema,
      responseSchema: MilkabilityDetailSchema,
      expectedStatus: 200,
    });
  }

  // Validates milkability data. (/api/reports/milkability/validation)
  public static async validateMilkabilityData(data: ValidateMilkabilityReport): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/api/reports/milkability/validation`,
      method: 'post',
      data,
      requestSchema: ValidateMilkabilityReportSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds the module guide URLs. (/api/module-guides/{appModule})
  public static async getModuleGuideUrls(pathParams: GetModuleGuideUrlsPathParams): Promise<Response<ModuleGuideUrls>> {
    validateSchema(GetModuleGuideUrlsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/api/module-guides/${pathParams.appModule}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: ModuleGuideUrlsSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about all organizations to select in the Mating module. (/api/mating/organizations)
  public static async getOrganizations(params: GetOrganizationsQueryParams): Promise<Response<OrganizationToChoose[]>> {
    validateSchema(GetOrganizationsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/mating/organizations`,
      method: 'get',
      params: params,
      requestSchema: t.void,
      responseSchema: t.array(OrganizationToChooseSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of payment codes ordered by name ASC. (/api/payment-codes)
  public static async getPaymentCodes(): Promise<Response<PaymentCode[]>> {
    return apiRequester.makeRequest({
      url: `/api/payment-codes`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(PaymentCodeSchema),
      expectedStatus: 200,
    });
  }

  // Returns all permissions. (/api/permissions)
  public static async getAllPermissions(): Promise<Response<Permission[]>> {
    return apiRequester.makeRequest({
      url: `/api/permissions`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(PermissionSchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of all production areas. (/api/production-areas)
  public static async getProductionAreas(): Promise<Response<ProductionArea[]>> {
    return apiRequester.makeRequest({
      url: `/api/production-areas`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(ProductionAreaSchema),
      expectedStatus: 200,
    });
  }

  // Finds all production areas in central evidence. (/api/production-areas/central-evidences)
  public static async getProductionAreasInCentralEvidence(): Promise<Response<ProductionAreaInCE[]>> {
    return apiRequester.makeRequest({
      url: `/api/production-areas/central-evidences`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(ProductionAreaInCESchema),
      expectedStatus: 200,
    });
  }

  // Returns a list of all user roles. (/api/roles)
  public static async getRoles(): Promise<Response<RoleV1[]>> {
    return apiRequester.makeRequest({
      url: `/api/roles`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(RoleV1Schema),
      expectedStatus: 200,
    });
  }

  // Finds association roles for the logged-in user. (/api/user-profiles/association-roles/find)
  public static async findLoggedInUserAssociationRoles(data: TableFilter[], params: FindLoggedInUserAssociationRolesQueryParams): Promise<Response<PagedModelAssociationRole>> {
    validateSchema(FindLoggedInUserAssociationRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/association-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelAssociationRoleSchema,
      expectedStatus: 200,
    });
  }

  // Finds breeder roles for the logged-in user. (/api/user-profiles/breeder-roles/find)
  public static async findLoggedInUserBreederRoles(data: TableFilter[], params: FindLoggedInUserBreederRolesQueryParams): Promise<Response<PagedModelBreederRole>> {
    validateSchema(FindLoggedInUserBreederRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/breeder-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelBreederRoleSchema,
      expectedStatus: 200,
    });
  }

  // Finds contacts for the logged-in user. (/api/user-profiles/user-contacts/find)
  public static async findLoggedInUserContacts(data: TableFilter[], params: FindLoggedInUserContactsQueryParams): Promise<Response<PagedModelUserProfileContact>> {
    validateSchema(FindLoggedInUserContactsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/user-contacts/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelUserProfileContactSchema,
      expectedStatus: 200,
    });
  }

  // Finds organization roles for the logged-in user. (/api/user-profiles/organization-roles/find)
  public static async findLoggedInUserOrganizationRoles(data: TableFilter[], params: FindLoggedInUserOrganizationRolesQueryParams): Promise<Response<PagedModelOrganizationRole>> {
    validateSchema(FindLoggedInUserOrganizationRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/organization-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelOrganizationRoleSchema,
      expectedStatus: 200,
    });
  }

  // Finds other subject roles for the logged-in user. (/api/user-profiles/other-subject-roles/find)
  public static async findLoggedInUserOtherSubjectRoles(data: TableFilter[], params: FindLoggedInUserOtherSubjectRolesQueryParams): Promise<Response<PagedModelOtherSubjectRole>> {
    validateSchema(FindLoggedInUserOtherSubjectRolesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/other-subject-roles/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelOtherSubjectRoleSchema,
      expectedStatus: 200,
    });
  }

  // Finds subject members for the logged-in user. (/api/user-profiles/subject-members/find)
  public static async findLoggedInUserSubjectMembers(data: TableFilter[], params: FindLoggedInUserSubjectMembersQueryParams): Promise<Response<PagedModelUserSubjectView>> {
    validateSchema(FindLoggedInUserSubjectMembersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/api/user-profiles/subject-members/find`,
      method: 'post',
      params: params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagedModelUserSubjectViewSchema,
      expectedStatus: 200,
    });
  }

  // Gets details about the logged-in user. (/api/user-profiles)
  public static async getLoggedInUserProfile(): Promise<Response<UserProfile>> {
    return apiRequester.makeRequest({
      url: `/api/user-profiles`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: UserProfileSchema,
      expectedStatus: 200,
    });
  }

// custom endpoints

  public static async getFEVersion(): Promise<Response<string>> {
    return staticRequester.makeRequest({
      url: '/version.txt',
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.string,
      expectedStatus: 200,
    });
  }

}
