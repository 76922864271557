import { classNames } from '@fl/cmsch-fe-library';
import React, { CSSProperties, memo } from 'react';

// eslint-disable-next-line no-magic-numbers
type BootstrapSpacing = 0 | 1 | 2 | 3 | 4 | 5;
interface Props {
  children?: React.ReactNode;
  ml?: BootstrapSpacing;
  mr?: BootstrapSpacing;
  mt?: BootstrapSpacing;
  mb?: BootstrapSpacing;
  maxWidth?: number | string;
  marginCenter?: boolean;
}
const LayoutDivBase: React.FC<Props> = props => {
  const {
    children,
    ml,
    mr,
    mt,
    mb,
    maxWidth,
    marginCenter
  } = props;
  const className = classNames(ml !== undefined && `ml-${ml}`, mr !== undefined && `mr-${mr}`, mt !== undefined && `mt-${mt}`, mb !== undefined && `mb-${mb}`, marginCenter && 'm-auto');
  const style: CSSProperties = {
    maxWidth
  };
  return <div className={className}
  // eslint-disable-next-line react/forbid-dom-props
  style={style} data-sentry-component="LayoutDivBase" data-sentry-source-file="index.tsx">
            {children}
        </div>;
};
export const LayoutDiv = memo(LayoutDivBase);