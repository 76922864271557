// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: BETWEEN

export const OperatorSchema = t.keyof({
  BETWEEN: null,
  IS: null,
  LESS_THAN: null,
  PERCENTAGE: null,
  TOP: null,
  WEIGHT: null,
});

export type Operator = t.TypeOf<typeof OperatorSchema>;
