// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridSettings, GridSettingsSchema } from 'api/gen/GridSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridSettingsName, GridSettingsNameSchema } from 'api/gen/GridSettingsName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  gridSettings: GridSettingsSchema,
  id: PositiveIntegerSchema,
  isDefault: t.boolean,
  name: GridSettingsNameSchema,
});

export const GridSettingsOptionSchema = excess(requiredPart);

export interface GridSettingsOption extends t.TypeOf<typeof GridSettingsOptionSchema> {}
