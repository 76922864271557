// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectCode, SubjectCodeSchema } from 'api/gen/SubjectCode';

const requiredPart = t.interface({
  businessName: SubjectBusinessNameSchema,
  code: SubjectCodeSchema,
  id: PositiveIntegerSchema,
});

export const SubjectSchema = excess(requiredPart);

export interface Subject extends t.TypeOf<typeof SubjectSchema> {}
