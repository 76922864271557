// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-layout-components-space-___styles__lastToRight___xYRZR div:last-child{margin-left:auto}
`, "",{"version":3,"sources":["webpack://./src/common/layout/components/space/styles.sass"],"names":[],"mappings":"AAAA,kFAA4B,gBAAA","sourcesContent":[".lastToRight div:last-child{margin-left:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lastToRight": `src-common-layout-components-space-___styles__lastToRight___xYRZR`
};
export default ___CSS_LOADER_EXPORT___;
