// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ControlYear, ControlYearSchema } from 'api/gen/ControlYear';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkedTotalRange, MilkedTotalRangeSchema } from 'api/gen/MilkedTotalRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingTimeRange, MilkingTimeRangeSchema } from 'api/gen/MilkingTimeRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TechnicianNumber, TechnicianNumberSchema } from 'api/gen/TechnicianNumber';

const requiredPart = t.interface({
  examinationDate: ControlYearSchema,
  milkedTotal: MilkedTotalRangeSchema,
  milkingTime: MilkingTimeRangeSchema,
  technicianNumber: TechnicianNumberSchema,
});

export const UpdateMilkabilitySchema = excess(requiredPart);

export interface UpdateMilkability extends t.TypeOf<typeof UpdateMilkabilitySchema> {}
