// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Username, UsernameSchema } from 'api/gen/Username';

const requiredPart = t.interface({
  password: t.string,
  username: UsernameSchema,
});

export const LoginRequestSchema = excess(requiredPart);

export interface LoginRequest extends t.TypeOf<typeof LoginRequestSchema> {}
