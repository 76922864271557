import {fold} from 'fp-ts/Either';
import {pipe} from 'fp-ts/function';

import {Language, LanguageSchema} from 'api/gen/Language';
import {i18n} from 'app/translations/i18n';

import {isLocalStorageAvailable} from './is-local-storage-available';

const languageLocalStorageName = 'i18nextLng';

/**
 * Language localStorage stores current language setting across sessions without the need for login.
 * Is source of truth during app initialization and for 'Accept-Language' header in communication with our BE.
 * Should be synchronized with 'locale_' cookie set on Legacy BE.
 */
class LanguageStore {
    public get(): Language | undefined {
        if (!isLocalStorageAvailable()) return undefined;

        const cookieValue = localStorage.getItem(languageLocalStorageName);

        return pipe(
            LanguageSchema.decode(cookieValue),
            fold(
                () => undefined,
                language => language,
            ),
        );
    }

    public set(value: Language): void {
        i18n.changeLanguage(value)
            .catch((err => {
                // eslint-disable-next-line no-console
                console.log(err);
            }));
    }
}

export const languageStore = new LanguageStore();
