// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaValuesBase, CriteriaValuesBaseSchema } from 'api/gen/CriteriaValuesBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52, Decimal52Schema } from 'api/gen/Decimal52';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_5_2'),
  leftValue: t.union([Decimal52Schema, t.null]),
  rightValue: t.union([Decimal52Schema, t.null]),
});

export const Decimal52CriteriaValuesSchema = excess(t.intersection([CriteriaValuesBaseSchema.type, requiredPart]));

export interface Decimal52CriteriaValues extends t.TypeOf<typeof Decimal52CriteriaValuesSchema> {}
