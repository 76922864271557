// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A technician number
// Example: "326"

export const technicianNumberRegexGen = (): RegExp => new RegExp("^\\d{3}$");

export const TechnicianNumberSchema = StringPatternSchema<TechnicianNumber>(technicianNumberRegexGen());

export type TechnicianNumber = string;
