// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// HUID - encoded data containing breeder registration number, IP address and a timestamp.
// Example: "3F6FAB49B50D5B17B2B191990A59053307BE4141D21050E663F3D5CA8F647A9135"

export const huidRegexGen = (): RegExp => new RegExp("^[a-fA-F0-9]{66}$");

export const HuidSchema = StringPatternSchema<Huid>(huidRegexGen());

export type Huid = string;
