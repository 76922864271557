import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppModule } from 'api/gen/AppModule';
import { useOurTranslation } from 'app/translations';
import { IconName, ICONS } from 'common/icons';
import { Space } from 'common/layout/components/space';
import { layoutAction, simpleLayoutSelector } from 'common/layout/model';
import styles from './styles.sass';
interface Props {
  title: string;
  icon?: IconName;
  moduleForTutorials?: AppModule;
}
const ScreenHeaderBase: FC<Props> = ({
  icon,
  title,
  moduleForTutorials
}) => {
  const dispatch = useDispatch();
  const tutorials = useSelector(simpleLayoutSelector.getTutorialsUrls).orUndef();
  const {
    tCommon
  } = useOurTranslation('common');
  useEffect(() => {
    if (moduleForTutorials) dispatch(layoutAction.getTutorials(moduleForTutorials));
    return () => {
      dispatch(layoutAction.getTutorialsSuccess(undefined));
    };
  }, [moduleForTutorials, dispatch]);
  return <Space align="center" fullWidth data-sentry-element="Space" data-sentry-component="ScreenHeaderBase" data-sentry-source-file="index.tsx">
            {icon && <span className="d-none d-sm-flex">{ICONS[icon]}</span>}
            <span data-test-id="screenTitle">{title}</span>

            <div className={styles.tutorialsWrapper}>
                {tutorials?.videoUrl && <a href={tutorials.videoUrl} className={styles.tutorialIcon} target="_blank" rel="noopener noreferrer" title={tCommon('videoTutorial')} data-test-id="video-tutorial">
                        {ICONS.videoCameraOutlined}
                    </a>}
                {tutorials?.pdfUrl && <a href={tutorials.pdfUrl} className={styles.tutorialIcon} target="_blank" rel="noopener noreferrer" title={tCommon('pdfTutorial')} data-test-id="pdf-tutorial">
                        {ICONS.fileTextOutlined}
                    </a>}
            </div>
        </Space>;
};
export const ScreenHeader = memo(ScreenHeaderBase);