// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionAction, PermissionActionSchema } from 'api/gen/PermissionAction';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionComment, PermissionCommentSchema } from 'api/gen/PermissionComment';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PermissionController, PermissionControllerSchema } from 'api/gen/PermissionController';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  action: PermissionActionSchema,
  comment: PermissionCommentSchema,
  controller: PermissionControllerSchema,
  id: PositiveIntegerSchema,
});

export const PermissionSchema = excess(requiredPart);

export interface Permission extends t.TypeOf<typeof PermissionSchema> {}
