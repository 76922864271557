import {beDateFormat, KeysOfType} from '@fl/cmsch-fe-library';
import {isNumber, isString} from 'lodash/fp';
import moment from 'moment';
import {opt, optEmptyString} from 'ts-opt';

import {ApmvRangeSchema} from 'api/gen/ApmvRange';
import {isDateWithinTwoControlYears} from 'app/milkability/utils/is-date-within-two-control-years';
import {t} from 'app/translations';
import {Validator} from 'utils/validator';

export class EskotValidator<Values> extends Validator<Values> {
    public apmv(fieldName: KeysOfType<Values, number | undefined | null>): void {
        const value = this.values[fieldName];
        if (opt(value).isEmpty) return;
        if (!isNumber(value)) {
            throw new Error(`Field ${String(fieldName)} is not a number.`);
        }
        if (!ApmvRangeSchema.is(value)) {
            this.setErrorForField(fieldName, t('common/validator')('apmv'));
        }
    }

    public examinationDate(fieldName: keyof Values): void {
        const value = this.values[fieldName];
        if (optEmptyString(value).isEmpty) return;
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate its date validity.`);
        }
        const momentValue = moment(value, beDateFormat, true);
        if (!momentValue.isValid()) {
            throw new Error(`Field ${String(fieldName)} is not a valid date.`);
        }
        if (!isDateWithinTwoControlYears(momentValue, moment())) {
            this.setErrorForField(fieldName, t('common/validator')('examinationDate'));
        }
        if (momentValue.isAfter(moment())) {
            this.setErrorForField(fieldName, t('common/validator')('examinationDateFuture'));
        }
    }
}
