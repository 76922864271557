// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingCount, MilkingCountSchema } from 'api/gen/MilkingCount';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingDayTime, MilkingDayTimeSchema } from 'api/gen/MilkingDayTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingInterval, MilkingIntervalSchema } from 'api/gen/MilkingInterval';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal52, PositiveOrZeroDecimal52Schema } from 'api/gen/PositiveOrZeroDecimal52';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal80, PositiveOrZeroDecimal80Schema } from 'api/gen/PositiveOrZeroDecimal80';

const requiredPart = t.interface({
  fatPercentage: PositiveOrZeroDecimal52Schema,
  lactosePercentage: PositiveOrZeroDecimal52Schema,
  milkKg: PositiveOrZeroDecimal52Schema,
  milkingCount: t.union([MilkingCountSchema, t.null]),
  milkingDayTime: t.union([MilkingDayTimeSchema, t.null]),
  milkingInterval: t.union([MilkingIntervalSchema, t.null]),
  proteinPercentage: PositiveOrZeroDecimal52Schema,
  somaticCells: PositiveOrZeroDecimal80Schema,
  urea: PositiveOrZeroDecimal52Schema,
});

export const AnalysisProtocolSchema = excess(requiredPart);

export interface AnalysisProtocol extends t.TypeOf<typeof AnalysisProtocolSchema> {}
