// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The three digits (or less) long code of organization.
// Example: "101"

export const organizationCodeRegexGen = (): RegExp => new RegExp("^[0-9]{1,3}$");

export const OrganizationCodeSchema = StringPatternSchema<OrganizationCode>(organizationCodeRegexGen());

export type OrganizationCode = string;
