// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of the breeder.
// Example: "Českomoravská společnost chovatelů, a.s."

export const breederNameMinLength = 1;
export const breederNameMaxLength = 256;

export const BreederNameSchema = t.refinement(t.string, n => n.length >= breederNameMinLength && n.length <= breederNameMaxLength, 'BreederName');

export type BreederName = string;
