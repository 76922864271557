// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ListName, ListNameSchema } from 'api/gen/ListName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  criteriaListId: t.union([PositiveIntegerSchema, t.null]),
  gridSettingsId: t.union([PositiveIntegerSchema, t.null]),
  gridUserSettingsId: t.union([PositiveIntegerSchema, t.null]),
  name: ListNameSchema,
});

export const CriteriaListValidationSchema = excess(requiredPart);

export interface CriteriaListValidation extends t.TypeOf<typeof CriteriaListValidationSchema> {}
