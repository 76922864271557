// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaFilterBase, CriteriaFilterBaseSchema } from 'api/gen/CriteriaFilterBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43, Decimal43Schema } from 'api/gen/Decimal43';

const requiredPart = t.interface({
  tag: t.literal('DECIMAL_4_3'),
  leftValue: t.union([Decimal43Schema, t.null]),
  rightValue: t.union([Decimal43Schema, t.null]),
});

export const CriteriaDecimal43FilterSchema = excess(t.intersection([CriteriaFilterBaseSchema.type, requiredPart]));

export interface CriteriaDecimal43Filter extends t.TypeOf<typeof CriteriaDecimal43FilterSchema> {}
