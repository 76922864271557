// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ScoringTraitWeight, ScoringTraitWeightSchema } from 'api/gen/ScoringTraitWeight';

const requiredPart = t.interface({
  clinicalMastitisWeight: ScoringTraitWeightSchema,
  limbDiseaseWeight: ScoringTraitWeightSchema,
  somaticCellsWeight: ScoringTraitWeightSchema,
});

export const HealthTraitsWeightsSchema = excess(requiredPart);

export interface HealthTraitsWeights extends t.TypeOf<typeof HealthTraitsWeightsSchema> {}
