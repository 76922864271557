// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmName, FarmNameSchema } from 'api/gen/FarmName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';

const requiredPart = t.interface({
  farmCode: FarmCodeSchema,
  farmId: PositiveIntegerSchema,
  farmName: t.union([FarmNameSchema, t.null]),
  rowId: RowIdSchema,
});

export const FarmRowSchema = excess(requiredPart);

export interface FarmRow extends t.TypeOf<typeof FarmRowSchema> {}
