// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Regular expression that specifies the safe characters that can be used within strings that are used to generate file names.
// Example: "export.csv"

export const safeCharactersRegexGen = (): RegExp => new RegExp("^[\\w\\!\\-\\.\\*\\'\\(\\)]+$");

export const SafeCharactersSchema = StringPatternSchema<SafeCharacters>(safeCharactersRegexGen());

export type SafeCharacters = string;
