// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ColumnSettings, ColumnSettingsSchema } from 'api/gen/ColumnSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridSettingsName, GridSettingsNameSchema } from 'api/gen/GridSettingsName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SortSettings, SortSettingsSchema } from 'api/gen/SortSettings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFilter, TableFilterSchema } from 'api/gen/TableFilter';

const requiredPart = t.interface({
  columnSettings: t.array(ColumnSettingsSchema),
  filters: t.union([t.array(TableFilterSchema), t.null]),
  name: GridSettingsNameSchema,
  pageSize: PositiveIntegerSchema,
  sort: t.union([t.array(SortSettingsSchema), t.null]),
});

export const GridSettingsSchema = excess(requiredPart);

export interface GridSettings extends t.TypeOf<typeof GridSettingsSchema> {}
