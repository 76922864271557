// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The controller name of the permission.
// Example: "Admin"

export const permissionControllerNameV2MinLength = 1;
export const permissionControllerNameV2MaxLength = 128;

export const PermissionControllerNameV2Schema = t.refinement(t.string, n => n.length >= permissionControllerNameV2MinLength && n.length <= permissionControllerNameV2MaxLength, 'PermissionControllerNameV2');

export type PermissionControllerNameV2 = string;
