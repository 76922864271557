import {excess} from '@fl/cmsch-fe-library';
import * as t from 'io-ts';

const requiredPart = t.interface({
    necessary: t.boolean,
    preferential: t.boolean,
    statistical: t.boolean,
});

export const CookieApprovalSchema = excess(requiredPart);

export type CookieApproval = t.TypeOf<typeof CookieApprovalSchema>;
