// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleView, RoleViewSchema } from 'api/gen/RoleView';

const requiredPart = t.interface({
  content: t.union([t.array(RoleViewSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelRoleViewSchema = excess(requiredPart);

export interface PagedModelRoleView extends t.TypeOf<typeof PagedModelRoleViewSchema> {}
