// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AppModule, AppModuleSchema } from 'api/gen/AppModule';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GridCode, GridCodeSchema } from 'api/gen/GridCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ListName, ListNameSchema } from 'api/gen/ListName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  animalIds: t.array(PositiveIntegerSchema),
  code: GridCodeSchema,
  module: AppModuleSchema,
  name: ListNameSchema,
});

export const CreateAnimalListFromIdsSchema = excess(requiredPart);

export interface CreateAnimalListFromIds extends t.TypeOf<typeof CreateAnimalListFromIdsSchema> {}
