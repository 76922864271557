// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateCreateAssociationRole, ValidateCreateAssociationRoleSchema } from 'api/gen/ValidateCreateAssociationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateUpdateAssociationRole, ValidateUpdateAssociationRoleSchema } from 'api/gen/ValidateUpdateAssociationRole';

export const ValidateAssociationRoleSchema = t.union([ValidateCreateAssociationRoleSchema, ValidateUpdateAssociationRoleSchema]);

export type ValidateAssociationRole = t.TypeOf<typeof ValidateAssociationRoleSchema>;
