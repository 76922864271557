// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(5, 2).
// Example: 5.0e-2

export const decimal52Minimum = -1000.0; // exclusive
export const decimal52Maximum = 1000.0; // exclusive
export const decimal52MinimumInclusive = -999.99;
export const decimal52MaximumInclusive = 999.99;
export const decimal52MinMaxInclusive: [number | null, number | null] = [-999.99, 999.99];
export const decimal52DecimalPlaces = 2;
export const decimal52RegexGen = new RegExp("^-?\\d{1,3}[,.]\\d{2}$");

export const Decimal52Schema = t.refinement(t.number, x => x > decimal52Minimum && x < decimal52Maximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'Decimal52');

export type Decimal52 = t.TypeOf<typeof Decimal52Schema>;
