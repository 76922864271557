// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: ADMIN

export const AppModuleSchema = t.keyof({
  ADMIN: null,
  ANIMALS_BROWSER: null,
  ANIMALS_BROWSER_ALL: null,
  MATING_H: null,
  MATING_C: null,
  MILKABILITY: null,
  APC: null,
  BULLSELECTOR_H: null,
  BULLSELECTOR_C: null,
  USER_PROFILE: null,
});

export type AppModule = t.TypeOf<typeof AppModuleSchema>;
