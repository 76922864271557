// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Huid, HuidSchema } from 'api/gen/Huid';

const requiredPart = t.interface({
  huid: t.union([HuidSchema, t.null]),
});

export const HuidWrapperSchema = excess(requiredPart);

export interface HuidWrapper extends t.TypeOf<typeof HuidWrapperSchema> {}
