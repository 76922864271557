import { classNames, Modal } from '@fl/cmsch-fe-library';
import { debounce } from 'lodash/fp';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { opt } from 'ts-opt';
import { gutter } from 'app/consts';
import { handleGoogleAnalyticsScript } from 'app/gtag';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { Button } from 'common/buttons';
import { layoutAction } from 'common/layout/model';
import { cookieApprovalStore } from 'common/layout/utils/cookie-approval-store';
import { isLocalStorageAvailable } from 'utils/is-local-storage-available';
import { Switch } from './switch';
import styles from './styles.sass';
interface Props {
  visible: boolean;
  setVisible(visible: boolean): void;
}
const waitTime = 700;
const CookieModalBase: FC<Props> = ({
  visible,
  setVisible
}) => {
  const {
    t
  } = useOurTranslation('layout/cookieModal');
  const dispatch = useDispatch();
  const hideModal = useCallback(() => setVisible(false), [setVisible]);
  const handleStatisticalCookie = useCallback((isEnabled: boolean) => {
    handleGoogleAnalyticsScript(isEnabled);
    dispatch(layoutAction.setStatisticalCookie(isEnabled));
  }, [dispatch]);
  const acceptAll = useCallback(() => {
    hideModal();
    cookieApprovalStore.acceptAll();
    handleStatisticalCookie(true);
  }, [hideModal, handleStatisticalCookie]);
  const denyAll = useCallback(() => {
    hideModal();
    cookieApprovalStore.acceptNecessary();
    handleStatisticalCookie(false);
  }, [hideModal, handleStatisticalCookie]);
  const [preferentialSelected, setPreferentialSelected] = useState(false);
  const [statisticalSelected, setStatisticalSelected] = useState(false);
  useEffect(() => {
    const cookieSettings = cookieApprovalStore.get();
    setPreferentialSelected(cookieSettings.prop('preferential').orTrue());
    setStatisticalSelected(cookieSettings.prop('statistical').orTrue());
  }, [visible]);
  const acceptSelected = useCallback(() => {
    hideModal();
    cookieApprovalStore.acceptSelected(preferentialSelected, statisticalSelected);
    handleStatisticalCookie(statisticalSelected);
  }, [hideModal, statisticalSelected, preferentialSelected, handleStatisticalCookie]);
  const acceptSelectedDebounced = useMemo(() => debounce(waitTime)(acceptSelected), [acceptSelected]);
  const acceptAllDebounced = useMemo(() => debounce(waitTime)(acceptAll), [acceptAll]);
  const denyAllDebounced = useMemo(() => debounce(waitTime)(denyAll), [denyAll]);
  const footer = <Ant.Row gutter={[gutter, {
    xs: gutter,
    sm: gutter,
    md: 0
  }]}>
            <Ant.Col xs={24} md={7}>
                <Button onClick={denyAllDebounced} block testId="denyAllButton">
                    {t('denyAll')}
                </Button>
            </Ant.Col>
            <Ant.Col xs={0} md={3} />
            <Ant.Col xs={24} md={7}>
                <Button onClick={acceptSelectedDebounced} block testId="acceptSelectedButton">
                    {t('acceptSelected')}
                </Button>
            </Ant.Col>
            <Ant.Col xs={24} md={7}>
                <Button onClick={acceptAllDebounced} block visuals="primary" testId="acceptAllButton">
                    {t('acceptAll')}
                </Button>
            </Ant.Col>
        </Ant.Row>;
  return <Modal footer={footer} title={t('title')} visible={visible && isLocalStorageAvailable()} onCancel={hideModal} data-sentry-element="Modal" data-sentry-component="CookieModalBase" data-sentry-source-file="index.tsx">
            <Ant.Row data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Ant.Col xs={24} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {t('description')}
                </Ant.Col>
            </Ant.Row>

            <div className={classNames('mt-2', styles.cookieCategory)}>
                <Switch label={t('necessary')} value={opt(true)} description={t('necessaryDescription')} disabled testId="necessarySwitch" data-sentry-element="Switch" data-sentry-source-file="index.tsx" />
                <Switch label={t('preferential')} value={opt(preferentialSelected)} setSelected={setPreferentialSelected} description={t('preferentialDescription')} testId="preferentialSwitch" data-sentry-element="Switch" data-sentry-source-file="index.tsx" />
                <Switch label={t('statistical')} value={opt(statisticalSelected)} setSelected={setStatisticalSelected} description={t('statisticalDescription')} testId="statisticalSwitch" data-sentry-element="Switch" data-sentry-source-file="index.tsx" />
            </div>
        </Modal>;
};
export const CookieModal = memo(CookieModalBase);