// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitBv, AnimalTraitBvSchema } from 'api/gen/AnimalTraitBv';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitBvInteger, AnimalTraitBvIntegerSchema } from 'api/gen/AnimalTraitBvInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitInteger, AnimalTraitIntegerSchema } from 'api/gen/AnimalTraitInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Inbreeding, InbreedingSchema } from 'api/gen/Inbreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkProduction, MilkProductionSchema } from 'api/gen/MilkProduction';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkProductionBv, MilkProductionBvSchema } from 'api/gen/MilkProductionBv';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  birthDate: t.union([DateSchema, t.null]),
  bvFatKg: t.union([AnimalTraitBvIntegerSchema, t.null]),
  bvFatPercentage: t.union([AnimalTraitBvSchema, t.null]),
  bvMilkKg: t.union([MilkProductionBvSchema, t.null]),
  bvMilkKgReliability: t.union([AnimalTraitBvIntegerSchema, t.null]),
  bvProteinKg: t.union([AnimalTraitBvIntegerSchema, t.null]),
  bvProteinPercentage: t.union([AnimalTraitBvSchema, t.null]),
  earTag: EarTagSchema,
  id: PositiveIntegerSchema,
  inbreedingCoefficient: InbreedingSchema,
  rbvBackAngle: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBackWidth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBodyDepth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBodyStructure: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBonesQuality: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvChestWidth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvClinicalMastitis: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvEdginess: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvExtremities: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFatKg: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFatPercentage: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFitness: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvForeUdderAttachment: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFrame: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFrontTeatsPlacement: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvHoovesAngle: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvLimbDisease: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvMilkKg: t.union([MilkProductionSchema, t.null]),
  rbvMilkStrength: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvOverallCondition: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvProteinKg: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvProteinPercentage: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearPosture: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearTeatsPlacement: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearUdderAttachmentHeight: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearUdderAttachmentWidth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvSidePosture: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvSomaticCells: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvSuspensoryLigament: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvTeatsLength: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvUdder: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvUdderDepth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvWalkQuality: t.union([AnimalTraitIntegerSchema, t.null]),
  sihIndex: t.union([AnimalTraitIntegerSchema, t.null]),
});

export const HolsteinAnimalInListBaseSchema = excess(requiredPart);

export interface HolsteinAnimalInListBase extends t.TypeOf<typeof HolsteinAnimalInListBaseSchema> {}
