// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationNameCobol, OrganizationNameCobolSchema } from 'api/gen/OrganizationNameCobol';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationRoleFacility, OrganizationRoleFacilitySchema } from 'api/gen/OrganizationRoleFacility';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';

const requiredPart = t.interface({
  organizationCode: OrganizationCodeSchema,
  organizationId: PositiveIntegerSchema,
  organizationName: t.union([OrganizationNameCobolSchema, t.null]),
  roles: t.array(OrganizationRoleFacilitySchema),
  rowId: RowIdSchema,
});

export const OrganizationRoleSchema = excess(requiredPart);

export interface OrganizationRole extends t.TypeOf<typeof OrganizationRoleSchema> {}
