// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-layout-components-cookie-modal-___styles__cookieLogo___N1UcE{text-align:center}.src-common-layout-components-cookie-modal-___styles__cookieLogo___N1UcE svg{font-size:210px;height:130px}.src-common-layout-components-cookie-modal-___styles__cookieCategory___aBBJU{font-size:16px}.src-common-layout-components-cookie-modal-___styles__cookieCategory___aBBJU svg{color:#77ab11;cursor:help}
`, "",{"version":3,"sources":["webpack://./src/common/layout/components/cookie-modal/styles.sass"],"names":[],"mappings":"AAAA,yEAAY,iBAAA,CAAkB,6EAAgB,eAAA,CAAgB,YAAA,CAAa,6EAAgB,cAAA,CAAe,iFAAoB,aAAA,CAAc,WAAA","sourcesContent":[".cookieLogo{text-align:center}.cookieLogo svg{font-size:210px;height:130px}.cookieCategory{font-size:16px}.cookieCategory svg{color:#77ab11;cursor:help}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookieLogo": `src-common-layout-components-cookie-modal-___styles__cookieLogo___N1UcE`,
	"cookieCategory": `src-common-layout-components-cookie-modal-___styles__cookieCategory___aBBJU`
};
export default ___CSS_LOADER_EXPORT___;
