// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A documentary comment about a permission
// Example: "Informace o systému"

export const permissionCommentMinLength = 1;
export const permissionCommentMaxLength = 256;

export const PermissionCommentSchema = t.refinement(t.string, n => n.length >= permissionCommentMinLength && n.length <= permissionCommentMaxLength, 'PermissionComment');

export type PermissionComment = string;
