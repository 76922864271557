// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(4, 3).
// Example: 4.0e-3

export const decimal43Minimum = -10.0; // exclusive
export const decimal43Maximum = 10.0; // exclusive
export const decimal43MinimumInclusive = -9.999;
export const decimal43MaximumInclusive = 9.999;
export const decimal43MinMaxInclusive: [number | null, number | null] = [-9.999, 9.999];
export const decimal43DecimalPlaces = 3;
export const decimal43RegexGen = new RegExp("^-?\\d{1}[,.]\\d{3}$");

export const Decimal43Schema = t.refinement(t.number, x => x > decimal43Minimum && x < decimal43Maximum && Number.isInteger(parseFloat((x / 0.001).toFixed(10))), 'Decimal43');

export type Decimal43 = t.TypeOf<typeof Decimal43Schema>;
