// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationNameCobol, OrganizationNameCobolSchema } from 'api/gen/OrganizationNameCobol';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  code: OrganizationCodeSchema,
  id: PositiveIntegerSchema,
  name: t.union([OrganizationNameCobolSchema, t.null]),
});

export const OrganizationSchema = excess(requiredPart);

export interface Organization extends t.TypeOf<typeof OrganizationSchema> {}
