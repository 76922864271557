import {numberRangePercentages, NumberRangeValue} from '@fl/cmsch-fe-library';
import {isInteger} from 'lodash/fp';

import {Decimal31Schema} from 'api/gen/Decimal31';
import {Decimal43Schema} from 'api/gen/Decimal43';
import {Decimal52Schema} from 'api/gen/Decimal52';

export const maxNumberIsGreater = (value: NumberRangeValue | undefined | null): string | undefined => {
    if (value?.selectedType === 'number' && value.maximum !== null && value.minimum !== null) {
        if (value.minimum > value.maximum) return `Minimum nesmí být větší než maximum.`;
    }

    return undefined;
};

function validateInt(value: NumberRangeValue): string | undefined {
    if (value.minimum !== null && !isInteger(value.minimum)) {
        return 'Minimum musí být celé číslo.';
    }

    if (value.maximum !== null && !isInteger(value.maximum)) {
        return 'Maximum musí být celé číslo.';
    }
}

function validateDecimal52(value: NumberRangeValue): string | undefined {
    if (value.minimum !== null && !Decimal52Schema.is(value.minimum)) {
        return 'Minimum může obsahovat nejvýše 3 celá a 2 desetinná čísla.';
    }

    if (value.maximum !== null && !Decimal52Schema.is(value.maximum)) {
        return 'Maximum může obsahovat nejvýše 3 celá a 2 desetinná čísla.';
    }
}

function validateDecimal43(value: NumberRangeValue): string | undefined {
    if (value.minimum !== null && !Decimal43Schema.is(value.minimum)) {
        return 'Minimum může obsahovat nejvýše 1 celé a 3 desetinná čísla.';
    }

    if (value.maximum !== null && !Decimal43Schema.is(value.maximum)) {
        return 'Maximum může obsahovat nejvýše 1 celé a 3 desetinná čísla.';
    }
}

function validateDecimal31(value: NumberRangeValue): string | undefined {
    if (value.minimum !== null && !Decimal31Schema.is(value.minimum)) {
        return 'Minimum může obsahovat nejvýše 2 celá a 1 desetinné číslo.';
    }

    if (value.maximum !== null && !Decimal31Schema.is(value.maximum)) {
        return 'Maximum může obsahovat nejvýše 2 celá a 1 desetinné číslo.';
    }
}

export const validateNumberRange = (value: NumberRangeValue | undefined | null): string | undefined => {
    if (value?.selectedType === 'percentage') {
        if (!numberRangePercentages.includes(value.percentage)) {
            return 'Není vybrána validní procentuální hodnota.';
        }
    } else if (value?.criteriaType === 'INT') {
        return validateInt(value);
    } else if (value?.criteriaType === 'DECIMAL_5_2') {
        return validateDecimal52(value);
    } else if (value?.criteriaType === 'DECIMAL_4_3') {
        return validateDecimal43(value);
    } else if (value?.criteriaType === 'DECIMAL_3_1') {
        return validateDecimal31(value);
    }

    return undefined;
};
