// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaValuesBase, CriteriaValuesBaseSchema } from 'api/gen/CriteriaValuesBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43, Decimal43Schema } from 'api/gen/Decimal43';

const requiredPart = t.interface({
  tag: t.literal('SINGLE_DECIMAL_4_3'),
  value: t.union([Decimal43Schema, t.null]),
});

export const SingleDecimal43CriteriaValuesSchema = excess(t.intersection([CriteriaValuesBaseSchema.type, requiredPart]));

export interface SingleDecimal43CriteriaValues extends t.TypeOf<typeof SingleDecimal43CriteriaValuesSchema> {}
