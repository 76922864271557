// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateAssociationRoleBase, ValidateAssociationRoleBaseSchema } from 'api/gen/ValidateAssociationRoleBase';

const requiredPart = t.interface({
  tag: t.literal('ValidateCreateAssociationRole'),
  approvedFrom: DateSchema,
  associationId: t.Integer,
  roleIds: t.array(PositiveIntegerSchema),
  userProfileId: PositiveIntegerSchema,
});

export const ValidateCreateAssociationRoleSchema = excess(t.intersection([ValidateAssociationRoleBaseSchema.type, requiredPart]));

export interface ValidateCreateAssociationRole extends t.TypeOf<typeof ValidateCreateAssociationRoleSchema> {}
