// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';

const requiredPart = t.interface({
  code: OrganizationCodeSchema,
  id: PositiveIntegerSchema,
  name: t.string,
  numOfBulls: PositiveOrZeroLongSchema,
});

export const OrganizationToChooseSchema = excess(requiredPart);

export interface OrganizationToChoose extends t.TypeOf<typeof OrganizationToChooseSchema> {}
