// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-icons-___styles__svgImage___iTRK2 svg{width:1em}
`, "",{"version":3,"sources":["webpack://./src/common/icons/styles.sass"],"names":[],"mappings":"AAAA,kDAAc,SAAA","sourcesContent":[".svgImage svg{width:1em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgImage": `src-common-icons-___styles__svgImage___iTRK2`
};
export default ___CSS_LOADER_EXPORT___;
