// Custom schema from src/api/custom/Date.ts

/* eslint-disable unicorn/filename-case */
// Mandatory for pnpm gen-api command
import {DateStringSchema} from '@fl/cmsch-fe-library';

export const DateSchema = DateStringSchema;

export type Date = string;


