// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalCodeName, AnimalCodeNameSchema } from 'api/gen/AnimalCodeName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitBv, AnimalTraitBvSchema } from 'api/gen/AnimalTraitBv';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitBvInteger, AnimalTraitBvIntegerSchema } from 'api/gen/AnimalTraitBvInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitInteger, AnimalTraitIntegerSchema } from 'api/gen/AnimalTraitInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkProductionBv, MilkProductionBvSchema } from 'api/gen/MilkProductionBv';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OrganizationCode, OrganizationCodeSchema } from 'api/gen/OrganizationCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  birthDate: t.union([DateSchema, t.null]),
  bvFatKg: t.union([AnimalTraitBvIntegerSchema, t.null]),
  bvFatPercentage: t.union([AnimalTraitBvSchema, t.null]),
  bvMilkKg: t.union([MilkProductionBvSchema, t.null]),
  bvProteinKg: t.union([AnimalTraitBvIntegerSchema, t.null]),
  bvProteinPercentage: t.union([AnimalTraitBvSchema, t.null]),
  earTag: EarTagSchema,
  fertilityIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  fitnessIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  fleshIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  id: PositiveIntegerSchema,
  lineRegistry: LineRegistrySchema,
  milkIndex: t.union([t.Integer, t.null]),
  name: t.union([AnimalCodeNameSchema, t.null]),
  organizationCode: t.union([OrganizationCodeSchema, t.null]),
  rbvAnkleJoint: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBackAngle: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBackHeight: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBackWidth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBodyDepth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvBodyLength: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvCarcassGrade: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvClinicalMastitis: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvCysts: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvDailyGain: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvDressingPercentage: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvEarlyFertilityDisorders: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvExtremities: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvForeUdderAttachment: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFrame: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFrontTeatsPlacement: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvFrontUdderAttachmentLength: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvHoovesAngle: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvHypocalcemia: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvLongevity: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvMaternalCalvingEase: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvMilkability: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvMusculature: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvPastern: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvPaternalCalvingEase: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvPersistence: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearPosture: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearTeatsPlacement: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvRearUdderAttachmentLength: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvSomaticCells: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvSuspensoryLigament: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvTeatsLength: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvTeatsPosition: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvTeatsWidth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvUdder: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvUdderDepth: t.union([AnimalTraitIntegerSchema, t.null]),
  rbvUdderPurity: t.union([AnimalTraitIntegerSchema, t.null]),
  totalBreedingValue: t.union([t.Integer, t.null]),
  udderHealthIndex: t.union([AnimalTraitIntegerSchema, t.null]),
  vitalityIndex: t.union([AnimalTraitIntegerSchema, t.null]),
});

export const MaleFleckviehAnimalInListSchema = excess(requiredPart);

export interface MaleFleckviehAnimalInList extends t.TypeOf<typeof MaleFleckviehAnimalInListSchema> {}
