// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';

const requiredPart = t.interface({
  approvedTo: t.union([DateSchema, t.null]),
  tag: t.string,
});

export const ValidateBreederRoleBaseSchema = excess(requiredPart);

export interface ValidateBreederRoleBase extends t.TypeOf<typeof ValidateBreederRoleBaseSchema> {}
