// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaIndex, CriteriaIndexSchema } from 'api/gen/CriteriaIndex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaName, CriteriaNameSchema } from 'api/gen/CriteriaName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Operator, OperatorSchema } from 'api/gen/Operator';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  code: t.string,
  id: PositiveIntegerSchema,
  index: CriteriaIndexSchema,
  name: CriteriaNameSchema,
  operator: OperatorSchema,
  tag: t.string,
});

export const CriteriaDefinitionBaseSchema = excess(requiredPart);

export interface CriteriaDefinitionBase extends t.TypeOf<typeof CriteriaDefinitionBaseSchema> {}
