// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BooleanCriteriaDefinition, BooleanCriteriaDefinitionSchema } from 'api/gen/BooleanCriteriaDefinition';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateCriteriaDefinition, DateCriteriaDefinitionSchema } from 'api/gen/DateCriteriaDefinition';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal31CriteriaDefinition, Decimal31CriteriaDefinitionSchema } from 'api/gen/Decimal31CriteriaDefinition';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43CriteriaDefinition, Decimal43CriteriaDefinitionSchema } from 'api/gen/Decimal43CriteriaDefinition';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52CriteriaDefinition, Decimal52CriteriaDefinitionSchema } from 'api/gen/Decimal52CriteriaDefinition';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { IntegerCriteriaDefinition, IntegerCriteriaDefinitionSchema } from 'api/gen/IntegerCriteriaDefinition';

export const CriteriaDefinitionSchema = t.union([BooleanCriteriaDefinitionSchema, DateCriteriaDefinitionSchema, Decimal31CriteriaDefinitionSchema, Decimal43CriteriaDefinitionSchema, Decimal52CriteriaDefinitionSchema, IntegerCriteriaDefinitionSchema]);

export type CriteriaDefinition = t.TypeOf<typeof CriteriaDefinitionSchema>;
