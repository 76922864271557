// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Organization full name imported from Cobol source.
// Example: "Rodinná ekofarma Konvalinka"

export const organizationNameCobolMinLength = 1;
export const organizationNameCobolMaxLength = 2000;

export const OrganizationNameCobolSchema = t.refinement(t.string, n => n.length >= organizationNameCobolMinLength && n.length <= organizationNameCobolMaxLength, 'OrganizationNameCobol');

export type OrganizationNameCobol = string;
