import {TableDataParams} from '@fl/cmsch-fe-library';

import {AppModule} from 'api/gen/AppModule';
import {GridCode} from 'api/gen/GridCode';

import {TableId} from '../common/types/table-id';
import {TableRowMap} from '../types/table-row-map';

interface TableUserSettingsServerIds {
    module: AppModule;
    code: GridCode;
}

interface TableConfig<TableRow> {
    defaultParams?: TableDataParams<TableRow>;
    defaultValues?: TableDataParams<TableRow>;
    serverUserSettingsIds?: TableUserSettingsServerIds;
}

type TableConfigs = {
    [TID in TableId]?: TableConfig<TableRowMap[TID]>;
};

const tableConfigs: TableConfigs = {
    matingCows: {
        defaultParams: {
            sorting: [{field: 'earTag', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'MATING_H', code: 'COW'},
    },
    matingHolsteinBulls: {
        defaultParams: {
            sorting: [{field: 'lineRegistry', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'MATING_H', code: 'BULL'},
    },
    matingFleckviehBulls: {
        defaultParams: {
            sorting: [{field: 'lineRegistry', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'MATING_C', code: 'BULL'},
    },
    rolePermissions: {
        defaultParams: {
            sorting: [{field: 'code', direction: 'ASC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'ROLE_PERMISSION'},
    },
    roleUnassignedPermissions: {
        defaultParams: {
            sorting: [{field: 'code', direction: 'ASC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'ROLE_PERMISSION_UNASSIGNED'},
    },
    stableToChoose: {
        defaultParams: {
            pagination: {page: 0, size: 20},
            filters: {},
        },
        defaultValues: {
            filters: {
                farmAnimalCount: {type: 'betweenIntegers', from: 1, to: null},
                stableAnimalCount: {type: 'betweenIntegers', from: 1, to: null},
            },
        },
        serverUserSettingsIds: {module: 'ANIMALS_BROWSER', code: 'STABLE'},
    },
    cowBrowser: {
        defaultParams: {
            sorting: [{field: 'earTag', direction: 'ASC'}],
            pagination: {page: 0, size: 20},
            filters: {alive: {type: 'boolean', value: true}},
        },
        serverUserSettingsIds: {module: 'ANIMALS_BROWSER', code: 'COW'},
    },
    calfBrowser: {
        defaultParams: {
            sorting: [{field: 'earTag', direction: 'ASC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ANIMALS_BROWSER', code: 'CALF'},
    },
    milkabilityOverview: {
        defaultParams: {
            sorting: [{field: 'examinationDate', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'MILKABILITY', code: 'MILKABILITY'},
    },
    analysisProtocolCorrectionOverview: {
        defaultParams: {
            sorting: [{field: 'proficiencyTestDate', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'APC', code: 'APC'},
    },
    comprehensiveBreedingFemale: {
        defaultParams: {
            sorting: [{field: 'earTag', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ANIMALS_BROWSER_ALL', code: 'COW'},
    },
    comprehensiveCalves: {
        defaultParams: {
            sorting: [{field: 'earTag', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ANIMALS_BROWSER_ALL', code: 'CALF'},
    },
    permissions: {
        defaultParams: {
            filters: {},
            sorting: [{field: 'actionName', direction: 'DESC'}],
            pagination: {page: 0, size: 20},

        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'PERMISSION'},
    },
    roles: {
        defaultParams: {
            filters: {},
            sorting: [{field: 'code', direction: 'ASC'}],
            pagination: {page: 0, size: 20},

        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'ROLE'},
    },
    roleSubordinateAssignedRoles: {
        defaultParams: {
            filters: {},
            sorting: [{field: 'code', direction: 'ASC'}],
            pagination: {page: 0, size: 20},

        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'ROLE_SUBORDINATE'},
    },
    roleSubordinateUnassignedRoles: {
        defaultParams: {
            filters: {},
            sorting: [{field: 'code', direction: 'ASC'}],
            pagination: {page: 0, size: 20},

        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'ROLE_SUBORDINATE_UNASSIGNED'},
    },
    users: {
        defaultParams: {
            filters: {},
            sorting: [{field: 'active', direction: 'ASC'}],
            pagination: {page: 0, size: 20},

        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER'},
    },
    memberOfSubjects: {
        defaultParams: {
            sorting: [{field: 'code', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER_SUBJECT_MEMBER'},
    },
    userContacts: {
        defaultParams: {
            sorting: [{field: 'value', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER_CONTACT'},
    },
    userAssociations: {
        defaultParams: {
            sorting: [{field: 'associationId', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER_ASSOCIATION_ROLE'},
    },
    userBreeders: {
        defaultParams: {
            sorting: [{field: 'breederRegistrationNumber', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER_BREEDER_ROLE'},
    },
    userOtherSubjectRoles: {
        defaultParams: {
            sorting: [{field: 'otherSubjectId', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER_OTHER_SUBJECT_ROLE'},
    },
    organizations: {
        defaultParams: {
            sorting: [{field: 'organizationCode', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'ADMIN', code: 'USER_ORGANIZATION_ROLE'},
    },
    memberOfSubjectsProfile: {
        defaultParams: {
            sorting: [{field: 'code', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'USER_PROFILE', code: 'USER_SUBJECT_MEMBER'},
    },
    userContactsProfile: {
        defaultParams: {
            sorting: [{field: 'value', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'USER_PROFILE', code: 'USER_CONTACT'},
    },
    userAssociationsProfile: {
        defaultParams: {
            sorting: [{field: 'associationId', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'USER_PROFILE', code: 'USER_ASSOCIATION_ROLE'},
    },
    userBreedersProfile: {
        defaultParams: {
            sorting: [{field: 'breederRegistrationNumber', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'USER_PROFILE', code: 'USER_BREEDER_ROLE'},
    },
    userOtherSubjectRolesProfile: {
        defaultParams: {
            sorting: [{field: 'otherSubjectId', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'USER_PROFILE', code: 'USER_OTHER_SUBJECT_ROLE'},
    },
    organizationsProfile: {
        defaultParams: {
            sorting: [{field: 'organizationCode', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {},
        },
        serverUserSettingsIds: {module: 'USER_PROFILE', code: 'USER_ORGANIZATION_ROLE'},
    },
};

export const getTableConfig = <TID extends TableId>(tableId: TID): TableConfig<TableRowMap[TID]> | undefined =>
    tableConfigs[tableId];
