// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CorrectionType, CorrectionTypeSchema } from 'api/gen/CorrectionType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';

const requiredPart = t.interface({
  correctionType: CorrectionTypeSchema,
  earTag: EarTagSchema,
});

export const ValidateApcBaseSchema = excess(requiredPart);

export interface ValidateApcBase extends t.TypeOf<typeof ValidateApcBaseSchema> {}
