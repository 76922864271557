import { debounce, noop } from 'lodash/fp';
import React, { Fragment, memo, useMemo } from 'react';
import { Ant } from 'common/ant';
interface Footer {
  submitText: string;
  cancelText: string;
  disabled?: boolean;
  loading?: boolean;
  onSubmit(): void;
}
interface Props {
  title: string;
  visible: boolean;
  footer?: React.ReactNode;
  defaultFooter?: Footer;
  children: React.ReactNode;
  dontDestroyOnClose?: boolean;
  displayInLeftBottom?: boolean;
  closable?: boolean;
  width?: number;
  onCancel(): void;
}
const waitTime = 700;
const ModalBase: React.FC<Props> = ({
  title,
  visible,
  footer,
  children,
  dontDestroyOnClose,
  closable,
  defaultFooter,
  width,
  onCancel
}) => {
  const onClickDebounced = useMemo(() => debounce(waitTime, defaultFooter?.onSubmit || noop), [defaultFooter?.onSubmit]);
  const onCancelDebounced = useMemo(() => debounce(waitTime, onCancel), [onCancel]);
  const defaultFooterNode = <Fragment>
            <Ant.Button key="back" onClick={onCancelDebounced} loading={defaultFooter?.loading}>
                {defaultFooter?.cancelText}
            </Ant.Button>
            <Ant.Button key="submit" disabled={defaultFooter?.disabled} type="primary" onClick={onClickDebounced} loading={defaultFooter?.loading}>
                {defaultFooter?.submitText}
            </Ant.Button>
        </Fragment>;
  return <Ant.Modal title={title} visible={visible} onCancel={onCancelDebounced} footer={defaultFooter ? defaultFooterNode : footer} destroyOnClose={!dontDestroyOnClose} maskClosable={false} closable={closable} width={width} data-sentry-element="unknown" data-sentry-component="ModalBase" data-sentry-source-file="index.tsx">
            {children}
        </Ant.Modal>;
};
export const Modal = memo(ModalBase);