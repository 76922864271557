// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Inbreeding represented by floating point values with format decimal(4, 3).
// Example: 4.0e-3

export const inbreedingMinimum = -10.0; // exclusive
export const inbreedingMaximum = 10.0; // exclusive
export const inbreedingMinimumInclusive = -9.999;
export const inbreedingMaximumInclusive = 9.999;
export const inbreedingMinMaxInclusive: [number | null, number | null] = [-9.999, 9.999];
export const inbreedingDecimalPlaces = 3;
export const inbreedingRegexGen = new RegExp("^-?\\d{1}[,.]\\d{3}$");

export const InbreedingSchema = t.refinement(t.number, x => x > inbreedingMinimum && x < inbreedingMaximum && Number.isInteger(parseFloat((x / 0.001).toFixed(10))), 'Inbreeding');

export type Inbreeding = t.TypeOf<typeof InbreedingSchema>;
