// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmName, FarmNameSchema } from 'api/gen/FarmName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  code: FarmCodeSchema,
  id: t.Integer,
  name: t.union([FarmNameSchema, t.null]),
  subjectBusinessName: t.union([SubjectBusinessNameSchema, t.null]),
});

export const CabFarmAutocompleteSchema = excess(requiredPart);

export interface CabFarmAutocomplete extends t.TypeOf<typeof CabFarmAutocompleteSchema> {}
