// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(4, 1).
// Example: 0.4

export const decimal41Minimum = -1000.0; // exclusive
export const decimal41Maximum = 1000.0; // exclusive
export const decimal41MinimumInclusive = -999.9;
export const decimal41MaximumInclusive = 999.9;
export const decimal41MinMaxInclusive: [number | null, number | null] = [-999.9, 999.9];
export const decimal41DecimalPlaces = 1;
export const decimal41RegexGen = new RegExp("^-?\\d{1,3}[,.]\\d{1}$");

export const Decimal41Schema = t.refinement(t.number, x => x > decimal41Minimum && x < decimal41Maximum && Number.isInteger(parseFloat((x / 0.1).toFixed(10))), 'Decimal41');

export type Decimal41 = t.TypeOf<typeof Decimal41Schema>;
