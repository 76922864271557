// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederRegistrationNumber, BreederRegistrationNumberSchema } from 'api/gen/BreederRegistrationNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleBreederFarm, RoleBreederFarmSchema } from 'api/gen/RoleBreederFarm';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  breederId: PositiveIntegerSchema,
  breederRegistrationNumber: BreederRegistrationNumberSchema,
  roles: t.array(RoleBreederFarmSchema),
  rowId: RowIdSchema,
  subjectId: PositiveIntegerSchema,
  subjectName: t.union([SubjectBusinessNameSchema, t.null]),
});

export const BreederRoleSchema = excess(requiredPart);

export interface BreederRole extends t.TypeOf<typeof BreederRoleSchema> {}
