import { classNames, useWindowDimensions } from '@fl/cmsch-fe-library';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { startsWith } from 'fp-ts/string';
import { isUndefined } from 'lodash/fp';
import React, { createRef, Fragment, FC, memo, ReactNode, RefObject, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { opt, optEmptyArray } from 'ts-opt';
import { Ant } from 'common/ant';
import { IconName, ICONS } from 'common/icons';
import { MenuItem } from 'common/layout/types/menu-item';
import styles from './styles.sass';
const spaceBeforeActionIcons = 50;
interface Props {
  menuItem: MenuItem;
  locationPath: string;
  visible: boolean;
  actionIconsRef: RefObject<HTMLDivElement>;
  toggleOverflowedMenuItem(key: string): void;
}
export const getLabel = (label: string, isSubMenu: boolean, icon?: IconName): ReactNode => <Fragment>
        <div className={isSubMenu ? styles.subMenuLabel : styles.menuLabel}>
            {icon && ICONS[icon]}
            {label}
        </div>
    </Fragment>;
export const isActive = (locationPath: string, path?: string, subMenuItems?: Array<MenuItem>, pathsNotInMenu?: Array<string>): boolean => path === locationPath || (subMenuItems || []).some(x => {
  const isActiveMenuItem = isActive(locationPath, path, x.subMenuItems, x.pathsNotInMenu);
  return x.exactPath ? x.path === locationPath || isActiveMenuItem : x.path && locationPath.startsWith(x.path) || isActiveMenuItem;
}) || (pathsNotInMenu || []).some(startsWith(locationPath));
export const buildSubMenuItems = (menuItems: Array<MenuItem>, locationPath: string): Array<ItemType> => menuItems.map(x => optEmptyArray(x.subMenuItems).caseOf<ItemType>(y => ({
  key: x.key,
  className: classNames(isActive(locationPath, x.path, x.subMenuItems) && styles.active, styles.item),
  label: getLabel(x.label, true, x.icon),
  children: buildSubMenuItems(y, locationPath)
}), () => x.path ? {
  key: x.key,
  className: classNames(isActive(locationPath, x.path, x.subMenuItems) && styles.active),
  label: !x.isExternalLink ? <Link to={x.path} target={x.openIn}>
                        {getLabel(x.label, true, x.icon)}
                    </Link> : <a href={x.path} target={x.openIn} rel="noreferrer">
                        {getLabel(x.label, true, x.icon)}
                    </a>
} : null));
interface ButtonProps {
  menuKey: string;
  path: string | undefined;
  isExternalLink?: boolean;
  openIn?: '_blank';
  reference: RefObject<HTMLDivElement>;
  menuItemLabel: ReactNode;
  classes: string;
  subMenuItems?: Array<MenuItem>;
}

// for some reasons it can't be as FunctionComponent (dropdown menu on hover doesn't work)
const button = ({
  menuKey,
  path,
  menuItemLabel,
  reference,
  classes,
  subMenuItems,
  isExternalLink,
  openIn
}: ButtonProps): ReactNode => {
  const firstSubMenuItemPath = opt(subMenuItems).headIn().prop('path').orUndef();
  return <div className={classes} key={menuKey} ref={reference} data-sentry-component="button" data-sentry-source-file="main-menu-item.tsx">

            {path && !isExternalLink ? <Link to={firstSubMenuItemPath ?? path} target={openIn}>
                        {menuItemLabel}
                    </Link> : <a href={path || ''} target={openIn} rel="noreferrer">
                        {menuItemLabel}
                    </a>}
        </div>;
};
const MainMenuItemBase: FC<Props> = props => {
  const {
    menuItem,
    locationPath,
    visible,
    actionIconsRef,
    toggleOverflowedMenuItem
  } = props;
  const {
    label,
    key,
    path,
    icon,
    subMenuItems,
    pathsNotInMenu,
    isExternalLink,
    openIn
  } = menuItem;
  const ref = createRef<HTMLDivElement>();
  const {
    width,
    height
  } = useWindowDimensions();
  const active = useMemo(() => isActive(locationPath, path, subMenuItems, pathsNotInMenu), [locationPath, path, pathsNotInMenu, subMenuItems]);
  const menuItemLabel: ReactNode = getLabel(label, false, icon);
  const className = classNames(styles.menuItem, active && styles.active, !visible && 'invisible');
  useEffect(() => {
    const {
      width: actionIconsWidth
    } = actionIconsRef.current?.getBoundingClientRect() || {};
    const ourWidth = width - (actionIconsWidth || 0) - spaceBeforeActionIcons;
    const {
      right
    } = ref.current?.getBoundingClientRect() || {};
    if (!isUndefined(right) && (right < ourWidth && !visible || right > ourWidth && visible)) {
      toggleOverflowedMenuItem(key);
    }
  }, [height, label, ref, visible, toggleOverflowedMenuItem, key, actionIconsRef, width]);
  return optEmptyArray(subMenuItems).caseOf(sbItems => <Ant.Dropdown overlay={<Ant.Menu items={buildSubMenuItems(sbItems, locationPath)} />} placement="bottomLeft" arrow overlayClassName={styles.dropdown}>
                {button({
      menuKey: key,
      classes: className,
      path,
      menuItemLabel,
      reference: ref,
      subMenuItems: sbItems,
      isExternalLink,
      openIn
    })}
            </Ant.Dropdown>, () => <Fragment>
                {button({
      menuKey: key,
      classes: className,
      path,
      menuItemLabel,
      reference: ref,
      isExternalLink,
      openIn
    })}
            </Fragment>);
};
export const MainMenuItem = memo(MainMenuItemBase);