// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ListName, ListNameSchema } from 'api/gen/ListName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowVersion, RowVersionSchema } from 'api/gen/RowVersion';

const requiredPart = t.interface({
  name: ListNameSchema,
  version: RowVersionSchema,
});

export const RenameAnimalListSchema = excess(requiredPart);

export interface RenameAnimalList extends t.TypeOf<typeof RenameAnimalListSchema> {}
