// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Text fragment for filtering dropdown autocompletion code list items.
// Example: "Ivan"

export const autocompleteFilterTextMinLength = 3;

export const AutocompleteFilterTextSchema = t.refinement(t.string, n => n.length >= autocompleteFilterTextMinLength, 'AutocompleteFilterText');

export type AutocompleteFilterText = string;
