// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The schema represents a decimal value in the following format: decimal(9, 2).
// Example: 9.0e-2

export const decimal92Minimum = -10000000.0; // exclusive
export const decimal92Maximum = 10000000.0; // exclusive
export const decimal92MinimumInclusive = -9999999.99;
export const decimal92MaximumInclusive = 9999999.99;
export const decimal92MinMaxInclusive: [number | null, number | null] = [-9999999.99, 9999999.99];
export const decimal92DecimalPlaces = 2;
export const decimal92RegexGen = new RegExp("^-?\\d{1,7}[,.]\\d{2}$");

export const Decimal92Schema = t.refinement(t.number, x => x > decimal92Minimum && x < decimal92Maximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'Decimal92');

export type Decimal92 = t.TypeOf<typeof Decimal92Schema>;
