// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateApcDiscard, ValidateApcDiscardSchema } from 'api/gen/ValidateApcDiscard';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateApcEarTagUpdate, ValidateApcEarTagUpdateSchema } from 'api/gen/ValidateApcEarTagUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateApcUpdate, ValidateApcUpdateSchema } from 'api/gen/ValidateApcUpdate';

export const ValidateApcSchema = t.union([ValidateApcDiscardSchema, ValidateApcEarTagUpdateSchema, ValidateApcUpdateSchema]);

export type ValidateApc = t.TypeOf<typeof ValidateApcSchema>;
