// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaBooleanFilter, CriteriaBooleanFilterSchema } from 'api/gen/CriteriaBooleanFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDateFilter, CriteriaDateFilterSchema } from 'api/gen/CriteriaDateFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDecimal31Filter, CriteriaDecimal31FilterSchema } from 'api/gen/CriteriaDecimal31Filter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDecimal43Filter, CriteriaDecimal43FilterSchema } from 'api/gen/CriteriaDecimal43Filter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDecimal52Filter, CriteriaDecimal52FilterSchema } from 'api/gen/CriteriaDecimal52Filter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaIntegerFilter, CriteriaIntegerFilterSchema } from 'api/gen/CriteriaIntegerFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaSingleIntegerFilter, CriteriaSingleIntegerFilterSchema } from 'api/gen/CriteriaSingleIntegerFilter';

export const CriteriaFilterSchema = t.union([CriteriaBooleanFilterSchema, CriteriaDateFilterSchema, CriteriaDecimal31FilterSchema, CriteriaDecimal43FilterSchema, CriteriaDecimal52FilterSchema, CriteriaIntegerFilterSchema, CriteriaSingleIntegerFilterSchema]);

export type CriteriaFilter = t.TypeOf<typeof CriteriaFilterSchema>;
