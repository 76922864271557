// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateApcBase, CreateApcBaseSchema } from 'api/gen/CreateApcBase';

const requiredPart = t.interface({
  correctionType: t.literal('DISCARD'),
});

export const CreateApcDiscardSchema = excess(t.intersection([CreateApcBaseSchema.type, requiredPart]));

export interface CreateApcDiscard extends t.TypeOf<typeof CreateApcDiscardSchema> {}
