import { useWindowDimensions } from '@fl/cmsch-fe-library';
import React, { FC, memo } from 'react';
import { Button } from '../button';
interface Props {
  text: string;
  onClick(): void;
}
const BackButtonBase: FC<Props> = props => {
  const {
    text,
    onClick
  } = props;
  const {
    activeBreakpoint
  } = useWindowDimensions();
  return <Button icon="rollbackOutlined" onClick={onClick} testId="goBackButton" data-sentry-element="Button" data-sentry-component="BackButtonBase" data-sentry-source-file="index.tsx">
            {activeBreakpoint === 'xs' || activeBreakpoint === 'sm' ? undefined : text}
        </Button>;
};
export const BackButton = memo(BackButtonBase);