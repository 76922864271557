import { RightOutlined } from '@ant-design/icons';
import { classNames } from '@fl/cmsch-fe-library';
import React, { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { ResetButton } from '../reset-button';
import styles from './styles.sass';
interface Props {
  title: string;
  id?: number;
  isActive?: boolean;
  extra?: ReactNode;
  children?: ReactNode;
  extraActions?: ReactNode;
  resetValues?(id: number): void;
}
export const OurCollapse: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    id,
    title,
    isActive,
    extraActions,
    resetValues
  } = props;
  const [open, setOpen] = useState(isActive);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const handleResetValues = useCallback(() => {
    if (id !== undefined) resetValues?.(id);
  }, [id, resetValues]);
  return <div className={styles.collapse} data-sentry-component="OurCollapse" data-sentry-source-file="index.tsx">
            <div className={classNames(styles.header, 'flex-wrap')}>
                <span onClick={toggleOpen} className={styles.headerTitle}>
                    <span className={open ? styles.openArrow : styles.closedArrow}>
                        <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="index.tsx" />
                    </span>
                    <span className="ml-3">{title}</span>
                </span>
                {open && <div className={styles.actions}>
                        {resetValues && <ResetButton onClick={handleResetValues} />}
                        {extraActions}
                    </div>}
            </div>
            <div className={open ? styles.uncollapsedContent : styles.collapsedContent}>
                {children}
            </div>
        </div>;
};