// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Calf, CalfSchema } from 'api/gen/Calf';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';

const requiredPart = t.interface({
  content: t.union([t.array(CalfSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelCalfSchema = excess(requiredPart);

export interface PagedModelCalf extends t.TypeOf<typeof PagedModelCalfSchema> {}
