import { classNames, isStringArray } from '@fl/cmsch-fe-library';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { initial, isEmpty } from 'lodash/fp';
import React, { useCallback, useEffect, useState, memo, Key, ReactInstance, ReactNode, FC } from 'react';
import { Link } from 'react-router-dom';
import { opt, optEmptyArray } from 'ts-opt';
import { logger } from 'app/sentry-logger';
import { useOurTranslation } from 'app/translations';
import { Ant } from 'common/ant';
import { IconName } from 'common/icons';
import { MenuItem } from 'common/layout/types/menu-item';
import { getLabel } from './main-menu-item';
import styles from './styles.sass';

// in current v. of AntD 4.6.5, suddenly open menu can return object supposedly, but that doesn't happen actually
type OpenEventHandler = (keys: Array<Key> | {
  key: Key;
  item: ReactInstance;
  trigger: string;
  open: boolean;
}) => void;
interface Props {
  menuItems: Array<MenuItem>;
  locationPath: string;
  isUserLoading: boolean;
  isSideMenuCollapsed?: boolean;
  onMenuItemClick?(): void;
}
const getSideMenuLabel = (label: string, isSubMenu: boolean, icon?: IconName, hasSubItems?: boolean, path?: string): ReactNode => hasSubItems ? getLabel(label, isSubMenu, icon) : <Link to={opt(path).orCrash('no path in leaf item')}>
            {getLabel(label, isSubMenu, icon)}
        </Link>;
const buildMenuItems = (onMenuItemClick: () => void, isSubMenu?: boolean) => (menuItems: Array<MenuItem>): Array<ItemType> => menuItems.map((menuItem: MenuItem) => {
  const {
    label,
    key,
    path,
    icon,
    subMenuItems
  } = menuItem;
  const menuItemLabel = getSideMenuLabel(label, isSubMenu || false, icon, !isEmpty(subMenuItems), path);
  return {
    key,
    label: menuItemLabel,
    path,
    children: optEmptyArray(subMenuItems).map(buildMenuItems(onMenuItemClick, true)).orUndef()
  };
});
const getKeysForPath = (menuItems: Array<MenuItem>, path: string): Array<string> | undefined => {
  for (const item of menuItems) {
    if (item.path === path) {
      return [item.key];
    } else if (item.subMenuItems) {
      const subMenuKeys = getKeysForPath(item.subMenuItems, path);
      if (subMenuKeys) {
        return [item.key, ...subMenuKeys];
      }
    }
  }
  return undefined;
};
const SideMenuBase: FC<Props> = props => {
  const {
    locationPath,
    menuItems,
    isUserLoading,
    isSideMenuCollapsed,
    onMenuItemClick
  } = props;
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const {
    t
  } = useOurTranslation('user/login');
  useEffect(() => {
    const pathKeys = getKeysForPath(menuItems, locationPath) ?? [];
    setSelectedKeys(pathKeys);
    setOpenKeys(initial(pathKeys));
  }, [menuItems, locationPath]);
  const handleMenuItemClick = useCallback(() => {
    if (onMenuItemClick) {
      onMenuItemClick();
    }
  }, [onMenuItemClick]);
  const handleOpenChange: OpenEventHandler = useCallback(value => {
    if (isStringArray(value)) setOpenKeys(value); // should be always string array
    else logger.logError(new Error('Menu open keys value is not string array'));
  }, []);
  return <div className={classNames(styles.menuContainer, styles.sideMenu)} data-sentry-component="SideMenuBase" data-sentry-source-file="side-menu.tsx">

            {isUserLoading ? <div className={styles.loadingHeader}>{t('loadingUser')}...</div> : <div className={styles.antMenu}>
                        <Ant.Menu data-test-id="menu-layout-sider-menu" mode="inline" theme="dark" selectedKeys={selectedKeys} onClick={handleMenuItemClick} onOpenChange={handleOpenChange} openKeys={openKeys} items={!isSideMenuCollapsed ? buildMenuItems(handleMenuItemClick)(menuItems) : undefined}
      // open keys must not be set at all (not even undefined),otherwise menu bugs out in desktop mode
      />
                    </div>}
        </div>;
};
export const SideMenu = memo(SideMenuBase);