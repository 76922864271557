// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: BULL

export const GridCodeSchema = t.keyof({
  BULL: null,
  CALF: null,
  COW: null,
  STABLE: null,
  MILKABILITY: null,
  APC: null,
  PERMISSION: null,
  ROLE: null,
  ROLE_PERMISSION: null,
  ROLE_PERMISSION_UNASSIGNED: null,
  ROLE_SUBORDINATE: null,
  ROLE_SUBORDINATE_UNASSIGNED: null,
  USER: null,
  USER_SUBJECT_MEMBER: null,
  USER_ASSOCIATION_ROLE: null,
  USER_BREEDER_ROLE: null,
  USER_CONTACT: null,
  USER_OTHER_SUBJECT_ROLE: null,
  USER_ORGANIZATION_ROLE: null,
});

export type GridCode = t.TypeOf<typeof GridCodeSchema>;
