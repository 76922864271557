// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  apmv: t.union([t.number, t.null]),
});

export const ApmvResponseSchema = excess(requiredPart);

export interface ApmvResponse extends t.TypeOf<typeof ApmvResponseSchema> {}
