// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AssociationName, AssociationNameSchema } from 'api/gen/AssociationName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleApproval, RoleApprovalSchema } from 'api/gen/RoleApproval';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';

const requiredPart = t.interface({
  associationId: PositiveOrZeroIntegerSchema,
  associationName: AssociationNameSchema,
  roles: t.array(RoleApprovalSchema),
  rowId: RowIdSchema,
});

export const AssociationRoleSchema = excess(requiredPart);

export interface AssociationRole extends t.TypeOf<typeof AssociationRoleSchema> {}
