// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  breederIds: t.union([t.array(t.Integer), t.null]),
  districtCodes: t.union([t.array(t.Integer), t.null]),
  farmIds: t.union([t.array(t.Integer), t.null]),
  organizationCenterInsIds: t.union([t.array(t.Integer), t.null]),
  organizationCenterPtIds: t.union([t.array(t.Integer), t.null]),
  organizationInsIds: t.union([t.array(t.Integer), t.null]),
  organizationPtIds: t.union([t.array(t.Integer), t.null]),
});

export const CalfFilterSchema = excess(requiredPart);

export interface CalfFilter extends t.TypeOf<typeof CalfFilterSchema> {}
