// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDefinitionBase, CriteriaDefinitionBaseSchema } from 'api/gen/CriteriaDefinitionBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { IntegerCriteriaValues, IntegerCriteriaValuesSchema } from 'api/gen/IntegerCriteriaValues';

const requiredPart = t.interface({
  tag: t.literal('INT'),
  values: IntegerCriteriaValuesSchema,
});

export const IntegerCriteriaDefinitionSchema = excess(t.intersection([CriteriaDefinitionBaseSchema.type, requiredPart]));

export interface IntegerCriteriaDefinition extends t.TypeOf<typeof IntegerCriteriaDefinitionSchema> {}
