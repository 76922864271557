// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ScoringTraitWeight, ScoringTraitWeightSchema } from 'api/gen/ScoringTraitWeight';

const requiredPart = t.interface({
  ideal: t.union([t.Integer, t.null]),
  weight: ScoringTraitWeightSchema,
});

export const ExteriorTraitIdealWeightSchema = excess(requiredPart);

export interface ExteriorTraitIdealWeight extends t.TypeOf<typeof ExteriorTraitIdealWeightSchema> {}
