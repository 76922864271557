// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CriteriaDefinitionBase, CriteriaDefinitionBaseSchema } from 'api/gen/CriteriaDefinitionBase';

const requiredPart = t.interface({
  tag: t.literal('BOOL'),
  value: t.union([t.boolean, t.null]),
});

export const BooleanCriteriaDefinitionSchema = excess(t.intersection([CriteriaDefinitionBaseSchema.type, requiredPart]));

export interface BooleanCriteriaDefinition extends t.TypeOf<typeof BooleanCriteriaDefinitionSchema> {}
