// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Row version value for optimistic locking.
// Example: "0000000062abd3dd"

export const rowVersionRegexGen = (): RegExp => new RegExp("^[a-fA-F0-9]{16}$");

export const RowVersionSchema = StringPatternSchema<RowVersion>(rowVersionRegexGen());

export type RowVersion = string;
