// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTrait, AnimalTraitSchema } from 'api/gen/AnimalTrait';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalTraitInteger, AnimalTraitIntegerSchema } from 'api/gen/AnimalTraitInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha13, BreedAlpha13Schema } from 'api/gen/BreedAlpha13';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FarmCode, FarmCodeSchema } from 'api/gen/FarmCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Genotype, GenotypeSchema } from 'api/gen/Genotype';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinAnimalInListBase, HolsteinAnimalInListBaseSchema } from 'api/gen/HolsteinAnimalInListBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Lactation, LactationSchema } from 'api/gen/Lactation';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkProduction, MilkProductionSchema } from 'api/gen/MilkProduction';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OverallClass, OverallClassSchema } from 'api/gen/OverallClass';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';

const requiredPart = t.interface({
  bodyStructure: t.union([AnimalTraitIntegerSchema, t.null]),
  breedAlpha13: t.union([BreedAlpha13Schema, t.null]),
  extremities: t.union([AnimalTraitIntegerSchema, t.null]),
  farmCode: FarmCodeSchema,
  fatKg: t.union([AnimalTraitIntegerSchema, t.null]),
  fatPercentage: t.union([AnimalTraitSchema, t.null]),
  genotype: t.union([GenotypeSchema, t.null]),
  lactation: t.union([LactationSchema, t.null]),
  lactationDays: t.union([PositiveOrZeroIntegerSchema, t.null]),
  lastCalvingAt: t.union([DateSchema, t.null]),
  lastMatingDate: t.union([DateSchema, t.null]),
  milkKg: t.union([MilkProductionSchema, t.null]),
  milkStrength: t.union([AnimalTraitIntegerSchema, t.null]),
  overallClass: t.union([OverallClassSchema, t.null]),
  overallCondition: t.union([AnimalTraitIntegerSchema, t.null]),
  pregnant: t.boolean,
  proteinKg: t.union([AnimalTraitIntegerSchema, t.null]),
  proteinPercentage: t.union([AnimalTraitSchema, t.null]),
  redIndex: t.boolean,
  udder: t.union([AnimalTraitIntegerSchema, t.null]),
});

export const FemaleHolsteinAnimalInListSchema = excess(t.intersection([HolsteinAnimalInListBaseSchema.type, requiredPart]));

export interface FemaleHolsteinAnimalInList extends t.TypeOf<typeof FemaleHolsteinAnimalInListSchema> {}
