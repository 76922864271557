// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Number of days between examination and calving.
// Example: 100

export const daysBetweenExamAndCalvingMinimum = 50.0; // inclusive
export const daysBetweenExamAndCalvingMaximum = 180.0; // inclusive

export const DaysBetweenExamAndCalvingSchema = t.refinement(t.Integer, x => x >= daysBetweenExamAndCalvingMinimum && x <= daysBetweenExamAndCalvingMaximum, 'DaysBetweenExamAndCalving');

export type DaysBetweenExamAndCalving = t.TypeOf<typeof DaysBetweenExamAndCalvingSchema>;
