// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Interval between milking (in hours) - valid values 0 - 8
// Example: 5

export const milkingIntervalMinimum = 0.0; // inclusive
export const milkingIntervalMaximum = 8.0; // inclusive

export const MilkingIntervalSchema = t.refinement(t.Integer, x => x >= milkingIntervalMinimum && x <= milkingIntervalMaximum, 'MilkingInterval');

export type MilkingInterval = t.TypeOf<typeof MilkingIntervalSchema>;
