// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateOrganizationRoleBase, ValidateOrganizationRoleBaseSchema } from 'api/gen/ValidateOrganizationRoleBase';

const requiredPart = t.interface({
  tag: t.literal('ValidateCreateOrganizationRole'),
  approvedFrom: DateSchema,
  farmIds: t.union([t.array(PositiveIntegerSchema), t.null]),
  organizationCenterIds: t.union([t.array(PositiveIntegerSchema), t.null]),
  organizationId: PositiveIntegerSchema,
  roleIds: t.array(PositiveIntegerSchema),
  userProfileId: PositiveIntegerSchema,
});

export const ValidateCreateOrganizationRoleSchema = excess(t.intersection([ValidateOrganizationRoleBaseSchema.type, requiredPart]));

export interface ValidateCreateOrganizationRole extends t.TypeOf<typeof ValidateCreateOrganizationRoleSchema> {}
