// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleNameV1, RoleNameV1Schema } from 'api/gen/RoleNameV1';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Uuid, UuidSchema } from 'api/gen/Uuid';

const requiredPart = t.interface({
  id: UuidSchema,
  name: RoleNameV1Schema,
  permissionIds: t.array(PositiveIntegerSchema),
});

export const RoleV1Schema = excess(requiredPart);

export interface RoleV1 extends t.TypeOf<typeof RoleV1Schema> {}
