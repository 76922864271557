// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Milk value boundaries in kilograms.
// Example: 20.0

export const milkKgMinimum = 0.0; // inclusive
export const milkKgMaximum = 98.9; // inclusive
export const milkKgMinimumInclusive = 0.0;
export const milkKgMaximumInclusive = 98.9;
export const milkKgMinMaxInclusive: [number | null, number | null] = [0.0, 98.9];
export const milkKgDecimalPlaces = 2;
export const milkKgRegexGen = new RegExp("^\\d{1,2}[,.]\\d{2}$");

export const MilkKgSchema = t.refinement(t.number, x => x >= milkKgMinimum && x <= milkKgMaximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'MilkKg');

export type MilkKg = t.TypeOf<typeof MilkKgSchema>;
