// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Direction, DirectionSchema } from 'api/gen/Direction';

const requiredPart = t.interface({
  direction: DirectionSchema,
  field: t.string,
});

export const SortSettingsSchema = excess(requiredPart);

export interface SortSettings extends t.TypeOf<typeof SortSettingsSchema> {}
