import { classNames } from '@fl/cmsch-fe-library';
import React, { FC, memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.sass';
interface Props {
  appLogo: ReactNode;
  actionIcons: ReactNode;
}
const SmallMenuBase: FC<Props> = props => {
  const {
    appLogo,
    actionIcons
  } = props;
  return <div className="position-relative" data-sentry-component="SmallMenuBase" data-sentry-source-file="small-menu.tsx">
            <Link to="/" data-sentry-element="Link" data-sentry-source-file="small-menu.tsx">
                <div className={classNames(styles.smallMenuLogo, 'position-absolute')}>
                    {appLogo}
                </div>
            </Link>
            <div className="d-flex">
                <div className="ml-auto">
                    {actionIcons}
                </div>
            </div>
        </div>;
};
export const SmallMenu = memo(SmallMenuBase);