// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  dataSource: t.string,
  database: t.string,
  deployDate: t.string,
  version: t.string,
});

export const DatabaseInfoSchema = excess(requiredPart);

export interface DatabaseInfo extends t.TypeOf<typeof DatabaseInfoSchema> {}
