import { head } from 'lodash/fp';
import React, { SyntheticEvent, useRef, memo, useCallback } from 'react';
import { Button } from '../button';
import styles from './styles.sass';
interface OuterProps {
  id: string;
  title: string;
  fileTypes: string;
  block?: boolean;
  testId?: string;
  disabled?: boolean;
  uploadFile(file: File): void;
}
type Props = OuterProps;
const UploadButtonBase: React.FC<Props> = ({
  id,
  title,
  fileTypes,
  block,
  uploadFile,
  testId,
  disabled
}) => {
  const inputCsv = useRef<HTMLInputElement>(null);
  const onFileChange = useCallback((data: SyntheticEvent<HTMLInputElement>): void => {
    const elem = data.currentTarget;
    if (!elem.files?.[0]) {
      throw new Error('Missing file on input.');
    }
    const file = head(elem.files);
    if (file !== undefined) {
      uploadFile(file);
    }
    elem.value = '';
  }, [uploadFile]);
  const handleClick = useCallback(() => {
    if (inputCsv.current) {
      inputCsv.current.click();
    }
  }, [inputCsv]);
  return <React.Fragment>
            <label onClick={handleClick} htmlFor={id} className={styles.label}>
                <Button block={block} testId={testId} disabled={disabled} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    {title}
                </Button>
            </label>
            <input type="file" id={id} ref={inputCsv} accept={fileTypes} onChange={onFileChange} className={styles.hiddenInput} data-test-id={id} />
        </React.Fragment>;
};
export const UploadButton = memo(UploadButtonBase);