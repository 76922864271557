// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleCode, RoleCodeSchema } from 'api/gen/RoleCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleName, RoleNameSchema } from 'api/gen/RoleName';

const requiredPart = t.interface({
  code: t.union([RoleCodeSchema, t.null]),
  name: t.union([RoleNameSchema, t.null]),
});

export const ValidateRoleSchema = excess(requiredPart);

export interface ValidateRole extends t.TypeOf<typeof ValidateRoleSchema> {}
