// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Operator, OperatorSchema } from 'api/gen/Operator';

const requiredPart = t.interface({
  code: t.string,
  operator: OperatorSchema,
  tag: t.string,
});

export const CriteriaFilterBaseSchema = excess(requiredPart);

export interface CriteriaFilterBase extends t.TypeOf<typeof CriteriaFilterBaseSchema> {}
