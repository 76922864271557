// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A documentary comment about a permission
// Example: "Informace o systému"

export const permissionCommentV2MinLength = 1;
export const permissionCommentV2MaxLength = 256;

export const PermissionCommentV2Schema = t.refinement(t.string, n => n.length >= permissionCommentV2MinLength && n.length <= permissionCommentV2MaxLength, 'PermissionCommentV2');

export type PermissionCommentV2 = string;
