// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BullResult, BullResultSchema } from 'api/gen/BullResult';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';

const requiredPart = t.interface({
  bulls: t.union([t.array(BullResultSchema), t.null]),
  earTag: EarTagSchema,
});

export const ScoringResultSchema = excess(requiredPart);

export interface ScoringResult extends t.TypeOf<typeof ScoringResultSchema> {}
