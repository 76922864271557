// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BooleanCriteriaValues, BooleanCriteriaValuesSchema } from 'api/gen/BooleanCriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateCriteriaValues, DateCriteriaValuesSchema } from 'api/gen/DateCriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal31CriteriaValues, Decimal31CriteriaValuesSchema } from 'api/gen/Decimal31CriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43CriteriaValues, Decimal43CriteriaValuesSchema } from 'api/gen/Decimal43CriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52CriteriaValues, Decimal52CriteriaValuesSchema } from 'api/gen/Decimal52CriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { IntegerCriteriaValues, IntegerCriteriaValuesSchema } from 'api/gen/IntegerCriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SingleDecimal43CriteriaValues, SingleDecimal43CriteriaValuesSchema } from 'api/gen/SingleDecimal43CriteriaValues';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SingleIntegerCriteriaValues, SingleIntegerCriteriaValuesSchema } from 'api/gen/SingleIntegerCriteriaValues';

export const CriteriaValuesSchema = t.union([BooleanCriteriaValuesSchema, DateCriteriaValuesSchema, Decimal31CriteriaValuesSchema, Decimal43CriteriaValuesSchema, Decimal52CriteriaValuesSchema, IntegerCriteriaValuesSchema, SingleIntegerCriteriaValuesSchema, SingleDecimal43CriteriaValuesSchema]);

export type CriteriaValues = t.TypeOf<typeof CriteriaValuesSchema>;
