// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  id: PositiveIntegerSchema,
  label: t.string,
});

export const AvailableAnimalSchema = excess(requiredPart);

export interface AvailableAnimal extends t.TypeOf<typeof AvailableAnimalSchema> {}
