import { zip } from 'lodash/fp';
import React, { FC, memo, useMemo } from 'react';
import styles from './styles.sass';
interface Props {
  labels: Array<string>;
  values: Array<string | number>;
}
const BasicDetailInfoBase: FC<Props> = ({
  labels,
  values
}) => {
  const headers = useMemo(() => labels.map(label => <td key={label}>{label}</td>), [labels]);
  const headersWithValues = useMemo(() => zip(labels, values).map(([label, value]) => <td key={label} className={styles.cellWrapper}>
            <div className={styles.header}>{label}</div>
            <div>{value}</div>
        </td>), [labels, values]);
  return <div className={styles.container} data-sentry-component="BasicDetailInfoBase" data-sentry-source-file="index.tsx">
            <table className={styles.tableStyles}>
                <tbody>
                    <tr className={styles.headerStyles}>
                        {headers}
                    </tr>
                    <tr className={styles.valueStyles}>
                        {headersWithValues}
                    </tr>
                </tbody>
            </table>
        </div>;
};
export const BasicDetailInfo = memo(BasicDetailInfoBase);