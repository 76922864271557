// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BooleanCriteriaDetails, BooleanCriteriaDetailsSchema } from 'api/gen/BooleanCriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateCriteriaDetails, DateCriteriaDetailsSchema } from 'api/gen/DateCriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal31CriteriaDetails, Decimal31CriteriaDetailsSchema } from 'api/gen/Decimal31CriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal43CriteriaDetails, Decimal43CriteriaDetailsSchema } from 'api/gen/Decimal43CriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Decimal52CriteriaDetails, Decimal52CriteriaDetailsSchema } from 'api/gen/Decimal52CriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { IntegerCriteriaDetails, IntegerCriteriaDetailsSchema } from 'api/gen/IntegerCriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SingleDecimal43CriteriaDetails, SingleDecimal43CriteriaDetailsSchema } from 'api/gen/SingleDecimal43CriteriaDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SingleIntegerCriteriaDetails, SingleIntegerCriteriaDetailsSchema } from 'api/gen/SingleIntegerCriteriaDetails';

export const CriteriaDetailsSchema = t.union([BooleanCriteriaDetailsSchema, DateCriteriaDetailsSchema, Decimal31CriteriaDetailsSchema, Decimal43CriteriaDetailsSchema, Decimal52CriteriaDetailsSchema, IntegerCriteriaDetailsSchema, SingleIntegerCriteriaDetailsSchema, SingleDecimal43CriteriaDetailsSchema]);

export type CriteriaDetails = t.TypeOf<typeof CriteriaDetailsSchema>;
