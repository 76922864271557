// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkedTotalRange, MilkedTotalRangeSchema } from 'api/gen/MilkedTotalRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingTimeRange, MilkingTimeRangeSchema } from 'api/gen/MilkingTimeRange';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TechnicianNumber, TechnicianNumberSchema } from 'api/gen/TechnicianNumber';

const requiredPart = t.interface({
  earTag: t.union([EarTagSchema, t.null]),
  examinationDate: t.union([DateSchema, t.null]),
  milkedTotal: t.union([MilkedTotalRangeSchema, t.null]),
  milkingTime: t.union([MilkingTimeRangeSchema, t.null]),
  tag: t.string,
  technicianNumber: t.union([TechnicianNumberSchema, t.null]),
});

export const ValidateMilkabilityReportBaseSchema = excess(requiredPart);

export interface ValidateMilkabilityReportBase extends t.TypeOf<typeof ValidateMilkabilityReportBaseSchema> {}
