// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleApproval, RoleApprovalSchema } from 'api/gen/RoleApproval';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowId, RowIdSchema } from 'api/gen/RowId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  otherSubjectId: PositiveIntegerSchema,
  otherSubjectName: SubjectBusinessNameSchema,
  roles: t.array(RoleApprovalSchema),
  rowId: RowIdSchema,
});

export const OtherSubjectRoleSchema = excess(requiredPart);

export interface OtherSubjectRole extends t.TypeOf<typeof OtherSubjectRoleSchema> {}
