// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ContactType, ContactTypeSchema } from 'api/gen/ContactType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ContactValue, ContactValueSchema } from 'api/gen/ContactValue';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  contactType: ContactTypeSchema,
  userContactId: PositiveIntegerSchema,
  value: ContactValueSchema,
});

export const UserProfileContactSchema = excess(requiredPart);

export interface UserProfileContact extends t.TypeOf<typeof UserProfileContactSchema> {}
