// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: APP_ROLE

export const AuthDiscriminatorSchema = t.keyof({
  APP_ROLE: null,
  SYS_ADMIN: null,
  REP_ROLE: null,
  DB_ROLE: null,
});

export type AuthDiscriminator = t.TypeOf<typeof AuthDiscriminatorSchema>;
