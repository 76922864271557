// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-layout-components-basic-detail-info-___styles__container___nes6k{padding-bottom:2rem}.src-common-layout-components-basic-detail-info-___styles__tableStyles___R6bUj{font-size:larger}.src-common-layout-components-basic-detail-info-___styles__tableStyles___R6bUj td{padding-right:1rem}@media (max-width: 576px){.src-common-layout-components-basic-detail-info-___styles__tableStyles___R6bUj td{padding-right:0;padding-left:1rem}}.src-common-layout-components-basic-detail-info-___styles__headerStyles___oCohL{opacity:70%;font-size:medium;display:table-row}@media (max-width: 576px){.src-common-layout-components-basic-detail-info-___styles__headerStyles___oCohL{display:none}}.src-common-layout-components-basic-detail-info-___styles__valueStyles___yQJZF{display:table-row}@media (max-width: 576px){.src-common-layout-components-basic-detail-info-___styles__valueStyles___yQJZF{display:flex;flex-direction:column}}.src-common-layout-components-basic-detail-info-___styles__cellWrapper___ljndg{display:table-cell}.src-common-layout-components-basic-detail-info-___styles__cellWrapper___ljndg .src-common-layout-components-basic-detail-info-___styles__header___nXyOJ{display:none;opacity:70%;font-size:medium}@media (max-width: 576px){.src-common-layout-components-basic-detail-info-___styles__cellWrapper___ljndg .src-common-layout-components-basic-detail-info-___styles__header___nXyOJ{display:flex}}
`, "",{"version":3,"sources":["webpack://./src/common/layout/components/basic-detail-info/styles.sass"],"names":[],"mappings":"AAAA,6EAAW,mBAAA,CAAoB,+EAAa,gBAAA,CAAiB,kFAAwB,kBAAA,CAAmB,0BAAyB,kFAAwB,eAAA,CAAgB,iBAAA,CAAA,CAAmB,gFAAc,WAAA,CAAY,gBAAA,CAAiB,iBAAA,CAAkB,0BAAyB,gFAAc,YAAA,CAAA,CAAc,+EAAa,iBAAA,CAAkB,0BAAyB,+EAAa,YAAA,CAAa,qBAAA,CAAA,CAAuB,+EAAa,kBAAA,CAAmB,yJAAqB,YAAA,CAAa,WAAA,CAAY,gBAAA,CAAiB,0BAAyB,yJAAqB,YAAA,CAAA","sourcesContent":[".container{padding-bottom:2rem}.tableStyles{font-size:larger}.tableStyles :global td{padding-right:1rem}@media(max-width: 576px){.tableStyles :global td{padding-right:0;padding-left:1rem}}.headerStyles{opacity:70%;font-size:medium;display:table-row}@media(max-width: 576px){.headerStyles{display:none}}.valueStyles{display:table-row}@media(max-width: 576px){.valueStyles{display:flex;flex-direction:column}}.cellWrapper{display:table-cell}.cellWrapper .header{display:none;opacity:70%;font-size:medium}@media(max-width: 576px){.cellWrapper .header{display:flex}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-common-layout-components-basic-detail-info-___styles__container___nes6k`,
	"tableStyles": `src-common-layout-components-basic-detail-info-___styles__tableStyles___R6bUj`,
	"headerStyles": `src-common-layout-components-basic-detail-info-___styles__headerStyles___oCohL`,
	"valueStyles": `src-common-layout-components-basic-detail-info-___styles__valueStyles___yQJZF`,
	"cellWrapper": `src-common-layout-components-basic-detail-info-___styles__cellWrapper___ljndg`,
	"header": `src-common-layout-components-basic-detail-info-___styles__header___nXyOJ`
};
export default ___CSS_LOADER_EXPORT___;
