// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectBusinessName, SubjectBusinessNameSchema } from 'api/gen/SubjectBusinessName';

const requiredPart = t.interface({
  id: PositiveIntegerSchema,
  otherSubjectName: SubjectBusinessNameSchema,
});

export const OtherSubjectSchema = excess(requiredPart);

export interface OtherSubject extends t.TypeOf<typeof OtherSubjectSchema> {}
