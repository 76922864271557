// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApcDate, ApcDateSchema } from 'api/gen/ApcDate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApcStable, ApcStableSchema } from 'api/gen/ApcStable';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ApcStableDateBase, ApcStableDateBaseSchema } from 'api/gen/ApcStableDateBase';

const requiredPart = t.interface({
  tag: t.literal('ApcDateListWithStables'),
  dateData: t.union([t.array(ApcDateSchema), t.null]),
  stableData: t.union([t.array(ApcStableSchema), t.null]),
});

export const ApcDateListWithStablesSchema = excess(t.intersection([ApcStableDateBaseSchema.type, requiredPart]));

export interface ApcDateListWithStables extends t.TypeOf<typeof ApcDateListWithStablesSchema> {}
