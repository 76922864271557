import {Dictionary, reject, toPairs} from '@fl/cmsch-fe-library';
import {Either, left, match, right} from 'fp-ts/Either';
import {forEach, isString, map} from 'lodash/fp';
import {Opt, id, opt, none, catOpts, isEmpty, pipe, flatMap} from 'ts-opt';

import {Language} from 'api/gen/Language';
import {logger} from 'app/sentry-logger';

// eslint-disable-next-line import/no-unused-modules
export type ErrorMessage = string;
type TranslationKey = string;
// eslint-disable-next-line import/no-unused-modules
export type TranslationValue = string;
type LanguageValidationFunction =
    (key: string, value: string, lang: Language) => Either<Array<ErrorMessage>, TranslationValue>;
// eslint-disable-next-line import/no-unused-modules
export type ValidationFunction = (key: string, value: string) => Either<ErrorMessage, TranslationValue>;

const ignoreListByLanguage: Record<Language, Array<TranslationKey>> = ({
    CS: [
        'beErrors.beErrors.connection',
        'beErrors.beErrors.login',
        'beErrors.beErrors.unknown',
        'user/withUser.loginAsAnother',
    ],
    EN: [],
});

export const validatePreposition: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?:(^| ))([KOSUVZkosuvz]|do|ke|na|od|po|se|ve|za|ze)(?= )', 'gu');
        const matched = val.match(regex);

        return matched
            ? left(`Missing non-breaking space at Preposition: \
${opt(matched).at(0).orCrash('Missing matched string.')}`)
            : right(val);
    };

export const validateConjunction: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?: )([ai])(?= )');
        const matched = val.match(regex);

        return matched
            ? left(`Missing non-breaking space at Conjunction: \
${opt(matched).at(0).orCrash('Missing matched string.')}`)
            : right(val);
    };

export const validateAbbreviation: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?: )(tj\\.|tzn\\.|tzv\\.|aj\\.|mj\\.)(?= )');
        const matched = val.match(regex);

        return matched
            ? left(`Missing non-breaking space at Abbreviation: \
${opt(matched).at(0).orCrash('Missing matched string.')}`)
            : right(val);
    };

export const validateUnits: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?:\\d )(hod|mg|kg|h|l|ml|°C|Kč|\\p{Sc})(?=(\\b| ))', 'gu');
        const matched = val.match(regex);

        return matched
            ? left(`Missing non-breaking space before Units: ${opt(matched).at(0).orCrash('Missing matched string.')}`)
            : right(val);
    };

export const validateHyphen: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?:[\\p{L}]( | )+)-(?=( | )+[\\p{L}])', 'gu');
        const matched = val.match(regex);

        return matched
            ? left(`Possible misuse of hyphen(Spojovník): ${opt(matched).at(0).orCrash('Missing matched string.')} \
consider using:‐ or removing surrounding spaces.`)
            : right(val);
    };

export const validateEllipsis: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?:\\w(\\.{3})+)');
        const matched = val.match(regex);

        return matched
            ? left(`Possible misuse of dot dot dot ellipsis: ${opt(matched).at(0).orCrash('Missing matched string.')} \
consider using:…`)
            : right(val);
    };

export const validateOrdinalNumerals: ValidationFunction =
    (key, val) => {
        const regex = new RegExp('(?:[\\p{L}](\\.| | ){1})\\d(?=(\\.){1}[\\p{L}])', 'gu');
        const matched = val.match(regex);

        return matched
            ? left(`Possible misuse of ordinal numerals: ${opt(matched).at(0).orCrash('Missing matched string.')}`)
            : right(val);
    };

export const validateQuoteMarks: ValidationFunction =
    (key, val) => {
        const regex = /(“|")(\w)/g;
        const matched = val.match(regex);

        return matched
            ? left(`Possible misuse of quote marks: ${opt(matched).at(0).orCrash('Missing matched string.')} \
consider using:„“ or ‚‘.`)
            : right(val);
    };

const validationFuctionsByLanguage: Record<Language, Array<ValidationFunction>> = ({
    CS: [
        validatePreposition,
        validateConjunction,
        validateAbbreviation,
        validateUnits,
        validateHyphen,
        validateEllipsis,
        validateOrdinalNumerals,
        validateQuoteMarks,
    ],
    EN: [],
});

const validateOneTranslation: LanguageValidationFunction = (key, value, lang) => {
    const errors = pipe(
        validationFuctionsByLanguage[lang],
        map(f => f(key, value)),
        map(match(opt, (): Opt<TranslationValue> => none)),
        catOpts,
        map(x => `${key}: ${x}`),
    );

    return isEmpty(errors) ? right(value) : left(errors);
};

export const checkTypography =
    (language: Language, translation: Dictionary<TranslationKey, TranslationValue>): void => {
        const processPair = ([k, v]: [string, string | undefined]): Either<Array<ErrorMessage>, TranslationValue> =>
            opt(v)
                .map(nonemptyValue => validateOneTranslation(k, nonemptyValue, language))
                .orElse(left([`Key ${k} has missing value`]));

        pipe(
            toPairs(translation),
            reject(([x]) => ignoreListByLanguage[language].includes(x)),
            x => x.map(processPair),
            flatMap(match(id, () => [])),
            forEach(x => {
                if (isString(x)) logger.logError(new Error(x));
            }),
        );
    };
