// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: "Galileo"

export const animalSearchLabelMinLength = 3;
export const animalSearchLabelMaxLength = 2147483647;

export const AnimalSearchLabelSchema = t.refinement(t.string, n => n.length >= animalSearchLabelMinLength && n.length <= animalSearchLabelMaxLength, 'AnimalSearchLabel');

export type AnimalSearchLabel = string;
