// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: BREEDING_FEMALES

export const AnimalTypeSchema = t.keyof({
  BREEDING_FEMALES: null,
  CALVES: null,
});

export type AnimalType = t.TypeOf<typeof AnimalTypeSchema>;
