// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalCodeName, AnimalCodeNameSchema } from 'api/gen/AnimalCodeName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Inbreeding, InbreedingSchema } from 'api/gen/Inbreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MatingScore, MatingScoreSchema } from 'api/gen/MatingScore';

// Using value or undefined (instead of null) because of memory optimization on BE

const optionalPart = t.partial({
  inbreeding: InbreedingSchema,
  name: AnimalCodeNameSchema,
});

const requiredPart = t.interface({
  lineRegistry: LineRegistrySchema,
  score: MatingScoreSchema,
});

export const BullResultSchema = excess(t.intersection([optionalPart, requiredPart]));

export interface BullResult extends t.TypeOf<typeof BullResultSchema> {}
