// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Milk production represented by integer values greater than or equal to 0.
// Example: 99999

export const milkProductionMinimum = 0.0; // inclusive
export const milkProductionMaximum = 99999.0; // inclusive

export const MilkProductionSchema = t.refinement(t.Integer, x => x >= milkProductionMinimum && x <= milkProductionMaximum, 'MilkProduction');

export type MilkProduction = t.TypeOf<typeof MilkProductionSchema>;
