import {Opt} from 'ts-opt';

import {ModuleGuideUrls} from 'api/gen/ModuleGuideUrls';
import {State} from 'app/setup';

import {LoadingItemId} from '../types';

export const simpleLayoutSelector = {
    isItemLoading: (id: LoadingItemId) => ({layout}: State): boolean => layout.loadingItems.includes(id),
    getTutorialsUrls: ({layout}: State): Opt<ModuleGuideUrls> => layout.commonTutorialsUrls,
};
