// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of a production area in central evidence
// Example: "Horská a podhorská"

export const productionAreaInCENameMinLength = 1;
export const productionAreaInCENameMaxLength = 512;

export const ProductionAreaInCENameSchema = t.refinement(t.string, n => n.length >= productionAreaInCENameMinLength && n.length <= productionAreaInCENameMaxLength, 'ProductionAreaInCEName');

export type ProductionAreaInCEName = string;
