export const layoutTranslations = {
    CS: {
        'layout/cookieModal': {
            title: 'Používáme cookies',
            description: 'Používáme technické cookies pro správné fungování webu, které nelze vypnout. Statistické cookies nám pomáhají zlepšovat obsah tím, že anonymně sledují, jak web používáte. K jejich použití potřebujeme váš souhlas. Můžete jej kdykoliv odvolat nebo změnit v nastavení cookies.',
            acceptAll: 'Povolit vše',
            denyAll: 'Odmítnout vše',
            acceptSelected: 'Povolit vybrané',
            necessary: 'Nezbytné',
            preferential: 'Preferenční',
            statistical: 'Statistické',
            necessaryDescription: 'Nezbytné cookies jsou nezbytné pro správnou funkčnost webu. Jejich použití nelze zakázat.',
            preferentialDescription: 'Preferenční cookies jsou používány pro zapamatování nastavení, které mění vzhled a chování webu. Může se jednat o nastavený jazyk.',
            statisticalDescription: 'Statistické cookies jsou používány pro získání informací pro majitele webu, jak uživatelé web užívají. Tyto informace jsou sbírány anonymně a jsou použity pro zkvalitnění webu.',
        },
        'layout/tutorials': {
            title: 'Načtení návodů',
        },
    },
    EN: {
        'layout/cookieModal': {
            title: 'This website uses cookies',
            description: 'We use technical cookies for the proper functioning of the website, which cannot be turned off. Statistical cookies help us improve content by anonymously tracking how you use the website. We need your consent to use them. You can withdraw or change your consent at any time in the cookie settings.',
            acceptAll: 'Accept all',
            denyAll: 'Deny all',
            acceptSelected: 'Accept selected',
            necessary: 'Necessary',
            preferential: 'Preferential',
            statistical: 'Statistical',
            necessaryDescription: 'The necessary cookies are necessary for the proper functioning of the website. Their use cannot be prohibited.',
            preferentialDescription: 'Preferential cookies are used to remember settings that change the appearance and behavior of the site. This can be a language setting.',
            statisticalDescription: 'Statistical cookies are used to obtain information for website owners on how users use the website. This information is collected anonymously and is used to improve the quality of the site.',
        },
        'layout/tutorials': {
            title: 'Loading tutorials',
        },
    },
};
