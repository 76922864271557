// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ExteriorTraitsWeights, ExteriorTraitsWeightsSchema } from 'api/gen/ExteriorTraitsWeights';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FunctionalTraitsWeights, FunctionalTraitsWeightsSchema } from 'api/gen/FunctionalTraitsWeights';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HealthTraitsWeights, HealthTraitsWeightsSchema } from 'api/gen/HealthTraitsWeights';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Inbreeding, InbreedingSchema } from 'api/gen/Inbreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProductionTraitsWeights, ProductionTraitsWeightsSchema } from 'api/gen/ProductionTraitsWeights';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ScoringTraitWeight, ScoringTraitWeightSchema } from 'api/gen/ScoringTraitWeight';

const requiredPart = t.interface({
  exteriorGroupWeight: ScoringTraitWeightSchema,
  exteriorTraitsWeight: ExteriorTraitsWeightsSchema,
  functionalGroupWeight: ScoringTraitWeightSchema,
  functionalTraitsWeights: FunctionalTraitsWeightsSchema,
  healthGroupWeight: ScoringTraitWeightSchema,
  healthTraitsWeights: HealthTraitsWeightsSchema,
  inbreedingCoefficient: InbreedingSchema,
  productionGroupWeight: ScoringTraitWeightSchema,
  productionTraitsWeights: ProductionTraitsWeightsSchema,
});

export const TraitsWeightsSchema = excess(requiredPart);

export interface TraitsWeights extends t.TypeOf<typeof TraitsWeightsSchema> {}
