// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateBreederRoleBase, ValidateBreederRoleBaseSchema } from 'api/gen/ValidateBreederRoleBase';

const requiredPart = t.interface({
  tag: t.literal('ValidateCreateBreederRole'),
  approvedFrom: DateSchema,
  breederId: PositiveIntegerSchema,
  farmIds: t.union([t.array(PositiveIntegerSchema), t.null]),
  roleIds: t.array(PositiveIntegerSchema),
  userProfileId: PositiveIntegerSchema,
});

export const ValidateCreateBreederRoleSchema = excess(t.intersection([ValidateBreederRoleBaseSchema.type, requiredPart]));

export interface ValidateCreateBreederRole extends t.TypeOf<typeof ValidateCreateBreederRoleSchema> {}
