import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {GetTutorialsAction, layoutAction} from '../action';

function* execute(action: GetTutorialsAction): SagaIterator {
    const response = yield* call(Api.getModuleGuideUrls, action.payload);

    if (response.isSuccess) {
        yield* put(layoutAction.getTutorialsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('layout/tutorials')('title')));
    }
}

export function* getTutorialsSaga(): SagaIterator {
    yield takeLatestF('layout/GET_APP_TUTORIALS', execute);
}
