// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ListName, ListNameSchema } from 'api/gen/ListName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RowVersion, RowVersionSchema } from 'api/gen/RowVersion';

const requiredPart = t.interface({
  created: DateSchema,
  editable: t.boolean,
  gridUserSettingsId: t.union([PositiveIntegerSchema, t.null]),
  id: PositiveIntegerSchema,
  name: ListNameSchema,
  version: RowVersionSchema,
});

export const CriteriaListSchema = excess(requiredPart);

export interface CriteriaList extends t.TypeOf<typeof CriteriaListSchema> {}
