// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Day time - values 1 (evening), 2 (morning), 3 (afternoon)
// Example: 2

export const milkingDayTimeMinimum = 1.0; // inclusive
export const milkingDayTimeMaximum = 3.0; // inclusive

export const MilkingDayTimeSchema = t.refinement(t.Integer, x => x >= milkingDayTimeMinimum && x <= milkingDayTimeMaximum, 'MilkingDayTime');

export type MilkingDayTime = t.TypeOf<typeof MilkingDayTimeSchema>;
