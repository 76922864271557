// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  areAnimalsInSession: t.boolean,
});

export const AnimalInSessionSchema = excess(requiredPart);

export interface AnimalInSession extends t.TypeOf<typeof AnimalInSessionSchema> {}
