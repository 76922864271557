// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateApcBase, ValidateApcBaseSchema } from 'api/gen/ValidateApcBase';

const requiredPart = t.interface({
  correctionType: t.literal('EAR_TAG_UPDATE'),
  updatedEarTag: t.union([EarTagSchema, t.null]),
});

export const ValidateApcEarTagUpdateSchema = excess(t.intersection([ValidateApcBaseSchema.type, requiredPart]));

export interface ValidateApcEarTagUpdate extends t.TypeOf<typeof ValidateApcEarTagUpdateSchema> {}
