// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Up to 3 digits long number representing code of organization center.
// Example: "302"

export const organizationCenterCodeRegexGen = (): RegExp => new RegExp("^[0-9]{1,3}$");

export const OrganizationCenterCodeSchema = StringPatternSchema<OrganizationCenterCode>(organizationCenterCodeRegexGen());

export type OrganizationCenterCode = string;
