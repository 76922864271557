// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';

const requiredPart = t.interface({
  businessName: t.union([t.string, t.null]),
  id: PositiveIntegerSchema,
  numOfAnimals: PositiveOrZeroLongSchema,
  registrationNumber: t.Integer,
});

export const BreederToChooseSchema = excess(requiredPart);

export interface BreederToChoose extends t.TypeOf<typeof BreederToChooseSchema> {}
