import React, { Dispatch, FC, memo, MouseEvent, PropsWithChildren, SetStateAction, useCallback } from 'react';
import { Opt } from 'ts-opt';
import { Ant } from 'common/ant';
import { ICONS } from 'common/icons';
interface Props {
  value: Opt<boolean>;
  label: string;
  description: string;
  disabled?: boolean;
  setSelected?: Dispatch<SetStateAction<boolean>>;
  testId?: string;
}
const SwitchBase: FC<PropsWithChildren<Props>> = props => {
  const {
    value,
    label,
    disabled,
    description,
    setSelected,
    testId
  } = props;
  const onChange = useCallback((checked: boolean, event: MouseEvent<HTMLButtonElement>) => {
    setSelected?.(checked);
    event.stopPropagation();
  }, [setSelected]);
  return <Ant.Row data-sentry-element="unknown" data-sentry-component="SwitchBase" data-sentry-source-file="switch.tsx">
            <Ant.Col xs={24} data-sentry-element="unknown" data-sentry-source-file="switch.tsx">
                <div className="mt-2 overflow-hidden">
                    <div className="float-left d-flex align-items-center">
                        {label}
                        <span className="d-flex ml-2" title={description}>
                            {ICONS.questionCircleFilled}
                        </span>
                    </div>
                    <div className="float-right">
                        <Ant.Switch checked={value.orFalse()} disabled={disabled} onChange={onChange} data-test-id={testId} data-sentry-element="unknown" data-sentry-source-file="switch.tsx" />
                    </div>
                </div>
            </Ant.Col>
        </Ant.Row>;
};
export const Switch = memo(SwitchBase);