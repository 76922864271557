// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CorrectionType, CorrectionTypeSchema } from 'api/gen/CorrectionType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingCount, MilkingCountSchema } from 'api/gen/MilkingCount';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingDayTime, MilkingDayTimeSchema } from 'api/gen/MilkingDayTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MilkingInterval, MilkingIntervalSchema } from 'api/gen/MilkingInterval';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal52, PositiveOrZeroDecimal52Schema } from 'api/gen/PositiveOrZeroDecimal52';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroDecimal80, PositiveOrZeroDecimal80Schema } from 'api/gen/PositiveOrZeroDecimal80';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableCode, StableCodeSchema } from 'api/gen/StableCode';

const requiredPart = t.interface({
  correctionType: CorrectionTypeSchema,
  earTag: EarTagSchema,
  fatPercentage: PositiveOrZeroDecimal52Schema,
  id: PositiveIntegerSchema,
  lactosePercentage: PositiveOrZeroDecimal52Schema,
  milkKg: PositiveOrZeroDecimal52Schema,
  milkingCount: t.union([MilkingCountSchema, t.null]),
  milkingDayTime: t.union([MilkingDayTimeSchema, t.null]),
  milkingInterval: t.union([MilkingIntervalSchema, t.null]),
  proficiencyTestDate: DateSchema,
  proteinPercentage: PositiveOrZeroDecimal52Schema,
  somaticCells: PositiveOrZeroDecimal80Schema,
  stableCode: StableCodeSchema,
  technicianNumber: t.string,
  updatedEarTag: t.union([EarTagSchema, t.null]),
  urea: PositiveOrZeroDecimal52Schema,
});

export const CorrectionReportDetailSchema = excess(requiredPart);

export interface CorrectionReportDetail extends t.TypeOf<typeof CorrectionReportDetailSchema> {}
