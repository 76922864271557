// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A username
// Example: "novak"

export const usernameRegexGen = (): RegExp => new RegExp("^[0-9a-zA-Z@_.]+$");

export const usernameMinLength = 1;
export const usernameMaxLength = 256;

export const UsernameSchema = t.refinement(StringPatternSchema<Username>(usernameRegexGen()), n => n.length >= usernameMinLength && n.length <= usernameMaxLength, 'Username');

export type Username = string;
