// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Animal trait represented by integer values greater than or equal to 0.
// Example: 0

export const animalTraitIntegerMinimum = 0.0; // inclusive
export const animalTraitIntegerMaximum = 999.0; // inclusive

export const AnimalTraitIntegerSchema = t.refinement(t.Integer, x => x >= animalTraitIntegerMinimum && x <= animalTraitIntegerMaximum, 'AnimalTraitInteger');

export type AnimalTraitInteger = t.TypeOf<typeof AnimalTraitIntegerSchema>;
