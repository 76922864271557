// Custom schema from src/api/custom/DateTime.ts

/* eslint-disable unicorn/filename-case */
// Mandatory for pnpm gen-api command
import {DatetimeStringSchema} from '@fl/cmsch-fe-library';

export const DateTimeSchema = DatetimeStringSchema;

export type DateTime = string;


