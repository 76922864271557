// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateApcBase, CreateApcBaseSchema } from 'api/gen/CreateApcBase';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTag, EarTagSchema } from 'api/gen/EarTag';

const requiredPart = t.interface({
  correctionType: t.literal('EAR_TAG_UPDATE'),
  updatedEarTag: EarTagSchema,
});

export const CreateApcEarTagUpdateSchema = excess(t.intersection([CreateApcBaseSchema.type, requiredPart]));

export interface CreateApcEarTagUpdate extends t.TypeOf<typeof CreateApcEarTagUpdateSchema> {}
