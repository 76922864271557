// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// A section in the studbook.
// Example: "PCB"

export const studbookSectionMinLength = 1;
export const studbookSectionMaxLength = 3;

export const StudbookSectionSchema = t.refinement(t.string, n => n.length >= studbookSectionMinLength && n.length <= studbookSectionMaxLength, 'StudbookSection');

export type StudbookSection = string;
