// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Animal trait represented by floating point values greater than or equal to 0 with format decimal(5, 2).
// Example: 5.0e-2

export const animalTraitMinimum = -1000.0; // exclusive
export const animalTraitMaximum = 1000.0; // exclusive
export const animalTraitMinimumInclusive = -999.99;
export const animalTraitMaximumInclusive = 999.99;
export const animalTraitMinMaxInclusive: [number | null, number | null] = [-999.99, 999.99];
export const animalTraitDecimalPlaces = 2;
export const animalTraitRegexGen = new RegExp("^-?\\d{1,3}[,.]\\d{2}$");

export const AnimalTraitSchema = t.refinement(t.number, x => x > animalTraitMinimum && x < animalTraitMaximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'AnimalTrait');

export type AnimalTrait = t.TypeOf<typeof AnimalTraitSchema>;
