// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateCreateOrganizationRole, ValidateCreateOrganizationRoleSchema } from 'api/gen/ValidateCreateOrganizationRole';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ValidateUpdateOrganizationRole, ValidateUpdateOrganizationRoleSchema } from 'api/gen/ValidateUpdateOrganizationRole';

export const ValidateOrganizationRoleSchema = t.union([ValidateCreateOrganizationRoleSchema, ValidateUpdateOrganizationRoleSchema]);

export type ValidateOrganizationRole = t.TypeOf<typeof ValidateOrganizationRoleSchema>;
