// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Animal trait represented by floating point values with format decimal(5, 2).
// Example: 5.0e-2

export const animalTraitBvMinimum = -1000.0; // exclusive
export const animalTraitBvMaximum = 1000.0; // exclusive
export const animalTraitBvMinimumInclusive = -999.99;
export const animalTraitBvMaximumInclusive = 999.99;
export const animalTraitBvMinMaxInclusive: [number | null, number | null] = [-999.99, 999.99];
export const animalTraitBvDecimalPlaces = 2;
export const animalTraitBvRegexGen = new RegExp("^-?\\d{1,3}[,.]\\d{2}$");

export const AnimalTraitBvSchema = t.refinement(t.number, x => x > animalTraitBvMinimum && x < animalTraitBvMaximum && Number.isInteger(parseFloat((x / 0.01).toFixed(10))), 'AnimalTraitBv');

export type AnimalTraitBv = t.TypeOf<typeof AnimalTraitBvSchema>;
