// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// First name of the user.
// Example: "Jitka"

export const firstNameMinLength = 1;
export const firstNameMaxLength = 256;

export const FirstNameSchema = t.refinement(t.string, n => n.length >= firstNameMinLength && n.length <= firstNameMaxLength, 'FirstName');

export type FirstName = string;
