// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// General scoring trait weight represented by integer values greater than or equal to 0.
// Example: 50

export const scoringTraitWeightMinimum = 0.0; // inclusive
export const scoringTraitWeightMaximum = 100.0; // inclusive

export const ScoringTraitWeightSchema = t.refinement(t.Integer, x => x >= scoringTraitWeightMinimum && x <= scoringTraitWeightMaximum, 'ScoringTraitWeight');

export type ScoringTraitWeight = t.TypeOf<typeof ScoringTraitWeightSchema>;
