// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AuthDiscriminator, AuthDiscriminatorSchema } from 'api/gen/AuthDiscriminator';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleCode, RoleCodeSchema } from 'api/gen/RoleCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RoleName, RoleNameSchema } from 'api/gen/RoleName';

const requiredPart = t.interface({
  code: RoleCodeSchema,
  discriminator: AuthDiscriminatorSchema,
  name: RoleNameSchema,
  userEnable: t.boolean,
});

export const CreateOrUpdateRoleSchema = excess(requiredPart);

export interface CreateOrUpdateRole extends t.TypeOf<typeof CreateOrUpdateRoleSchema> {}
