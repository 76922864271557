// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  approvedTo: t.union([DateSchema, t.null]),
  userProfileAssociationRoleIds: t.array(PositiveIntegerSchema),
});

export const UpdateUserProfileAssociationRoleSchema = excess(requiredPart);

export interface UpdateUserProfileAssociationRole extends t.TypeOf<typeof UpdateUserProfileAssociationRoleSchema> {}
