// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  tag: t.string,
});

export const ApcStableDateBaseSchema = excess(requiredPart);

export interface ApcStableDateBase extends t.TypeOf<typeof ApcStableDateBaseSchema> {}
