// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The 11 characters long code of a farm. The code consists from 2-letter country code, blank space and 8-digits code.
// Example: "CZ 00123456"

export const farmCodeRegexGen = (): RegExp => new RegExp("^CZ [0-9]{8}$");

export const FarmCodeSchema = StringPatternSchema<FarmCode>(farmCodeRegexGen());

export type FarmCode = string;
