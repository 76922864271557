// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of a farm.
// Example: "Bělokozly, 13"

export const farmNameMinLength = 1;
export const farmNameMaxLength = 1000;

export const FarmNameSchema = t.refinement(t.string, n => n.length >= farmNameMinLength && n.length <= farmNameMaxLength, 'FarmName');

export type FarmName = string;
