// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';

const requiredPart = t.interface({
  addedAnimals: PositiveOrZeroIntegerSchema,
  duplicateLabels: t.array(t.string),
  invalidLabels: t.array(t.string),
  numOfProcessedLines: PositiveOrZeroIntegerSchema,
  skippedLabels: t.array(t.string),
});

export const CsvImportResultSchema = excess(requiredPart);

export interface CsvImportResult extends t.TypeOf<typeof CsvImportResultSchema> {}
