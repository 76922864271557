import { ButtonRole, classNames } from '@fl/cmsch-fe-library';
import { ButtonType } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { MouseEvent, useCallback, memo, ReactNode, createRef, RefObject } from 'react';
import { Ant } from 'common/ant';
import { ICONS, IconName } from 'common/icons';
import styles from './styles.sass';
type ExtendedButtonRole = ButtonRole | 'reset';
export interface Props {
  children?: React.ReactNode;
  type?: ButtonHTMLType;
  disabled?: boolean;
  visuals?: ButtonType;
  title?: string;
  icon?: IconName;
  loading?: boolean;
  block?: boolean;
  size?: SizeType;
  href?: string;
  ghost?: boolean;
  danger?: boolean;
  smallButtonBiggerIcon?: boolean;
  stopClickEventPropagation?: boolean;
  autoSize?: boolean;
  noPadding?: boolean;
  noLineHeight?: boolean;
  testId?: string;
  role?: ExtendedButtonRole;
  buttonRef?: RefObject<HTMLElement>;
  onClick?(): void;
}
const getType = (visuals?: ButtonType, role?: ExtendedButtonRole): ButtonType | undefined => {
  if (visuals) return visuals;
  if (role === 'create' || role === 'delete') return 'primary';
  return undefined;
};
const getIcon = (icon?: IconName, role?: ExtendedButtonRole): ReactNode => {
  if (icon) return ICONS[icon];
  switch (role) {
    case 'create':
      return ICONS.plusOutlined;
    case 'delete':
      return ICONS.closeOutlined;
    case 'edit':
      return ICONS.editOutlined;
    case 'export':
      return ICONS.downloadOutlined;
    case 'print':
      return ICONS.printerOutlined;
    case 'reset':
      return ICONS.closeOutlined;
    default:
  }
};
const ButtonBase: React.FC<Props> = props => {
  const {
    children,
    type,
    disabled,
    visuals,
    title,
    icon,
    loading,
    block,
    size,
    href,
    ghost,
    danger,
    smallButtonBiggerIcon,
    stopClickEventPropagation,
    autoSize,
    noPadding,
    noLineHeight,
    testId,
    role,
    buttonRef,
    onClick
  } = props;
  const ref = createRef<HTMLElement>();
  const finalRef = buttonRef || ref;
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (stopClickEventPropagation) event.stopPropagation();
    if (type === 'submit') ref.current?.focus();
    onClick?.();
  }, [onClick, ref, stopClickEventPropagation, type]);
  const finalClassName = classNames(styles.buttonMinWidth, smallButtonBiggerIcon && styles.smallButtonBiggerIcon, autoSize && styles.autoSize, noPadding && styles.noPadding, noLineHeight && styles.noLineHeight, role === 'export' || role === 'print' ? styles.exportButton : undefined, role === 'reset' ? styles.resetButton : undefined, role === 'reset' ? 'favor-reset-button' : undefined);
  return <Ant.Button onClick={handleClick} type={getType(visuals, role)} disabled={disabled} title={title} icon={getIcon(icon, role)} className={finalClassName} htmlType={type} loading={loading} block={block} size={size} href={href} ghost={ghost} danger={role === 'delete' || danger ? true : false} data-test-id={testId} ref={finalRef} data-sentry-element="unknown" data-sentry-component="ButtonBase" data-sentry-source-file="index.tsx">
            {children !== undefined && <span className={styles.antBtnInlineFlex}>
                    {children}
                </span>}
        </Ant.Button>;
};
export const Button = memo(ButtonBase);